@import '../../../../../../styles/media-mixins';

.errorPanelWithAction {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0 10px;
    padding: 12px;
    border-radius: 8px;
    background-color: var(--alert100);
    border: 2px solid var(--alert300);
    color: var(--neutral1000);
    max-width: 1180px;

    &__texts {
        & > p:nth-of-type(1) {
            width: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            color: var(--neutral1000);
            font-weight: bold;
            margin-left: 0 !important;
            & > svg {
                margin-right: 10px;
                width: 20px;
                & > path {
                    stroke: var(--alert300);
                }
            }
        }

        & > p {
            margin-left: 30px;
            margin-bottom: 0;
        }

        & > p:nth-of-type(3) {
            margin-top: 10px;
        }
    }

    & > button {
        margin-top: 10px;
        margin-left: 30px;
        height: 32px;
        background-color:  var(--alert300);
        color: white;
        border: none;

        &:hover {
            background-color:  var(--alert400);
        }
    }
}
