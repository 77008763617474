@import "../../../../../../styles/media-mixins";


.noAction {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    gap: 8px;
    height: 100%;
    padding: 40px;
    @include min-width($mobileMD) {
        border: 1px dashed var(--neutral400);
        border-radius: 12px;
    }
    & > img {
        max-width: 120px;
    }
    & > p {
        text-align: center;
    }
    & > h6 {
        text-align: center;
        max-width: 260px;
    }
}
       
