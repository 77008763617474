@import "../../../../styles/media-mixins";

.gaCheckbox {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include align-items(center);
    gap: 8px;
    & > input {
        cursor: pointer;
        &[type="checkbox"] {
            margin: 0;
            &:checked {
                -moz-appearance: none;
                -webkit-appearance: none;
                -o-appearance: none;
                border: 2px solid var(--primary700);
                transition: background-color 0.3s ease-in-out;
                -webkit-transition: background-color 0.3s ease-in-out;
                -ms-transition: background-color 0.3s ease-in-out;
                background-color: var(--primary700);
                position: relative;
            }
            &:not(:checked) {
                -moz-appearance: none;
                -webkit-appearance: none;
                -o-appearance: none;
                background-color: var(--neutral100);
                transition: background-color 0.3s ease-in-out;
                -webkit-transition: background-color 0.3s ease-in-out;
                -ms-transition: background-color 0.3s ease-in-out;
            }

            
        }
    }
    &.medium {
        & > input {
            border-radius: 2.25px;
            &[type="checkbox"] {
                width: calc(24px - 3px);
                height: calc(24px - 3px);
                &:not(:checked) {
                    border: 2px solid var(--neutral400);
                    &:hover {
                        border-color: var(--primary700);
                    }
                }
                &:checked {
                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        width: 6px;
                        height: 1.5px;
                        background-color: var(--neutral100);
                        left: 3px;
                        top: 10px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        width: 10px;
                        height: 1.5px;
                        background-color: var(--neutral100);
                        left: 5px;
                        top: 8px;
                        -ms-transform: rotate(-55deg);
                        /* IE 9 */
                        -webkit-transform: rotate(-55deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(-55deg);
                    }
                }
            }
        }

    }
    &.small {
        & > input {
            border-radius: 1.92px;
            &[type="checkbox"] {
                width: calc(16px - 2px);
                height: calc(16px - 2px);
                &:not(:checked) {
                    border: 1.42px solid var(--neutral400);
                    &:hover {
                        border-color: var(--primary700);
                    }
                }
                &:checked {
                    &::before {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        width: 4.5px;
                        height: 1.5px;
                        background-color: var(--neutral100);
                        left: 0.5px;
                        top: 6px;
                        -ms-transform: rotate(45deg);
                        /* IE 9 */
                        -webkit-transform: rotate(45deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(45deg);
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        width: 8px;
                        height: 1.5px;
                        background-color: var(--neutral100);
                        left: 2px;
                        top: 4.5px;
                        -ms-transform: rotate(-55deg);
                        /* IE 9 */
                        -webkit-transform: rotate(-55deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(-55deg);
                    }
                }
            }
        }

    }
    &.disabled {
        & > input {
            cursor: not-allowed;
            &[type="checkbox"] {
                position: relative;
                &:checked {
                    background-color: transparent;
                    &::before{
                        content: '';
                        position: absolute;
                        background-color: var(--neutral400);
                        border-radius: 1.5px;
                        -ms-transform: none;
                        /* IE 9 */
                        -webkit-transform: none;
                        /* Chrome, Safari, Opera */
                        transform: none;
                    }
                    &::after {
                        content: none;
                    }
                }
                &:not(:checked) {
                    &:hover {
                        border-color: var(--neutral400);
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    background-color: var(--neutral400);
                    border-radius: 1.5px;
                    -ms-transform: none;
                    /* IE 9 */
                    -webkit-transform: none;
                    /* Chrome, Safari, Opera */
                    transform: none;
                }
                &:hover {
                    border-color: var(--neutral400);
                }
            }
        }
        &.medium {
            &>input {
                &[type="checkbox"] {
                    border: 2px solid var(--neutral400);
                    &::before {
                        width: 11px;
                        height: 11px;
                        top: 3px;
                        left: 3px;
                    }
                    &:checked {
                        &::before {
                            width: 11px;
                            height: 11px;
                            top: 3px;
                            left: 3px;
                        }
                    }
                }
            }
        }
        &.small {
            &>input {
                &[type="checkbox"] {
                    border: 1.42px solid var(--neutral400);
                    &::before {
                        width: 8px;
                        height: 8px;
                        top: 2px;
                        left: 2px;
                    }
                    &:checked {
                        &::before {
                            width: 8px;
                            height: 8px;
                            top: 2px;
                            left: 2px;
                        }
                    }   
                }
            }
        }
    }
    .label__container {
        @include flexbox;
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        pointer-events: none;
        gap: 4px;
        > label {
            cursor: pointer;
            @include flexbox;
            @include flex-direction(column);
        }
    }
}