.backgroundLayerActive {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0px;
    bottom: 0;
    left: 0;
    max-width: 1180px;
    cursor: auto;
}

.dropdownActionsContainer {
    position: relative;
    padding-top: 5px;
}

.dropdownActionsButton {
    z-index: 99999;
    overflow: hidden;
    padding: 0;
    border: none;
    background-color: var(--neutral100);
    cursor: pointer;

    & > svg {
        height: 100% !important;
    }

    &:hover {
        transition: background-color 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;
    }
}

.menuOptionsCard {
    position: absolute;
    z-index: 9999999;
    width: 200px;
    right: 28px;
    top: -28px;
    background: transparent;
    cursor: auto;

    & > ul {
        background-color: var(--neutral100);
        box-shadow: 0 3px 10px var(--boxShadowLigh);
        border-radius: 10px;
        box-shadow: 0 5px 13px #0000002e;;
    }

    &__item {
        display: flex;
        background-color: var(--neutral100);
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding: 7px 10px;
        cursor: pointer;

        &:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &:hover {
            background-color: #f1f1f1;
            transition: background-color 0.1s ease-in-out;

            -webkit-transition: background-color 0.1s ease-in-out;
            -ms-transition: background-color 0.1s ease-in-out;
        }
    }
}

.active {
    display: block;
}

.inActive {
    display: none;
}

.lastItemActions {
    top: -75px;
}
