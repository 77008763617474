@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.backgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.modal {
    overflow: hidden;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    max-height: 900px;
    max-width: 90vw;
    height: 95vh;
    padding: 20px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);

    @include min-width($tabletSM) {
        max-width: 1300px;
        width: 90vw;
    }
}

.periodToggle {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding-bottom: 20px;
    & > span {
        margin-bottom: 2px;
    }
}

.periodCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.periodCheckboxLabel {
    cursor: pointer;
    text-indent: 49px;
    width: 44px;
    height: 22px;
    background: white;
    border: 2px solid var(--primary700);
    display: block;
    border-radius: 90px;
    position: relative;
    margin-right: 10px;
}

.periodCheckboxLabel:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid var(--primary700);
    border-radius: 90px;
    transition: 0.3s;
}

.periodCheckbox:checked + .periodCheckboxLabel {
    background: var(--primary700);
}

.periodCheckbox:checked + .periodCheckboxLabel:after {
    width: 18px;
    left: calc(100% - 18px);
}

.periodCheckboxLabel:active:after {
    width: 22px;
}

.modal__header {
    &__title {
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
    }
    &__close {
        margin-left: auto;
        cursor: pointer;
        width: fit-content;
        padding: 5px 0 5px
            5px;
        &:hover {
            & > svg > path {
                fill: red;
            }
        }
    }
}
.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}

.tableContainer {
    overflow: scroll;
    max-height: calc(100% - 150px);
    max-width: 100%;

    @include min-width($mobileXL) {
        display: block;
    }
}

table {
    border-collapse: separate !important;
    height: 100%;
}

.table__header {
    &__cell {
        border: 1px solid rgba(227, 227, 227, 1);
        padding: 12px;
        vertical-align: middle;
        text-align: center;
        line-height: 16px;
        color: var(--primary1000);
        width: 175px;

        &:first-child {
            border-top-left-radius: 12px;
        }

        &__content {
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            width: 100%;
            vertical-align: top;
            align-items: center;
            text-align: left;
            display: inline-flex;
            .license__header {
                width: 100%;
                display: flex;
                flex-direction: row;
                width: 181px;
                flex-wrap: wrap;
                justify-content: space-between;
                & > .licenseName {
                    font-weight: bold;
                    font-size: 16px;
                    width: 100%;
                    font-weight: bold !important;
                    width: calc(100% - 76px);
                }
                &__popularTag {
                    background-color: var(--neutral1000);
                    border-radius: 5px;
                    padding: 3px 8px;
                    width: fit-content;
                    margin-left: auto;
                    height: 25px;
                    & > p {
                        color: white;
                        font-size: 12px;
                    }
                }
            }

            & > div {
                width: 100%;
                & > .licensePrice {
                    margin: 5px 0;
                    font-weight: normal;
                    font-size: 16px;
                    width: 100%;

                    & > span {
                        font-size: 25px;
                        font-weight: bold;
                    }
                }

                & > .licensePriceLarge {
                    margin-bottom: 49px;
                }
                & > .licenseCustomPrice {
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: bold;
                }

                & > .licenseSave {
                    font-size: 12px;
                    margin-bottom: 30px;
                    height: 14px;
                }

                & > .licenseAdapted {
                    font-size: 12px;
                    margin-bottom: 20px;
                }
                & > button {
                    margin-top: 20px;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
    &:first-child {
        & > .table__header__cell:nth-child(2) {
            -moz-border-top-left-radius: 12px;
            -webkit-border-top-left-radius: 12px;
            border-top-left-radius: 12px;
        }
        & > .table__header__cell:last-child {
            -moz-border-top-right-radius: 12px;
            -webkit-border-top-right-radius: 12px;
            border-top-right-radius: 12px;
        }
    }
}

.table__body {
    &__row {
        & > td {
            border: 1px solid rgba(227, 227, 227, 1);
            padding: 12px;

            line-height: 16px;
            color: var(--primary1000);
        }

        &__category {
            vertical-align: middle;
            text-align: center;
            font-weight: bold;
            font-size: 13px;
        }
        &__subcategory {
            & > div {
                min-width: 205px; 
            }
        }
        &__data {
            color: var(--primary1000);
        }

        &:first-child {
            & > td {
                &:first-child {
                    -moz-border-top-left-radius: 12px;
                    -webkit-border-top-left-radius: 12px;
                    border-top-left-radius: 12px;
                }
            }
        }

        &:last-child {
            &:last-child {
                & > td {
                    &:last-child {
                        -moz-border-bottom-right-radius: 12px;
                        -webkit-border-bottom-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                }
            }
        }

        &:last-child {
            border-top-right-radius: 12px;
        }

        &:nth-child(5) {
            & > td:nth-child(1) {
                -moz-border-bottom-left-radius: 12px;
                -webkit-border-bottom-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }
        }
    }
}

.mobileCardsContainer {
    display: block;
    overflow: scroll;
    max-height: calc(100% - 100px);
    max-width: 100%;
    padding-top: 11px;
    padding-bottom: 10px;
    padding-right: 20px;
    & > div:first-child {
        margin: auto;
        margin-bottom: 20px;
    }
    & > div:not(:first-child) {
        width: 100%;
    }
    @include min-width($mobileXL) {
        display: none;
    }
}

.licenses {
    display: flex;
    gap: 32px;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    justify-content: center;
    margin: 32px auto 25px;
    min-height: 534px !important;

    & > div {
        margin: 0;
        width: 100%;
        min-height: 366px;
        height: auto;
    }
    @include min-width($tabletMD) {
      & > div {
        width: calc(50% - 58px);
      }
    }
    @include min-width(1322px) {
      & > div {
        width: 270px;
        min-height: 603px;
        height: auto;
      }
    }
}

.onboardingSwicthButton {
    margin: 0 auto;
    width: 243px !important;
    height: 64px;

    & > div {
        width: 243px !important;
         height: 48px;

        & > label {
            font-size: 16px;
            font-weight: 500;
            height: 48px;
            line-height: 48px;

        }

        & > label:nth-child(3) {
            width: 117px !important;
        }

        & > label:nth-child(4) {
            width: 117px !important;
        }

        & > div {
            width: 117px !important;
             height: 48px;
             & > div {
                height: 48px;
             }
        }
    }
}

.tablesContainer {
    margin-bottom: 32px;
}

.showTableButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
        height: 24px;
        width: 24px;
        margin-left: 5px;

        & > g > path {
            stroke: var(--primary700);
        }
        
    }
}