@import "../../../styles/media-mixins";

.stepper {
    margin: 32px 0 32px;
}

.infoPanel {
    > div {
        @include align-items(flex-start);
        margin-top: 32px;
    }
    > div > div {
        width: 100%;
        & > p {
            width: 100% !important;
            @include align-items(center);
            span {
                font-weight: 400;
            }
        }
    }
}
.elementsAllignment {
    > div {
        gap: 20px;
        @include flex-wrap(wrap);
        @include min-width($mobileXL) {
            @include flex-wrap(nowrap);
        }
        @include min-width(775px) {
            @include flex-wrap(wrap);
        }
        @include min-width(820px) {
            @include flex-wrap(nowrap);
        }
        & > div {
            @include flex(1, 1, auto);
            @include min-width($mobileXL) {
                @include flex(1, 1, 0);
            }
        
            @include min-width($tabletMD) {
            @include flex(1, 1, auto);
            }
        
            @include min-width($desktopSM) {
                @include flex(1, 1, 0);;
            }
        }
        & > button {
            margin: 0 !important;
            min-width: fit-content;
            @include min-width($mobileXL) {
                margin: 0 0 0 auto !important;
            }
            @include min-width(775px) {
                margin: 0 !important;
            }
            @include min-width(820px) {
                margin: 0 0 0 auto !important;
            }
        }
    }
}

.stepperSkeleton {
    height: 58px;
}
