@import "../../../../styles/media-mixins";

.consentCredentialCard {
    @include flexbox();
    @include flex-direction(column);
    gap: 24px;
    width: 100%;
    height: auto;
    padding: 12px;
    border: 1px solid var(--neutral400);
    border-radius: 8px;

    &__header {
        @include flexbox();
        @include flex-direction(row);
        @include  align-items(center);
        @include  justify-content(space-between);
        @include flex-wrap(wrap-reverse);
        gap: 12px;
        width: 100%;

        &__left {
            @include flexbox();
            @include align-items(center);
            @include flex(1, 0, 0);
            gap: 12px;
            max-width: calc(100% - 36px);
        
            & > img {
                height: 24px;
            }
        }
        &__right {
            margin-left: auto;
            height: 24px;
        }
    }
}

.photoContainer {
    @include flexbox();
    @include align-items(center);
    gap: 8px;
    &__image {
        width: 32px;
        height: auto;
        border-radius: 6.4px;
    }

    & > p {
        cursor: pointer
    }
}

.bottomContainer {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    gap: 12px;

    & > button {
        border: 1px solid var(--neutral500);
        margin-left: auto;
    }
}

.textSetByWidth {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    min-width: 0;
}
