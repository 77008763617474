@import '../../../../../../styles/media-mixins';

.formLightPanel {
    margin-top: 20px;
    padding-top: 10px;
    & > div {
        width: 100%;
    }
}

.tabCard {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.setAnyCheckbox {
    margin-top: 10px;
    & > div {
        &>input[type=checkbox] {
            min-width: 21px;
        }
    }  
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-top: 20px;
    background-color: white;
    gap: 15px;
}
