@import "../../../../styles/media-mixins";


.optionCard {
    width: 100%;
    height: auto;
    padding: 16px 12px;
    border: 1px solid var(--neutral400);
    border-radius: 8px;

    &__header {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(row);
        gap: 12px;
        &__title {
            @include flexbox();
            @include align-items(center);
            @include flex-direction(row);
            gap: 12px;
            &__icon {
                width: 24px;
            }
        }
        & > div:nth-child(2) {
            width: 100%;
            & > div {
                width: 100%;
                & > label {
                    width: 100%;
                }
            }
        }
    }
}