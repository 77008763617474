@import '../../../../../styles/media-mixins';

.container {
    overflow-x: scroll;
     &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: var(--neutral300);
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:horizontal {
            height: 11px;
        }
}

.tableContainer {
    padding: 20px;
    border-radius: 12px;
    box-shadow: none;
    background-color: var(--neutral100);
    min-width: 1010px;

    & > table {
        width: 100%;
        min-width: 969px;

        & > thead {
            min-width: 932px;
            & > tr > th {
                padding: 2px 8px 16px;

                & > div {
                    @include flexbox();
                    gap: 4px;
                }
            }
        }

        & > tbody {
            min-width: 932px;
            & > tr {
                min-height: 72px;

                & > td {
                    min-height: 50px;
                    padding: 12px 8px;
                    border-top: 1px solid var(--gray300, #E4E4E4);
                    vertical-align: middle;
                    overflow-wrap: break-word;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                }
            }

            & > tr:last-of-type {
                & > td {
                    padding-bottom: 0;
                }
            }

        }
    }
}

.paginationContainer {
    margin-top: 32px;
}