@import '../../../../../styles/media-mixins';

.leftColumn {
    width: 100%;
    @include min-width($mobileXL) {
        @include flex-grow(1);
    }
    .form {
        margin-top: 32px;
        @include min-width($desktopMD) {
            margin-top: calc(32px + 14px);
        }
    }
    .formHeader {
        & > div {
            gap: 12px;
            @include flex-wrap(wrap);
            @include align-items(flex-start);
            @include min-width($mobileXL) {
                @include flex-wrap(nowrap);
            }
            @include min-width(775px) {
                @include flex-wrap(wrap);
            }
            @include min-width(820px) {
                @include flex-wrap(nowrap);
            }
            button {
                min-width: fit-content;
                margin: 0;
                @include min-width($mobileXL) {
                    margin: 0 0 0 auto;
                }
                @include min-width(775px) {
                    margin: 0;
                }
                @include min-width(820px) {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
    .buttonContainer {
        width: 100%;
        margin-top: 32px;
        @include min-width($mobileXL) {
            width: auto;
            margin: 32px 0 0 auto;
        }
        @include min-width($tabletMD) {
            width: 100%;
            margin-top: 32px;
        }
        @include min-width($desktopSM) {
            width: auto;
            margin: 32px 0 0 auto;
        }
        & > button {
            width: 100%;
            @include justify-content(center);
            padding: 12px 24px;
            height: 48px;
        }
    }
}