@import '../../../../styles/media-mixins';

.license {
    border: 1px solid #e3e3e3;
    @include flex(0 0 100%);
    padding: 20px 15px;
    position: relative;
    box-shadow: none;
    height: auto;
    @include min-width($mobileXL) {
        @include flex(0 0 calc(50% - 10px));
    }
    @include min-width($tabletMD) {
        @include flex(0 0 100%);
    }
    @include min-width($desktopSM) {
        @include flex(0 0 calc(50% - 10px));
    }
    &.maxTiers {
        @include min-width($desktopXXL) {
            @include justify-content(flex-start);
            @include flex(0 0 calc(25% - 15px));
        }
    }
    &.threeTiers {
        @include min-width($desktopXXL) {
            @include justify-content(flex-start);
            @include flex(0 0 calc((100%/3) - 13.5px));
        }
    }
    &::before {
        height: auto;
    }
    &__tag {
        display: block;
        position: absolute;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;
        
        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: var(--neutral200);

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }

    &__tag__newLicense {
        background-color: white;
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__title {
            font-weight: bold !important;
            width: calc(100% - 70px);
        }
        &__popularTag {
            background-color: var(--secondary600);
            border-radius: 5px;
            padding: 3px 8px;
            width: fit-content;
            margin-left: auto;
            height: 25px;
            & > p {
                color: white;
                font-size: 12px;
            }
        }
    }

    &__description {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        min-height: 54px;
    }

    &__price {
        margin-top: auto;
        padding-top: 10px;
        & > span {
            font-size: 40px;
            font-weight: bold;
        }
    }

    &__customPrice {
        font-size: 29px;
        font-weight: bold;
        margin-bottom: 1px;
    }

    &__save {
        font-size: 12px;
    }

    &__features {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #e3e3e3;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
            min-width: 232px;
        }
        &__title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &__list {
            font-size: 13px;

            .notAvailableNow {
                color: #a1a1a1;
                & > span {
                    color: #a1a1a1;
                    margin: 3px 0;
                }
                .featureNotAvailableNow {
                    margin-left: 16px;

                    & > span {
                        color: #a1a1a1 !important;
                    }
                }
            }

            &__item {
                display: flex;
                flex-direction: row;
                // flex-wrap: wrap;
                margin-bottom: 15px;
                font-size: 13px;
                align-items: center;
                &__content {
                    @include flexbox();
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include align-items(center);
                    gap: 8px;
                    margin-left: 9px;

                    & > .featureName {
                        font-size: 13px;
                        width: fit-content;
                    }

                }

                & > .featureName {
                    font-size: 13px;
                    width: fit-content;
                }

                & > div > div > .featureName {
                    font-size: 13px;
                    width: fit-content;
                }
            }
        }
    }

    &__button {
       height: 48px;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.currentLicense {
    background-color: transparent;
    position: relative;
    box-shadow: none;
    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: #fafafa;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }
       & > button:nth-of-type(2) {
        background-color: transparent !important;
    }
}

 .license__buttonCurrent {
        background-color: transparent !important;
    }