#root {
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    white-space: pre-line;
}

:host {
    #root {
        width: 100vw;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
    }
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: initial;
}

button, input, select, textarea {
    border-radius: 0;
    font-family: inherit;
}

input[type=text]::-ms-clear {
    display: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
    display: block;
}

sup {
    vertical-align: super;
}

sub {
    vertical-align: sub;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
    content: "";
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    box-sizing: border-box;
}

button {
    margin: 0;
}

html {
      height: 100%;
}

body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    margin: 0;
    background-color: #F2F2F2;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

strong {
    font-weight: 600;
}


h1, h2, h3, h4, h5, p, a, ul, span, li {
    color: var(--neutral1000);
}

a, span {
    text-decoration: none;
}