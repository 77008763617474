@import '../../../../../styles/media-mixins';

.cardContainer {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid var(--neutral400);
    .card {
        width: 100%;
        &__requirements{
            @include flexbox();
            @include align-items(center);
            @include flex-wrap(wrap);
            justify-content: space-between;
            gap: 12px;
            @include min-width($mobileMD) {
                @include flex-wrap(nowrap);
            }
            
        }
        &__name {
            @include flexbox();
            @include align-items(center);
            @include flex-wrap(nowrap);
            gap: 12px;

            &__icon {
                width: 24px;
            }
        }
        &__requirementOptions {
            margin-left: auto;
            .switchButton {
                padding: 0;
                min-height: 32px;
                height: 32px;
                border-radius: 8px;
                border: 1px solid var(--neutral400);
                width: 192px;
        
                &>div {
                    &>label {
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                        padding: 8px 16px;
                        min-height: 32px;
                        font-family: var(--base);
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        max-width: 96px;
                        z-index: 8;
                    }
        
                    &>div {
                        padding: 0;
                        width: 96px;
                        left: -0.5px;
                        top: -0.5px;
        
                        &>div {
                            min-height: 32px;
                            max-width: 96px;
                            background: transparent;
                            border-radius: 0;
        
                            &>div {
                                background: var(--primary700);
                                min-height: 31px;
                                transition: none;
                                will-change: initial;
        
                                &:nth-child(1) {
                                    border-top-left-radius: 8px;
                                    border-bottom-left-radius: 8px;
                                }
        
                                &:nth-child(2) {
                                    border-bottom-right-radius: 8px;
                                    border-top-right-radius: 8px;
                                }
        
                            }
                        }
                    }
                }
            }
        }
        &__purposes {
            padding-top: 16px;
        }
    }
}