@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.backgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.modal {
    overflow: hidden;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 460px;
    min-height: 431px;
    height: fit-content;
    max-height: 90%;
    padding: 35px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);

    &__header {
        &__icon {
            min-height: 102px;
            margin-top: 25px;
            
            & > img {
                margin: auto;
                display: block;
                width: 120px;
                margin-bottom: 15px;
            }
        }
        &__title > h3 {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
            margin-bottom: 10px;
            padding: 0 20px;
            color: #1e1e20;
            font-size: 24px;
        }
        &__close {
            margin-left: auto;
            cursor: pointer;
            width: fit-content;
            padding: 5px 0 5px
                5px;
            &:hover {
                & > svg > path {
                    fill: red;
                }
            }
        }
    }

    &__description {
        text-align: center;
        & > strong {
            color: var(--alert300)
        }
    }
    
    &__container {
        overflow: scroll;

        max-height: calc(100% - 116px);
        max-width: 100%;

        display: block;

        &__text {
            text-align: center;
        }

        &__button {
            margin: auto;
            margin-top: 25px;
            width: 100%;
            height: 36px;
            font-weight: bold;
            justify-content: center;
        }
        
    }
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}
