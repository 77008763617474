@import '../../../../../styles/media-mixins';

.editableAssuranceCardContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    padding: 10px 10px 10px
        20px;
    border-radius: 10px;
    background-color: #f4f4f4;
    color: var(--neutral1000);

    @include min-width($mobileMD) {
        align-items: center;
        flex-direction: row;
        padding: 5px 10px;
    }
    @include min-width($tabletSM) {
        padding: 10px 20px 8px;
    }

    .editableAssuranceCard {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        @include min-width($mobileMD) {
            align-items: center;
            flex-direction: row;
        }

        &__name {
            display: flex;
            align-items: center;
            flex-direction: row;
            min-width: 110px;
            margin-right: 10px;

            @include min-width($mobileMD) {
                width: 38%;
            }

            &__icon {
                display: none;
                margin-right: 15px;
                padding-bottom: 3px;
                width: 24px;

                @include min-width($mobileSM) {
                    display: inline-block;
                }
            }
        }

        &__options {
            display: flex;

            @include min-width($mobileMD) {
                flex-direction: row;
                max-width: 60%;
            }
            @include min-width($tabletSM) {
                width: fit-content;
                max-width: none;
            }

            &__select {
                max-width: 180px;
                height: 30px;

                @include min-width($mobileMD) {
                    display: block;
                    margin: 0;
                }

                & > div {
                    padding-bottom: 0px !important;
                    padding-top: 7px !important;
                    padding-right: 0px !important;
                    & > input {
                        margin-right: 0px !important;
                    }
                }
            }
        }
    }
}
