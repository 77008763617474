@import '../../../../../styles/media-mixins';

.infoNotification {
    background-color: var(--neutral300);

    &__content {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__texts {
            .title {
                font-weight: bold;
                font-size: 1rem;
            }

            & > p {
                font-size: 14px;
                color: var(--info300);
                margin-right: auto;
                @media (min-width: 1230px) {
                    min-width: 606px;
                }
            }
        }
        &__close {
            display: flex;
            flex-direction: row;
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            cursor: pointer;
            & > .closeIcon {
                width: 40px;

                & > path {
                    fill: var(--info300);
                }
            }
        }

        & > .fullWidth {
            width: 100% !important;
        }
    }
}
