@import '../../../../../styles/media-mixins';

.editIssuance {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(center);
    gap: 32px;
    margin-top: 13px;
    flex-wrap: wrap;
    @include min-width(1320px) {
        margin-left: 2px;
        flex-wrap: nowrap;
        @include justify-content(space-between);
    }
}
