@import '../../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 5993;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    max-width: none !important;
    background: rgba(0, 0, 0, 0.6);
    & > .modal {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 95vw;
        max-width: 960px;
        height: 95vh;
        max-height: 800px;
        border-radius: 12px;
        background: var(--neutral100);
        transform: translate(-50%, -50%);
        overflow: hidden;
        @include flexbox();
        @include flex-direction(column);
        @include min-width($tabletSM) {
            width: 80vw;
        }
    }

    .modal__header {
        width: 100%;
        padding: 24px 24px 0;
        &__top {
            width: 100%;
            gap: 24px;
            @include flexbox();
            @include justify-content(space-between);
            &__text {
                @include flexbox();
                @include flex-direction(column);
                gap: 4px;
            }
            &__close {
                cursor: pointer;
                &:hover {
                    &>svg>path {
                        stroke: var(--alert300);
                    }
                }
            }
        }
    }
    .modal__body {
        padding: 0 24px 24px;
        border-bottom: 1px solid var(--neutral300);
        height: 100vh;
        overflow-y: scroll;
        &__leftColumn {
            @include flexbox();
            @include flex-direction(column);
            @include min-width($desktopSM) {
                @include flex(0 0 calc(50% - 26px));
                padding-left: 16px;
            }
            strong {
                color: var(--primary700);
            }
        }
    }
    .modal__body__leftColumn__scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        vertical-align: initial;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 11px;
        }
        &::-webkit-scrollbar:horizontal {
            height: 20px;
        }
        &::-webkit-scrollbar-thumb {
            border: 2px solid var(--neutral100);
            /* should match background, can't be transparent */
            border-radius: 8px;
            background-color: var(--neutral300);
        }
    }

    .modal__footer {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        @include flex-direction(row);
        @include justify-content(space-between);
        gap: 12px 20px;
        width: 100%;
        padding: 24px 24px;
        &>button {
            padding: 12px 24px;
            height: 48px;
            width: 100%;
            @include flexbox();
            @include justify-content(center);
            @include min-width($mobileMD) {
                width: auto;
            }
            
        }

        &__noBorderButton {
            padding: 0 !important;
            @include justify-content(center);
            @include min-width($mobileXL) {
                @include justify-content(flex-start);
            }
        }
        &__primaryButton {
            margin-left: auto;
        }
    }
}

.visibleModal {
    display: block;
}

.progressIndicator {
    margin-top: 32px;
    padding: 0 24px;

    & > div > ul {
        gap: 24px;

        & > li {
            padding-bottom: 16px;
            border-bottom-width: 2px;
        }
    }
}
