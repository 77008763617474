@import '../../../../../styles/media-mixins';

.credentialCardDetail {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include align-items(flex-start);
    gap: 24px;
    padding: 12px;
    min-width: 300px;
    background-color: var(--neutral100);
    border-radius: 8px;

    @include min-width($mobileMD) {
        min-width: 330px;
    }
    @include min-width(1002px) {
        @include flex(0 0 calc((100% / 2) - (32px / 2)));
    }
    @include min-width(1364px) {
        @include flex(0 0 calc((100% / 3) - (64px / 3)));
    }

    &__header {
        @include flexbox();
        @include justify-content(space-between);
        gap: 12px;
        width: 100%;

        &__left {
            @include flexbox();
            @include align-items(center);
            @include flex(1, 0, 0);
            gap: 12px;
            max-width: calc(100% - 35px);
        
            &__texts {
                @include flexbox();
                @include flex-direction(column);
                gap: 4px;
                max-width: calc(100% - 104px);
            }
        }
        &__right {
            margin-left: auto;
        }
    }

    &__body {
        width: 100%;
    }

    &__footer {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(flex-end);
        @include flex-wrap(wrap);
        gap: 12px;
        width: 100%;

        &__issuanceDate {
            @include flexbox();
            @include flex-direction(column);
            gap: 2px;
        }

        &__expirationDate {
            @include flexbox();
            @include flex-direction(column);
            gap: 2px;
            text-align: right;
        }
    }
}

.textSetByWidth {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    min-width: 0;
}

.credDetail {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    cursor: default;

    & > .credCard {
        min-width: 0 !important;
    } 

    & > .content {
        & > .header {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
            gap: 12px;
            padding: 12px;
            border-top: 1px solid var(--gray300);
            border-radius: 0px 0px 8px 8px;
            cursor: pointer;
        }
    }
}

.tabs {
    height: 100%;

    & > div > ul {
        width: 100%;
        gap: 0;

        & > li {
            flex: 1;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
        }
    }

    & > div:last-child {
        min-height: calc(100% - 57px);
        min-height: 245.5px;
        position: relative;
    }
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}

.tabCursorPointer {
    & > div > ul > li {
        cursor: auto !important;
    }
}

.modal {
    & > div:nth-child(2){
        padding-bottom: 24px;
    }
}
