
@import "../../../../../../../../../styles/media-mixins";

.previewImageContainer {
    @include flexbox();
    @include flex(1);
    width: 100%;
    overflow-x: scroll;
    max-width: 100%;
    @include min-width(1200px) {
        @include justify-content(center);
    }

    .previewImage {
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        min-width: 635px;
        @include min-width(1200px) {
            min-width: 0;
        }
       
        &__header {
            background-color: var(--neutral300);
            width: 100%;
            gap: 9px;
            padding: 8px 12px;
            @include flexbox();
            @include align-items(center);

            & > span {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                &:nth-of-type(1) {
                    background-color: #EE6A5F;
                }
                &:nth-of-type(2) {
                    background-color: #F5BD4F;
                }
                 &:nth-of-type(3) {
                    background-color: #63C655;
                }
            }
        }

        &__body {
            background-color: var(--neutral200);
            @include flexbox();
            @include justify-content(space-between);

            & > div {
                padding: 14.6px;
                @include flexbox();
                @include flex-direction(column);
                @include justify-content(space-between);
            }

            &__left {
                gap: 4.358px;
                width: calc(100% / 3);
                background-color: var(--neutral200);
                border-radius: 0px 0px 0px 6.71px;
                border-bottom: 1px solid var(--neutral300);
                border-left: 1px solid var(--neutral300);
                &__getVerified {
                    @include flexbox();
                    @include align-items(center);
                    gap: 3.29px;
                    p {
                        font-family: "Poppins";
                        font-size: 6.571px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 8.762px;
                        & > strong {
                            font-family: "Ubuntu";
                            font-size: 7.302px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 10.222px;
                        }
                    }
                }

                &__oneClick {
                    @include flexbox();
                    @include flex-direction(column);
                    @include align-items(center);
                    @include justify-content(space-between);
                    gap: 3.49px;
                    width: 100%;
                    padding: 29.21px 10.01px;

                    & > img {
                        margin-bottom: 22.66px;
                        max-width: 154px;
                    }

                    & > p:nth-of-type(1) {
                        text-align: center;
                        font-family: "Ubuntu";
                        font-size: 11.683px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 14.603px;
                        > strong {
                            color: var(--primary700);
                        }
                    }
                }

                &__language {
                    @include flexbox();
                    @include align-items(center);
                    font-family: "Poppins";
                    font-size: 5.11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 5.841px; 
                    gap: 1.46px;
                    color: var(--neutral700);
                }
            }

            &__right {
                gap: 4.358px;
                width: calc((100% / 3) * 2);
                border-radius: 7.302px 0px 6.701px 7.302px;
                border-right: 1px solid var(--neutral300, #E4E4E4);
                border-bottom: 1px solid var(--neutral300, #E4E4E4);
                background: var(--neutral100, #FFF);
                box-shadow: -4.358px 0px 6.974px 0px rgba(47, 45, 139, 0.08);

                &__content {
                    @include flexbox();
                    @include flex-direction(column);
                    @include flex(1 0 0);
                    gap: 22.664px;
                    margin: 34.868px auto;
                    max-width: 301px;
                    &__companyLogo {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        padding: 2px 4px;
                        border-radius: 2px;
                        border: 0.5px solid var(--neutral500, #A9A9AC);
                        text-align: center;
                        font-family: "Poppins";
                        font-size: 6px;
                        width: fit-content;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 7px;
                    }

                    &__companyLogoImg {
                        max-height: 18px;
                        height: 18px;
                        max-width: 100%;
                        width: fit-content;

                        & > img {
                            height: 100%;
                        }
                    }

                    &__scan {
                        & > p:nth-of-type(1) {
                            font-family: "Ubuntu";
                            font-size: 11.683px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 14.603px;
                        }
                        & > p:nth-of-type(2) {
                            margin-top: 3.49px;
                            font-family: "Poppins";
                            font-size: 6.571px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 8.762px;
                            & > strong {
                                color: var(--primary700);
                            }
                        }
                    }

                    &__qr {
                        @include flexbox();
                        gap: 17.434px;
                        & > div:nth-of-type(1) {
                            @include flexbox();
                            @include align-items(center);
                            @include flex-direction(column);
                            @include justify-content(center);
                            gap: 8.717px;
                            padding: 10.46px;
                            border-radius: 5.23px;
                            background-color: var(--neutral100, #FFF);
                            box-shadow: 0px 0px 8.717px 0px rgba(0, 0, 0, 0.10);
                            & > img {
                                max-width: 144px;
                            }
                            & > p {
                                width: 100%;
                                color: var(--neutral700);
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 6.102px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 6.974px;

                                & > strong {
                                    color: var(--primary700);
                                }
                            }
                        }

                        &__shared {
                            @include flexbox();
                            @include flex-direction(column);
                            gap: 11.68px;
                            @include flex(1 0 0);
                            height: fit-content;
                            border-radius: 4.381px;
                            padding: 8.76px;
                            max-height: 181px;
                            overflow-y: scroll;
                            background-color: var(--primary100);
                            &__header {
                                & > p:nth-of-type(1) {
                                    font-family: "Poppins";
                                    font-size: 5.111px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 5.841px;
                                    margin-bottom: 1.46px;
                                }
                                & > p:nth-of-type(2) {
                                    font-family: "Ubuntu";
                                    font-size: 7.302px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 8.762px;

                                    & > strong {
                                        color: var(--primary700);
                                    }
                                }
                            }

                            &__scope {
                                @include flexbox();
                                @include flex-direction(column);
                                gap: 5.84px;
                                &__item {
                                    @include flexbox();
                                    gap: 2.92px;
                                    & > div:nth-of-type(1) {
                                        width: 10.46px;
                                        height: 10.46px;
                                        @include flexbox();
                                        @include justify-content(flex-start);
                                        @include align-items(flex-start);
                                    }
                                    & > div:nth-of-type(2) {
                                        @include flexbox();
                                        @include flex-direction(column);
                                        & > p:nth-of-type(1) {
                                            font-family: "Poppins";
                                            font-size: 5.841px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 7.302px;
                                        }
                                        & > p:nth-of-type(2) {
                                            font-family: "Poppins";
                                            font-size: 5.111px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 5.841px;
                                        }
                                    }
                                }
                            }
                            &__policiesTerms {
                                & > p:nth-of-type(1) {
                                    font-family: "Poppins";
                                    font-size: 5.111px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 5.841px;
                                    > strong {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }

                &__footer {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);
                    &__tutorial {
                        & > p {
                            font-family: "Poppins";
                            font-size: 5.111px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 5.841px;
                            text-transform: capitalize;
                        }
                    }
                    &__terms {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(flex-end);
                        gap: 11.68px;
                        & > p {
                            font-family: "Poppins";
                            font-size: 5.111px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 5.841px;
                            text-transform: capitalize;
                        }
                    }
                
                }
            }
        }
    }
}
