@import '../../../../../../../../styles/media-mixins';

.noLinkedServices {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    min-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 250px;
    border-radius: 8px;
    border: 1px solid var(--gray300);
    padding: 5px;

    &__content {
        @include flexbox();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        @include align-items(center);
        @include justify-content(center);
        gap: 8px;

        & > p {
            text-align: center;
        }
    }
}
