@import '../../../styles/media-mixins';

.gaNotification {
    z-index: 2;
    top: -140px;
    left: 250px;
    z-index: 90000;

    @media (max-width: 775px) {
        top: -160px;
        left: 0;
    }
}

.gaPublicNotification {
    top: -150px;
    left: 0;
    z-index: 90000;
}

.gaNotificationPanel {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: auto !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px 17px;
    margin-top: -25px;
    margin-bottom: 30px;
    width: fit-content;
    margin-left: -30px;
}

.entered {
    transition: transform 300ms ease-in-out;
}

.exiting {
    z-index: 0;
    transition: transform 300ms ease-in-out;
}

.exited {
    right: -350px;
}
