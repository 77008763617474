@import "../../../../../styles/media-mixins";

.failedEndContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 30px 0 0;

    .message {
        width: 100%;
        font-size: 20px;
        letter-spacing: 0.72px;
        text-align: center;
    }

    .error {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;
        text-align: center;
    }

    .error:first-letter {
        text-transform: uppercase;
    }

    .buttons {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
        border-top: 1px solid var(--neutral300);

        @include min-width($mobileSM) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        & > button {
            display: flex;
            justify-content: center;
            min-width: 130px;
            height: 30px;
            margin-top: 20px;
            padding: 10px 15px;
            background-color: var(--neutral100);
        }
    }
}
