@import '../../../styles/media-mixins';

.tag {
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 8px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: var(--neutral100);
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    line-height: 15px;
    @include flexbox();
    gap: 4px;

    & > div {
        & > img {
            margin-left: 10px;
        }
    }

    // & > svg:nth-of-type(1) {
    //     margin-right: 4px;
    // }

    // & > svg:nth-of-type(2) {
    //     margin-left: 4px;
    // }
}

.yellow {
    background-color: var(--warning300);
    color: var(--neutral1000);

    & > svg {
        & > path {
            stroke: var(--neutral1000);
        }
    }
}

.green {
    background-color: var(--success300);
}

.grey {
    background-color: var(--neutral300);
    cursor: auto;
}

.salmon {
    background-color: var(--tertiary400);
}

.red {
    background-color: var(--alert300);
}

.gaBlue {
    background-color: var(--neutral1000);
}

.pink {
    background-color: var(--tertiary600);
}
