.mediumModal__customClass > div > div  {
   > div:nth-child(2) {
    min-height: 200px;
   }
}

.methodInput {
   & > div:nth-of-type(2) > div {
      position: relative;
      width: calc(100% - 4px);
      top: 1px;
      margin: 0 auto 5px;
   }
}