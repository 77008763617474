@import '../../../../styles/media-mixins';

.limitReachedModalBackgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.6);
    max-width: none !important;
}

.limitReachedModal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    padding: 25px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 375px;
    height: fit-content;

    &__header {
        z-index: 9999;
        width: 100%;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &__close {
            padding: 5px;
            padding-left: 10px;
            margin-left: auto;

            & > svg {
                width: 15px;
            }
            &:hover {
                & > svg > g > path {
                    stroke: red;
                }
            }
        }

        & > img {
            margin-bottom: 25px;
            width: 40px;
        }

        & > p {
            font-weight: bold;
            font-size: 28px;
            text-align: center;
        }
    }

    &__description {
        margin-top: 16px;
        text-align: center;
        margin-bottom: 28px;
    }

    & > .button {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    & > .button:nth-child(4) {
        margin-top: 12px;
    }
}
