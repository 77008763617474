@import 'styles/reset';
@import 'styles/fonts';
@import 'styles/spacing';
@import 'styles/themes';
@import 'styles/colors';
@import 'styles/media-mixins';

#main {
    height: 100%;
    width: 100%;
}

#main.Z2F0YWNh {
    @include spread-map($theme-map-Z2F0YWNh);
}

// Use to set new branding variables
#main.aW5jbQ {
    @include spread-map($theme-map-aW5jbQ);
}

