@import "../../../../../styles/media-mixins";

.formSection {
    & > div {
        & > div:nth-child(3)  {
            margin-top: 20px;
        }
    }
}
.panelHeader {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(row);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    width: 100%;
    gap: 12px;
    @include min-width($mobileXL) {
       @include flex-wrap(nowrap);
    }
    & > button {
        margin-left: auto;
    }
}

.panelContainer {
    width: 100%;
    margin-top: 20px;
    padding-right: 10px;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 11px;
    }
    &::-webkit-scrollbar-thumb {
        border: 2px solid white;
        border-radius: 8px;
        background-color: var(--neutral300);
    }
}

.panelContainer__lightPanel {
    border: 1px solid var(--neutral300);
    box-shadow: none;
    border-radius: 8px;
    padding: 16px 12px;
}
.panelList {
    width: 100%;
    margin-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid var(--neutral1000, 0.4);
}

.leftColumn {
    width: 100%;

    @include min-width($mobileXL) {
        @include flex-grow(1);
    }

    .form {
        margin-top: 32px;

        @include min-width($desktopMD) {
            margin-top: calc(32px + 14px);
        }
    }
    .formHeader {
        & > div {
            gap: 12px;
            @include flex-wrap(wrap);
            @include align-items(flex-start);
            @include min-width($mobileXL) {
                @include flex-wrap(nowrap);
            }
            @include min-width(775px) {
                @include flex-wrap(wrap);
            }
            @include min-width(820px) {
                @include flex-wrap(nowrap);
            }
            button {
                min-width: fit-content;
                margin: 0;
                @include min-width($mobileXL) {
                    margin: 0 0 0 auto;
                }
                @include min-width(775px) {
                    margin: 0;
                }
                @include min-width(820px) {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
    .buttonContainer {
        width: 100%;
        margin-top: 32px;
        @include min-width($mobileXL) {
            width: auto;
            margin: 32px 0 0 auto;
        }
        @include min-width($tabletMD) {
            width: 100%;
            margin-top: 32px;
        }
        @include min-width($desktopSM) {
            width: auto;
            margin: 32px 0 0 auto;
        }
        & > button {
            width: 100%;
            @include justify-content(center);
            padding: 12px 24px;
            height: 48px;
        }
    }
}
.twoAuthFactorCheckbox, .appAuthenticationCheckbox {
    > div > div > div > div > div {
        transform: translateX(-90%) !important;
        &::before {
            right: 10px !important;
            left: auto !important;
        }
        @include min-width($tabletSM) {
            transform: translateX(-15%) !important;
            &::before {
                right: 30px !important;
                left: 30px !important;
            }
        }
    }
}
.credentialsSkeleton {
    min-height: 400px;
}