@import '../../../styles/media-mixins';

.dashboard__header {
    display: flex;
    justify-content: row;
    flex-wrap: wrap;
    align-items: center;
    @include min-width($mobileMD) {
        margin-bottom: 12px;
    }

    & > img {
        margin-right: 10px;
    }
}
.title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 16px;

    @include min-width($mobileMD) {
        font-size: 28px;
    }
}
.firstSection {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-items(center);
    margin-top: 32px;
    max-width: 1180px;
    gap: 32px 19.25px;
    @include min-width(1490px) {
        gap: 32px;
    }
    .subscriptionPanelContainerHome {
        background-color: var(--neutral100);
        border-radius: 10px !important;
        max-width: 100%;
        @include align-self(stretch);
        @include flex(1 1 0);
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        & > div {
            height: 100%;
            & > div {
                box-shadow: none;
                height: 100%;
            }
        }

        &__skeleton {
            max-width: calc(100% - 52.5px);
            margin: 26.25px;
            min-height: 420px;
            @include min-width(600px) {
                min-height: 224.5px;
            }
        
            @include min-width(775px) {
                min-height: 420px;
            }
        
            @include min-width(880px) {
                min-height: 224.5px;
            }
        }
    }

}
.homeBlocks {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    @include align-self(stretch);
    max-width: 100%;
    gap: 20px;
    @include min-width(1490px){
        max-width: calc(50% - 16px);
    }
    &__block {
        width: 100%;

        @media (min-width: 942px) {
            min-width: 178px;
            max-width: calc(calc(100% / 3) - 14px);
            min-height: 103px;
        }
    }
    &__block:nth-child(1) {
        background-color: var(--primary700);
        margin-right: 0 !important;
        &:hover {
            background-color: var(--primary900);
        }
    }
    &__block:nth-child(2) {
        background-color: var(--secondary600);
        margin-right: 0 !important;
         &:hover {
            background-color: var(--secondary800);
        }
    }
    &__block:nth-child(3) {
        background-color: var(--tertiary600);
        margin-right: 0 !important;
         &:hover {
            background-color: var(--tertiary800);
        }
    }

    &__disabled {
        background-color: var(--neutral400) !important;
        cursor: auto;
        &:hover {
            box-shadow: none !important;
        }

        & > div:nth-of-type(1) {
            background-color: transparent;
            padding: 0 !important;
          
        }
        & > div {
            & > svg {
                color: var(--neutral600);
                & > path:not(:first-child) {
                    stroke: var(--neutral400);
                }
            }
            & > h3 {
                color: var(--neutral600);
            }
            & > p {
                color: var(--neutral600);
            }
        }
    }
}

.pendingActions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: fit-content;

    @include min-width($mobileMD) {
        overflow-y: hidden;
        height: 200px;
        padding: 0 10px;
    }
    @media (min-width: 775px) {
        height: 185px;
    }
}

.seeMore {
    display: inline-block;
    width: fit-content;
    padding: 10px 10px 0 0;
    font-size: 1rem;

    &:hover {
        font-weight: bold;
    }
}

.charts {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    margin-top: 30.8px;
    gap: 19.5px;
}

.loader {
    > div {
        top: 50%;
    }
}
