@import '../../../styles/media-mixins';

.expandableMenu, .expandedMenu {
    padding: 0 !important;
    margin: 0 15px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: 35px;
    &__text {
        width: calc(100% - 30px);
        padding: 5px 0px 5px 8px;
    }
    & > div {
        display: flex;
        align-items: center;

        & > img {
            width: 20px;
            height: 20px;
        }
        span {
            color: var(--neutral1000);
            font-weight: 500;
            letter-spacing: 0.32px !important;
            line-height: 16px !important;
            height: 20px;
            padding: 4px 0;
            font-size: 13px;
        }
    }
    .angle {
        z-index: 999999;
        width: 30px;
        height: 30px;
        padding: 10px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        list-style: none;

        @media (min-width: 775px) {
            margin-top: 76px;
            position: relative;
            margin-top: 0;
            flex-direction: column;
        }

        & > li {
            @media (min-width: 775px) {
                cursor: pointer;
            }

            & > img {
                width: 20px;
                height: 20px;
            }
            & > span {
                color: var(--neutral1000);
                font-weight: 500;
                letter-spacing: 0.32px !important;
                line-height: 16px !important;
                height: 20px;
                padding: 4px 0;
                font-size: 13px;
            }

            & > div {
                padding: 5px 0px 5px 5px;
                margin: 0 15px 5px 45px;
                display: flex;
                border-radius: 4px;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                min-height: 35px;
                & > div {
                    display: flex;
                    align-items: center;
                    & > img {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        color: var(--neutral1000);
                        font-weight: 500;
                        letter-spacing: 0.32px !important;
                        line-height: 16px !important;
                        height: 20px;
                        padding: 4px 0;
                        font-size: 13px;
                    }
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                & > li {
                    display: flex;
                    align-items: center;
                    padding: 5px 8px 5px 8px;
                    margin: 0 15px 5px 45px;

                    height: 35px;
                    & > span {
                        color: var(--neutral1000);
                        margin-left: 0;
                        font-size: 13px;
                    }
                }
                & > a {
                    display: flex;
                    align-items: center;
                    padding: 5px 8px 5px 8px;
                    margin: 0 15px 5px 45px;

                    height: 35px;
                    & > span {
                        color: var(--neutral1000);
                        margin-left: 0;
                        font-size: 13px;
                    }
                }
                .angle {
                    z-index: 999999;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        & > a {
            @media (min-width: 775px) {
                cursor: pointer;
            }

            & > img {
                width: 20px;
                height: 20px;
            }
            & > span {
                color: var(--neutral1000);
                font-weight: 500;
                letter-spacing: 0.32px !important;
                line-height: 16px !important;
                height: 20px;
                padding: 4px 0;
                font-size: 13px;
            }

            & > div {
                padding: 5px 0px 5px 8px;
                margin: 0 15px 5px 45px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                min-height: 35px;
                & > div {
                    display: flex;
                    align-items: center;
                    & > img {
                        width: 20px;
                        height: 20px;
                    }
                    span {
                        color: var(--neutral1000);
                        font-weight: 500;
                        letter-spacing: 0.32px !important;
                        line-height: 16px !important;
                        height: 20px;
                        padding: 4px 0;
                        font-size: 13px;
                    }
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                & > li {
                    display: flex;
                    align-items: center;
                    padding: 5px 8px 5px 8px;
                    margin: 0 15px 5px 45px;

                    height: 35px;
                    & > span {
                        color: var(--neutral1000);
                        margin-left: 0;
                        font-size: 13px;
                    }
                }
                & > a {
                    display: flex;
                    align-items: center;
                    padding: 5px 8px 5px 8px;
                    margin: 0 15px 5px 45px;

                    height: 35px;
                    & > span {
                        color: var(--neutral1000);
                        margin-left: 0;
                        font-size: 13px;
                    }
                }
                .angle {
                    z-index: 999999;
                    width: 10px;
                    height: 10px;
                }
            }
        }

        @media (max-width: 768px) {
            position: relative;
            top: 0;
            left: 0;
            flex-flow: column nowrap;
            width: 250px;
        }
    }
}

.expandableMenu__link {
    list-style-type: none;
    @media (min-width: 775px) {
        cursor: pointer;
    }

    & > img {
        width: 20px;
        height: 20px;
    }
    & > span {
        color: var(--neutral1000);
        font-weight: 500;
        letter-spacing: 0.32px !important;
        line-height: 16px !important;
        height: 20px;
        padding: 4px 0;
        font-size: 13px;
    }

    & > div {
        &:hover {
            background-color: #f6f6f6;
        }
    }
    & > .expandableMenu__list > li {
        &:hover {
            background-color: #f6f6f6;
        }
    }
}

.expandableMenu__link__disabled {
    & > div > div > span {
        color: var(--neutral1000);
        opacity: 0.3;
        font-size: 13px;
    }
}

.expandableMenu__link.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        background-color: #f6f6f6;
    }
}
.expandableMenu__link.row:hover {
    background-color: #f6f6f6;
}

.inactive {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;

    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -ms-transition: -ms-transform 0.3s ease-in-out;
}

.active {
    transition: transform 0.3s ease-in-out;

    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    -ms-transition: -ms-transform 0.3s ease-in-out;
}

.selected {
    background-color: var(--neutral200) !important;
    & > span {
        color: var(--primary700) !important;
    }
}
.expandedMenu {
    &__noClick {
        cursor: default;
    }
}