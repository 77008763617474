@import '../../../../../styles/media-mixins';

.importCredentialCard {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    gap: 8px;
    padding: 24px;
    min-height: 173.94px;
    min-width: 300px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px dashed var(--neutral500);

    @include min-width($mobileMD) {
        min-width: 330px;
        padding: 24px;
    }
    @include min-width(1002px) {
        @include flex(0 0 calc((100% / 2) - (32px / 2)));
    }
    @include min-width(1364px) {
        @include flex(0 0 calc((100% / 3) - (64px / 3)));
    }

    &__body {
        &__import {
            position: relative;
            &:hover {
                & > .button {
                    background-color: var(--primary900);
                    box-shadow: 0 5px 13px #0000002e;;
                    color: var(--neutral100);
                }
            }

            & > .input {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                font-size: 0;
                width: 100%;
                height: 100%;
                filter: alpha(opacity=0); /* IE 7 */
                cursor: pointer;
                &::-webkit-file-upload-button {
                    cursor:pointer;
                }
            }
        }

        & > p {
            text-align: center;
        }
    }
}

.loading {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    max-width: 100%;
    gap: 16px;

    & > div {
        max-width: 100%;
        & > p {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;    
            min-width: 0;
        }
    } 
}