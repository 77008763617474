@import "../../styles/media-mixins";

.badget {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    border-radius: 2px;
    width: fit-content;
    padding: 2px 6px;

    & > p {
        line-height: 12px;
    }
}

.primaryBG {
    background-color: var(--primary700);
}

.neutralBG {
    background-color: var(--neutral600);
}