@import "../../../../../styles/media-mixins";

.successEndContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 30px;

    @include min-width($tabletSM) {
        max-width: 900px;
    }

    &__messagePanel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        max-width: 414px;
        &__icon {
            width: 50px;
            margin-right: 20px;
        }

        &__successMessage {
            width: fit-content;
            font-size: 20px;
            letter-spacing: 0.72px;
            text-align: center;
        }

    }
    &__examplePanel {
         max-width: 414px;
    &__description {
        width: 100%;
    }

    &__upon {
        height: 70px;
        margin: 20px 0 10px;

        @include min-width($mobileSM) {
            height: 90px;
        }
    }

    &__actionsText {
        width: 245px;
        margin-top: 10px;
        margin-bottom: 0;
        padding-top: 20px;
        border-top: 2px solid var(--neutral300);
        text-align: center;
    }
    &__header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
        margin-bottom: 24px;
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @include min-width($mobileMD) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        & > button {
            display: flex;
            justify-content: center;
            min-width: 130px;
            margin-top: 32px;
        }

        &__apiKey {
            margin-bottom: 10px;

            @include min-width($mobileMD) {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }
    }
    }
}


.card {
    width: 343px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 3px 10px var(--boxShadowLigh);
    height: 80px;
    display: flex;
    flex-direction: row;
    margin: 20px auto 0;
    border: 1px solid var(--neutral300);
    @include min-width($mobileSM) {
        height: 80px;
        width: 300px;
    }

    &:hover {
        box-shadow: 0 5px 13px #0000002e;;
    }

    &__leftSide {
        width: 10%;
        &__icon {
            width: 20px;
           
        }
    }

    &__infoSide {
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        width: 70%;

        &__orgName{
            font-size: 10px;
            color: #707070;
            margin-top: 5px
        }
        &__issuanceId {
            font-size: 10px;
            color: #707070;
        }
        &__appId {
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__rightSide {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;


        &__date {
            font-size: 7px;
            color: #707070;
            letter-spacing: 0.16px;
            line-height: 18px;
            text-align: right;
        }

        &__info {
            font-size: 10px;
            color: #707070;
            text-align: right;
        }

    }
    &__header {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        &__title {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 85%;
            &__text {
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 0.32px;
                margin-bottom: 5px
            }
            &__icon {
                margin-right: 15px;
                width: 30px;
            }
        }

        &__button {
            padding: 0;
            border: none;
            background-color: #ffffff;
            cursor: pointer;

            @include min-width($tabletSM) {
                margin-left: 10px;
                padding: 3px;
            }

            &__icon {
                width: 25px;
            }
        }
    }
}