@import '../../../../../styles/media-mixins';

.changeSubscription {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    @include min-width($desktopSM) {
        justify-content: flex-start;
    }
}

.changeSubscriptionContainer {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    margin-top: 30px;
    gap: 15px;
    @media (min-width: 1200px) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    & > div:first-child {
        @media (min-width: 1200px) {
            width: calc(100% - 300px);
            min-width: 614px;

            display: flex;
            // justify-content: center;
        }
    }
}

.currentLicense {
    background-color: transparent;

    position: relative;
    box-shadow: none;

    @include min-width($desktopSM) {
        justify-content: flex-start;
        margin-right: 15px !important;
    }

    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: #fafafa;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.rightArrow {
    margin-right: 15px;
}
.license {
    border: 1px solid #e3e3e3;
    width: 270px;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 20px 15px;
    position: relative;

    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: white;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }

    @include min-width($desktopSM) {
        justify-content: flex-start;
        margin-right: 20px;
    }

    @media (min-width: 1080px) {
        width: 23%;
        min-width: 260px;
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__title {
            font-weight: bold !important;
        }
        &__popularTag {
            background-color: var(--neutral1000);
            border-radius: 5px;
            padding: 3px 8px;
            & > p {
                color: white;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }

    &__description {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    &__price {
        & > span {
            font-size: 40px;
            font-weight: bold;
        }
    }

    &__features {
        margin-top: 10px;
        padding-top: 15px;
        margin-bottom: 10px;
        border-top: 1px solid #e3e3e3;
        width: 100%;
        &__title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &__list {
            font-size: 13px;

            &__item {
                margin-bottom: 15px;
                font-size: 13px;
                & > span {
                    font-weight: bold;
                    font-size: 13px;
                }
                & > div > div > .featureQuantity {
                    font-weight: bold;
                    font-size: 13px;
                }
                & > div > div > .featureName {
                    color: var(--neutral1000);
                    font-size: 13px;
                }
            }
        }
    }
    &__button {
        font-weight: bold;
        margin-top: 10px;
        width: 100%;
        background-color: transparent;
        border: 1px solid #e3e3e3;
        display: flex;
        justify-content: center;
        &:hover {
            border: 1px solid var(--primary700);
        }
    }
}

.next {
    margin-top: 24px;
    border-top: 1px solid #e3e3e3;
    padding-top: 24px;
    width: 100%;
    &__title {
        font-weight: bold;
        font-size: 18px;
    }

    &__list {
        margin-top: 15px;
        list-style: inherit;
        margin-left: 20px;
        &__item {
            margin-top: 10px;
        }
    }
}

.totalDueDisscount {
    font-size: 14px !important;
}

.availableLicenses {
    margin-top: 24px;
    border-top: 1px solid #e3e3e3;
    padding-top: 24px;
    width: 100%;

    &__title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 24px;
    }
    & > .license {
        width: 100%;
        max-width: 540px;
        display: flex;
        flex-direction: row;
        & > input {
            cursor: pointer;
        }
        & > div {
            width: 100%;
            margin-left: 16px;
            & > .license__title {
                font-weight: bold;
                font-size: 18px;
            }
            & > .license__features {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}

.subscriptionPayment {
    max-width: 272px;
    .periodButtons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        & > button {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            width: fit-content;
            padding: 8px 13px;
            width: 126px;
            min-height: 53px;
            border-radius: 5px;
            letter-spacing: -0.36px;

            & > span {
                font-size: 12px;
                font-weight: normal;
            }
        }

        &__disabled {
            background-color: #cdcdcd !important;
            border: none !important;

            & > p {
                color: white !important;
            }

            & > span {
                color: white !important;
            }
            &:hover {
                // background-color: var(--neutral100);
                box-shadow: none !important;
                cursor: auto !important;
            }
        }
        &__inactive {
            border: 1px solid var(--primary700);
            background-color: white;
            color: var(--neutral100);
            cursor: pointer;

            & > p {
                color: var(--neutral1000);
            }

            & > span {
                color: var(--neutral1000);
            }
            &:hover {
                box-shadow: 0 5px 13px #0000002e;
            }
        }

        &__active {
            border: 1px solid var(--primary700);
            background-color: var(--primary700);
            color: var(--neutral100);
            cursor: auto;

            & > p {
                color: white;
            }

            & > span {
                color: white;
            }
        }
    }

    .periodButtons__yearly {
        margin-left: 10px;
    }

    .billSummary {
        margin-top: 20px;
        padding-top: 15px;
        &__title {
            font-weight: bold;
            font-size: 18px;
        }

        & > div {
            width: 100%;
            margin-top: 12px;
            border-top: 1px solid grey;
            &:nth-child(2) {
                margin-top: 0;
                border-top: none !important;
            }
        }

        &__info:nth-of-type(4) {
            border-top: none !important;
            margin-top: 0 !important;
        }

        &__label {
            font-size: 14px;

            padding-top: 12px;
        }
        &__data {
            font-size: 12px;
            color: grey;
            width: 100%;
        }
        &__price {
            font-size: 16px;
            font-weight: bold;
        }

        &__continue {
            margin-top: 18px;
            width: 100%;
            height: 35px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 14px;
        }
    }
}
