@import "../../../../styles/media-mixins";

.lineProgressBar__container {
    position: relative;
    min-height: 42px;
    width: 100%;
    @include min-width($mobileXL) {
        width: calc(100% - 20px); // 20px padding right in the forms scrollableArea
    }
    @include min-width($tabletMD) {
        width: 100%;
    }
    @include min-width($desktopMD) {
        width: calc(100% - 20px); // 20px padding right in the forms scrollableArea
    }
    & > .lineProgressBar{
        width: 100%;
        margin: 0 auto;
        @include min-width($desktopMD) {
            transform: translate(-50%, 0%);
            left: 50%;
            position: absolute;
        }
    }
}

.progressBar {
    counter-reset: step;
    margin: auto;
    justify-content: space-between;
    width: 100%;
    display: flex;
    li {
        list-style-type: none;
        flex-grow: 1;
        width: 4rem;
        position: relative;
        float: left;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        @include min-width($mobileXL) {
            width: 6rem;
        }
        > button.progress_text {
            max-width: 70px;
            font-size: 10px;
            border: none;
            outline: none;
            background-color: transparent;
            margin: 10px auto 0;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            padding: 0;
            word-wrap: break-word;
            @include min-width($mobileMD) {
                font-size: 12px;
                word-wrap: inherit;
            }
            @include min-width($mobileXL) {
                font-size: 14px;
                padding: 0 6px;
                max-width: 110px;
            }
            @include min-width($tabletMD) {
                font-size: 12px;
            }
            @include min-width($desktopMD) {
                font-size: 14px;
            }
        }
         & > div > div  {
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);

            & > button.progress_text {
                max-width: 70px;
                font-size: 10px;
                border: none;
                outline: none;
                background-color: transparent;
                margin: 10px auto 0;
                display: inline-block;
                text-align: center;
                cursor: auto;
                padding: 0;
                word-wrap: break-word;
                @include min-width($mobileMD) {
                    font-size: 12px;
                    word-wrap: inherit;
                }
                @include min-width($mobileXL) {
                    font-size: 14px;
                    padding: 0 6px;
                    max-width: 110px;
                }
                @include min-width($tabletMD) {
                    font-size: 12px;
                }
                @include min-width($desktopMD) {
                    font-size: 14px;
                }
            }
        }
    }
}


.progressBar li {
    & > div > div > .progressCircle {
        counter-increment: step;
        height: 16px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    & > .progressCircle {
        counter-increment: step;
        height: 16px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    &::after {
        content: "";
        position: absolute;
        width: calc(100% - 12px);
        height: 2px;
        background-color: var(--neutral400);
        top: 6.5px;
        left: calc(-50% + 6px);
        z-index: 0;
    }
    &.completed {
        & > div > div > button {
            font-weight: 400;
        }
        & > button {
            font-weight: 400;
        }
        &::after {
            background-color: var(--primary700);
        }
    }
    &.active{
        &::after {
            background-color: var(--primary700);
        }
    }
    &.uncompleted {
        pointer-events: none;
        & > button {
            color: var(--primary700);
        }
        & > div > div > button {
            color: var(--primary700);
        }
    }
    &:first-child::after {
        content: none;
    }

    
}

.lineStepperHoverTooltip {
    & > div > div {
        top: 100%;
        
    } 
}

.lastStepperHoverTooltip {
    & > div > div {
        left: -15% !important;
        &::before {
            left: calc(50% + 44px) !important;
        }
        @include min-width($mobileXL) {
            left: 50%  !important;
            &::before {
                left: 50%  !important;
            }
        }
    }
}
