.testQr {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    & > div {
        width: 24px;
        height: 24px;
        transform-origin: 50% 50%;
        animation: spin 1.2s infinite linear;
    }
    & > p {
        margin-top: 20px;
    }
}

.errorContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;
    & > div > svg {
        width: 24px;
        height: 24px;
        & > path {
            fill: red;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }