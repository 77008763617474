@import '../../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999999;
}

.backgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.downGradeInfoModal {
    overflow: hidden;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 1040px;
    height: fit-content;
    max-height: 90%;
    padding: 20px;
    border-radius: 20px;
    background: #fafafa;

    transform: translate(-50%, -50%);
    width: 90%;
    position: relative;
    // height: calc(100vh - 140px);
    padding: 35px;
    height: calc(100vh - 35px);

    @media (min-width: 775px) {
    }

    &__header {
        & > div {
            width: fit-content;
            margin-left: auto;

            & > svg {
                margin: auto;
                height: 20px;
                width: 20px;
                cursor: pointer;

                &:hover {
                    & > path {
                        fill: red;
                    }
                }
            }
        }
    }

    &__container {
        height: fit-content;
        max-width: 800px;
        margin: 0;
        overflow: scroll;
        width: 100%;
        max-height: calc(100% - 90px);
        max-width: 90%;
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 15px 15px;

        @media (min-width: 775px) {
            max-width: calc(100% - 70px);
        }

        &__title {
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 5px;
        }

        &__description {
            max-width: 560px;

            margin: auto;
            margin-top: 20px;
            text-align: center;
        }

        &__description:nth-of-type(2) {
            margin-top: 15px;
        }

        &__description:nth-of-type(3) {
            margin-top: 15px;
        }

        &__features {
            margin-top: 25px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            justify-content: center;

            @include min-width($mobileSM) {
                justify-content: space-between;
            }
        }

        &__feature {
            display: flex;
            margin: auto;
            flex-direction: row;
            flex-wrap: wrap;
            // max-width: 416px;
            gap: 16px;
            justify-content: center;

            @include min-width($mobileSM) {
                justify-content: space-between;
                justify-content: center;
            }

            & > .newFeature {
                border: 1px solid var(--neutral400);
                background-color: transparent;

                & > .downGradeInfoModal__container__feature__info__tag {
                    color: var(--neutral1000);
                    border: 1px solid var(--neutral400);
                }
            }

            & > .toDeleteFeatures {
                border: 1px solid var(--warning300);

                & > .downGradeInfoModal__container__feature__info__tag {
                    color: white;
                    background-color: var(--warning300);
                }

                .downGradeInfoModal__container__feature__info__quantity {
                    color: var(--warning300) !important;
                }
            }

            &__info {
                position: relative;
                padding: 20px 12px;
                width: 150px;
                margin-top: 32px;
                background-color: white;
                min-height: 108px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0 3px 10px var(--boxShadowLigh);
                border-radius: 10px;

                @media (min-width: 775px) {
                    max-width: 160px;
                }

                &__tag {
                    position: absolute;
                    height: 24px;
                    top: -11px;
                    padding: 4px 8px;
                    background-color: white;
                    border: 1px solid var(--neutral200);
                    border-radius: 20px;
                }

                &__quantity {
                    line-height: 1.5;
                    font-size: 45px;
                    font-weight: bold;
                    text-align: center;
                }

                &__total {
                    margin-top: auto;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                }

                &__description {
                    font-size: 16px;
                    text-align: center;
                    width: 108px;
                }
            }

            .allFeaturesLink {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__buttons {
            width: 100%;
            margin-top: 25px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;

            & > button {
                width: calc(50% - 6px);
                justify-content: center;
                height: 36px;
                font-weight: bold;
                margin-top: 12px;
            }
        }
    }
}
