.detailContainer {
    li:not(:first-child) {
        margin-top: 12px;
    }

    li {
        list-style: none;
        max-height: 60px;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 11px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 20px;
        }

        &::-webkit-scrollbar-thumb {
            border: 2px solid white; /* should match background, can't be transparent */
            border-radius: 8px;
            background-color: var(--neutral300);
        }
        & > div {
            color: var(--neutral1000);
            min-height: 50px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid var(--neutral300);

            & > button {
                position: relative;
                border: none;
                background-color: transparent;
                padding: 0;
                font-size: 24px;
                width: 30px;
                text-align: right;
                color: grey;
                cursor: pointer;

                &:disabled {
                    cursor: default;
                }

                &:hover {
                    color: var(--neutral1000);
                }

                & > p {
                    font-size: 12px;
                    position: absolute;
                    right: 25px;
                    margin-top: 8px;
                    color: grey;
                    width: 90px;
                }
                & > img {
                    margin-top: 5px;
                }
            }
            & > p {
                width: calc(100% - 30px);
                font-family: var(--base);
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                text-decoration: none;
                color: var(--neutral1000);
            }
            & > div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                min-height: 15px;
                text-overflow: ellipsis;
                & > span {
                    color: grey;
                }
            }
        }

        & > ul {
            margin-left: 20px;
            & > div > span {
                height: 50px;
                background-color: #eaeaea;
                margin-top: 20px;
                display: flex;
                align-items: center;
                padding: 10px 20px;
                color: grey;
            }
        }
    }

    h5 {
        padding-top: 20px;
    }
}

.active {
    border: 1px solid red;
}

.showed {
    display: none;
}
