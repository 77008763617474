@import '../../../../../../../styles/media-mixins';

.changeSubscriptionContainer {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-wrap(wrap);
    margin-top: 0;

    &__licenses {
        width: 100%;
        max-width: 540px;
        @media (min-width: 1200px) {
            min-width: 614px;
            justify-content: space-between;
            max-width: none;
        }
    }
}
.changeSubscriptionFirstStep {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-wrap(wrap);
    @include flex-direction(column);
    margin-top: 30px;
    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: flex-start;
    }

    &__licensesContainer {
        @include flexbox();
        @include justify-content(flex-start);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        gap: 12px;
        @media (min-width: 1200px) {
            @include flex-direction(row);
            @include align-items(center);
        }
    }

    &__licenseCard {
        max-width: 256px;
    }

    &__licenseSkeleton {
        margin-right: 15px;
        & > div {
            width: 272px;
            height: 440px;
            &::before {
                height: 440px;
            }
        }
    }

    & > div:not(:nth-of-type(2)) {
        height: auto;
        min-height: 590px;
    }
}

.currentLicense {
    background-color: transparent;
    max-width: 540px;
    position: relative;
    box-shadow: none;

    @media (min-width: 1200px) {
        justify-content: flex-start;
        margin-right: 15px !important;
    }

    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: #fafafa;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.rightArrow {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
}

.downArrow {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    transform: rotate(90deg);
    height: 18px;
    max-width: 540px;
    @media (min-width: 1200px) {
        display: none;
    }
}
.license {
    border: 1px solid #e3e3e3;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 20px 15px;
    position: relative;
    max-width: 540px;
    @media (min-width: 1200px) {
        width: 270px;
    }

    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: white;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }

    @media (min-width: 1200px) {
        justify-content: flex-start;
        margin-right: 20px;
    }

    @media (min-width: 1080px) {
        // width: 23%;
        min-width: 260px;
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__title {
            font-weight: bold !important;
        }
        &__popularTag {
            background-color: var(--secondary600);
            border-radius: 5px;
            padding: 3px 8px;
            & > p {
                color: white;
            }
        }
    }

    &__description {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    &__price {
        & > span {
            font-size: 40px;
            font-weight: bold;
        }
    }

    &__features {
        margin-top: 10px;
        padding-top: 15px;
        margin-bottom: 10px;
        border-top: 1px solid #e3e3e3;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
        &__title {
            margin-bottom: 10px;
        }

        &__list {

            &__item {
                margin-bottom: 15px;
                @include flexbox();
                @include align-items(center);
                gap: 4px;
            }
        }
    }
    &__button {
        margin-top: 10px;
        width: 100%;
        background-color: transparent;
        border: 1px solid #e3e3e3;
        display: flex;
        justify-content: center;
    }
}

.next {
    margin-top: 24px;
    border-top: 1px solid #e3e3e3;
    padding-top: 24px;
    width: 100%;
    @media (min-width: 1200px) {
        margin-top: 0px;
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
    }

    &__list {
        margin-top: 15px;
        list-style: inherit;
        margin-left: 20px;
        &__item {
            margin-top: 10px;
        }
    }
}

.availableLicenses {
    margin-top: 24px;
    border-top: 1px solid #e3e3e3;
    padding-top: 24px;
    width: 100%;

    &__title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 24px;
    }
    & > .license {
        width: 100%;
        max-width: 540px;
        display: flex;
        flex-direction: row;
        & > input {
            cursor: pointer;
        }
        & > div:not(:first-child) {
            width: 100%;
            margin-left: 16px;
            & > .license__title {
                font-weight: bold;
                font-size: 18px;
            }
            & > .license__features {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}
