@import '../../../../../../../styles/media-mixins';

.changeSubscriptionSecondStep {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include min-width($desktopSM) {
        justify-content: flex-start;
    }

    & > .form {
        width: 100%;
        & > div {
            width: 100%;
        }
    }
}
.form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    @include min-width($desktopSM) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__textInputs {
        width: 100%;
        @include min-width($desktopSM) {
            width: 100%;
            min-width: 200px;
        }
        &__doubleColumn {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;

            & > div {
                width: 100%;
                margin-top: 20px;

                @include min-width($mobileXL) {
                    width: 48%;
                }
            }
        }
    }
}

.terms {
    margin-top: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    & > input {
        margin: 0 6px 0.5rem 0;
        cursor: pointer;
    }

    & > .checkbox__text {
        font-size: 0.75rem;
        letter-spacing: 0.32px;
        display: inline-block;
        margin: 0 5px 0 0;
        color: #525252;
        font-weight: 400;
        line-height: 1rem;
        vertical-align: baseline;
    }

    & > a {
        font-size: 0.75rem;
        letter-spacing: 0.32px;
        margin: 0rem 5px 0 0;
        text-decoration: none; 
        &:hover {
            text-decoration: underline;
        }
    }
}
