@import '../../../../../../../styles/media-mixins';

.changeSubscriptionThirdStep {
    width: 100%;
    display: flex;
    // justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    @include min-width($desktopSM) {
        justify-content: flex-start;
    }

    & > .form {
        width: 100%;
        & > div {
            width: 100%;
        }
    }
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    &__title {
        font-weight: bold;
        letter-spacing: 0.72px;
    }
    &__button {
        color: white;
        font-weight: bold;
        background-color: var(--neutral1000);
        &:hover {
            color: var(--neutral1000);
            font-weight: bold;
            background-color: white;
        }
    }
}

.details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    &__content {
        width: 100%;
        margin-bottom: 30px;
        padding: 20px;
    }
    &__button {
        &__save {
            color: white;
            font-weight: bold;
            background-color: var(--neutral1000);
            &:hover {
                color: var(--neutral1000);
            }
        }
        &__reset {
            color: #dc3d43;
            border: none;
            background-color: transparent;
            padding: 15px 0;
            font-size: 1rem;

            &:hover {
                text-decoration: underline;
                box-shadow: none;
            }
        }

        &__cancel {
            margin-right: 20px;
        }
    }

    &__ctaBar {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        &__right {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;

            & > .details__button {
                margin-left: 20px;
            }
        }
    }

    &__form {
        width: 100%;
        &__double {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            & > div {
                width: 100%;
                @include min-width($mobileMD) {
                    width: calc(100% / 2.06);
                }
            }
        }
        &__inline {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            & > div {
                width: 100%;
                @include min-width($mobileMD) {
                    width: calc(100% / 3.2);
                }
            }
        }
    }
}

.personalData {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;

    & > div {
        width: 100%;
        margin-top: 20px;

        @include min-width($mobileXL) {
            width: 48%;
        }
    }
}
