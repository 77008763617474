@import '../../../../../styles/media-mixins';

.menuItem {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    gap: 12px;
    padding: 12px;
    &:hover {
        background-color: var(--neutral200);
    }
    & > .menuItem__option {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.option__leftContent {
    max-width: calc(100% - 36px);
}
