@import "../../../../styles/media-mixins";

.lightPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;

    @include min-width($mobileMD) {
        padding: 20px;
    }
}
