@import '../../../../../styles/media-mixins';

.credentialCard {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include align-items(flex-start);
    gap: 24px;
    padding: 12px;
    min-width: 300px;
    background-color: var(--neutral100);
    border-radius: 8px;
    min-height: 169.57px;
    cursor: pointer;

    @include min-width($mobileMD) {
        min-width: 330px;
    }
    @include min-width(1002px) {
        @include flex(0 0 calc((100% / 2) - (32px / 2)));
    }
    @include min-width(1364px) {
        @include flex(0 0 calc((100% / 3) - (64px / 3)));
    }

    &__header {
        @include flexbox();
        @include justify-content(space-between);
        gap: 12px;
        width: 100%;
        min-height: 40px;

        &__left {
            @include flexbox();
            @include align-items(center);
            @include flex(1, 0, 0);
            gap: 12px;
            max-width: calc(100% - 35px);
        
            &__texts {
                @include flexbox();
                @include flex-direction(column);
                gap: 4px;
                max-width: calc(100% - 104px);
            }
        }
        &__right {
            margin-left: auto;
        }
    }

    &__body {
        width: 100%;
        min-height: 28px;
    }

    &__footer {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(flex-end);
        @include flex-wrap(wrap);
        gap: 12px;
        width: 100%;
        &__issuanceDate {
            @include flexbox();
            @include flex-direction(column);
            gap: 2px;
        }
        &__expirationDate {
            @include flexbox();
            @include flex-direction(column);
            gap: 2px;
            text-align: right;
            margin-left: auto;
        }
    }
}

.textSetByWidth {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    min-width: 0;
}

.newTag {
    height: 20px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 14px;
}

.fullWidth {
    max-width: none;
}
