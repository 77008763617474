@import '../../../../../../../styles/media-mixins';

.sectionDescription {
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--neutral700);
}

.editUser {
    width: 100%;
    & > form {
        overflow-y: scroll;
    }
}
