@import '../../../styles/media-mixins';

.basicCard:not(:first-child) {
    margin-top: 10px;
}

.pointer {
    cursor: pointer;
    &:hover {
        box-shadow: 0 5px 13px #0000002e;;
    }
}
.basicCard {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 3px 10px var(--boxShadowLigh);

    &__header {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;

        @include min-width($mobileSM) {
            align-items: center;
        }

        &__title {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 85%;

            &__info {
                width: 100%;
            }

            &__text {
                overflow-x: hidden;
                height: 21px;
                margin-bottom: 2px;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 0.32px;
                white-space: nowrap;

                text-overflow: ellipsis;
            }

            &__icon {
                width: 30px;
                margin-right: 15px;
            }
        }

        &__button {
            border: none;
            background-color: #ffffff;
            cursor: pointer;
            border-radius: 9px;
            padding: 5px;
            @include min-width($tabletSM) {
                margin-left: 5px;
                padding: 3px;
            }

            &__icon {
                width: 25px;
            }
            &__two {
                border-radius: 20px;
                width: 35px;
                height: 35px;
                padding: 5px;
            }
        }
    }
}

.flex_start {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column-reverse;
    & > div > .basicCard__header__title__info {
        margin-top: 10px;
    }
    & > .basicCard__header__date {
        width: 100%;
        text-align: right;
        color: var(--neutral1000);
    }
    @include min-width($mobileSM) {
        flex-direction: row;
        & > div > .basicCard__header__title__info {
            margin-top: 0;
        }
        & > .basicCard__header__date {
            width: fit-content;
        }
    }
}
