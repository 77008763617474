@import "../../../../styles/media-mixins";

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.backgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 95vw;
    max-width: 1080px;
    height: 95vh;
    padding: 0 20px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);

    @include min-width($tabletSM) {
        width: 80vw;
        height: 80vh;
    }
}

.modal__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    width: calc(95vw - 40px );
    max-width: 1038px;
    height: 60px;
    padding: 10px 0;
    background-color: white;

    @include min-width($tabletSM) {
        width: calc(80vw - 40px );
        max-width: 1038px;
        height: 65px;
        padding: 12px 0;
    }

    & > button:not(:last-child) {
        margin-right: 15px;
    }
}

.modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;
    margin-top: 2px;
    padding: 20px 0;
    background-color: white;

    & > div:first-child {
        width: 90%;
    }

    @include min-width($tabletSM) {
        min-height: 65px;
    }

    &__close {
        cursor: pointer;
        &:hover {
            & > svg > path {
                stroke: red;
            }
        }
    }
}


.modal__content {
    position: absolute;
    top: 100px;
    bottom: 60px;
    overflow-y: auto;
    width: calc(95vw - 42px );
    max-width: 1038px;

    @include min-width($tabletSM) {
        top: 83px;
        bottom: 83px;
        width: calc(80vw - 42px );
    }
}

.shortBottom {
    bottom: 20px;
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}
