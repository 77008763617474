@import '../../../../../../../styles/media-mixins';

.dataAgreementCard {
    border-radius: 12px;
    border: 1px solid var(--neutral400);
    background: var(--neutral100);
    padding: 16px;

    &__header {
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(space-between);
        gap: 12px;
        width: 100%;
        
        & > div:nth-of-type(1) {
            @include flexbox();
            @include flex-direction(column);
            gap: 8px;
            width: calc(100% - 36px);
        }

        &__delete {
            cursor: pointer;
            &:hover {
                & > svg > path {
                    stroke: var(--alert400);
                }
            }
        }
    }

    &__body {
        @include flexbox();
        @include flex-direction(column);
        gap: 12px;

        &__cred {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
            gap: 12px;
            padding: 12px;
            border-radius: 8px;
            border: 1px solid var(--neutral400);
            cursor: pointer;

            &:hover {
                color: var(--primary700);
            }

            & > div {
                @include flexbox();
                @include flex-direction(column);
                width: calc(100% - 36px);
            }
        }
    }
}
