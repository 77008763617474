@import "../../../../styles/media-mixins";

.modalContainer {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;

    &__types {
        width: 100%;

        @include min-width($tabletSM) {
            overflow: hidden;
        }
    }

    .addToApiKeySearch {
        margin-top: 0 !important;
        & > input {
            border: 1px solid #a1a1a1;
            box-shadow: none;
            border-radius: 5px;
        }
    }

    .apiKeysList {
        height: calc(95vh - 226px);
        @include min-width($tabletSM) {
            height: calc(80vh - 242px);
        }
    }
}
