@import "../../../../../../styles/media-mixins";

.credentialSectionContainer {
    .searchBar {
        border: 1px solid var(--neutral300);
        border-radius: 8px;
        margin-bottom: 20px;
        & > div {
            background-color: var(--neutral200);
        }
    }
    .scrollableList {
        padding-left: 0;
        width: 100%;
        & > div:not(:first-child) {
            margin-top: 12px;
        }
    }
}
