@import "../../../../../styles/media-mixins";

.leftColumn {
    width: 100%;
    @include min-width($mobileXL) {
        @include flex-grow(1);
    }
    .form {
        margin-top: 32px;

        @include min-width($desktopMD) {
            margin-top: calc(32px + 14px);
        }
    }
    .formHeader {
        & > div {
            gap: 12px;
            @include flex-wrap(wrap);
            @include align-items(flex-start);
            @include min-width($mobileXL) {
                @include flex-wrap(nowrap);
            }
            @include min-width(775px) {
                @include flex-wrap(wrap);
            }
            @include min-width(820px) {
                @include flex-wrap(nowrap);
            }
            button {
                min-width: fit-content;
                margin: 0;
                @include min-width($mobileXL) {
                    margin: 0 0 0 auto;
                }
                @include min-width(775px) {
                    margin: 0;
                }
                @include min-width(820px) {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
    .buttonContainer {
        width: 100%;
        margin-top: 32px;
        @include min-width($mobileXL) {
            width: auto;
            margin: 32px 0 0 auto;
        }
        @include min-width($tabletMD) {
            width: 100%;
            margin-top: 32px;
        }
        @include min-width($desktopSM) {
            width: auto;
            margin: 32px 0 0 auto;
        }
        & > button {
            width: 100%;
            @include justify-content(center);
            padding: 12px 24px;
            height: 48px;
        }
    }
}

.checkboxesPanel {
    padding: 12px;
    border: 1px solid var(--neutral400);
    border-radius: 8px;
    & > div:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.justifyContent {
    @include justify-content(space-between);
}
.advancedOptions {
    margin-top: 32px;
    &>p {
        cursor: pointer;
        &>.advancedOptionsToogle {
            width: 16px;
            height: 16px;
            padding: 8px 4px 0;

        }
        &>.toogleOpen {
            transform: rotate(180);
            -webkit-transform: rotate(180deg);
            padding: 0px 4px 8px;

        }
    }
}
.notAvailable {
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
    gap: 8px;
    border: 1px solid var(--neutral400);
    border-radius: 8px;
    padding: 8px 12px;
    > div {
        @include flex(0, 1 , auto);
        
    }
    .text {
        @include flex(0, 0, 75%);
    }
    .tagComingSoon {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: var(--neutral300);
        color: var(--neutral600);
        margin: 0 0 0 auto;
    }
}
.credentialsSkeleton{
    min-height: 220px;  
}