@import '../../styles/media-mixins';

.tabs {
  display: block;
}

.tabs__header {
  gap: 16px;
  margin-bottom: 32px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  @include flex-wrap(wrap);

  &__titles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px 20px;
    align-items: center;
    justify-content:flex-start;
    align-content: flex-start;
  }
}

.tabTitle {
  @include flexbox();
  @include align-items(center);
  gap: 4px;
  padding-bottom: 8px;
  color: var(--neutral600);
  border-bottom: 1px solid transparent;
  font-family: var(--base);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  &:hover {
    color: var(--primary1000);
  }

  & > a {
    color: var(--neutral600);
    &:hover {
      color: var(--primary1000);
    }
  }
}

.tabTitleActive {
  color: var(--primary700);
  border-bottom: 1px solid var(--primary700);
  font-weight: 700;
  &:hover {
    color: var(--primary700);
  }
   & > a {
    color: var(--primary700);
    &:hover {
      color: var(--primary700);
    }
  }
}

.disabled {
  cursor: auto !important;
  &:hover {
    color: var(--neutral600);
  }
  & > a {
    cursor: auto !important;
    color: var(--neutral600);
    &:hover {
      color: var(--neutral600);
    }
  }
}

[aria-label][tooltip-position] {
  position: relative;
}

[aria-label][tooltip-position]::before,
[aria-label][tooltip-position]::after {
  --scale: 0;
  position: absolute;
  transition: transform 100ms;
  transition-timing-function: linear;
}

[aria-label][tooltip-position]:hover::before,
[aria-label][tooltip-position]:hover::after {
  --scale: 1;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.45, 1.93);
}

[aria-label][tooltip-position]::before {
  content: attr(aria-label);
  background-color: var(--neutral1000);
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  transform: scale(0);
}

[aria-label][tooltip-position]::after {
  content: "";
  border: var(--arrow-size) solid transparent;
}

[aria-label][tooltip-position]::before,
[aria-label][tooltip-position]::after {
  --tooltip-color: var(--neutral1000);
  --arrow-size: 0.5rem;
  --scale: 0;

  z-index: 1;
  transform: translate(var(--translate-x), var(--translate-y))
    scale(var(--scale));
}

/** Top */
[aria-label][tooltip-position="top"]::before {
  position: absolute;
  --translate-x: -50%;
  color: var(--neutral100);
  --translate-y: calc(-100% - var(--arrow-size));
  top: 0px;
  left: 50%;
  transform-origin: bottom center;
}

[aria-label][tooltip-position="top"]::after {
  --translate-x: -50%;
  --translate-y: calc(-1 * var(--arrow-size));
  top: 0px;
  left: 50%;
  border-top-color: var(--tooltip-color);
  transform-origin: bottom center;
}
