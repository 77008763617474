@import "../../../../../styles/media-mixins";

.clickable {
    cursor: pointer;
    &:hover {
        color: var(--primary700);
    }
}

.copyCell {
    display: flex !important;

    & > span {
        margin-right: 5px;
    }

    &__button {
        position: relative;
        &:before {
            content: '';
            display: none;
            position: absolute;
            z-index: 9998;
            width: 0;
            height: 0;
        }

        &:after {
            content: attr(data-before-content);
            display: none;
            position: absolute;
            font-weight: bold;
            z-index: 9999;
            width: 70px;
            height: 28px;
            color: var(--neutral100);
            font-size: 10px;
            line-height: 30px;
            text-align: center;
            background: rgba(0, 0, 0, 0.72);
            border-radius: 3px;
        }

        &:hover {
            &:before,
            &:after {
                right: -70px;
                top: -10px;
                display: block;
            }
        }

        &:active,
        &:focus {
            outline: none;
            &:after {
                content: attr(data-after-content);
            }
        }

        & > svg {
            padding: 0 3px;
            height: 15px;
            width: 25px;
            cursor: pointer;
            &:hover {
                & > path {
                    stroke: var(--primary700);
                }
            }
        }
    }
}
