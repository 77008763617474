@import "../../../../../../styles/media-mixins";
@import '../../../../../../styles/colors';

tr:nth-last-child(-n + 4) > td > div > div > div:nth-last-child(1) {
    &::after {
        margin-top: -60px;
    }
}

tr:nth-last-child(-n + 2) > td > div > div > div:last-child > ul {
    margin-top: -120px;
    margin-left: 73px;
}

tr:nth-last-child(-n + 4) > td > div > div > div:last-child > ul {
    margin-top: -120px;
    margin-right: 28px;
}

.container {
  overflow-x: scroll;
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: var(--neutral300);
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
  }
}

.panelContainer {
    padding: 20px 20px 0;
    overflow-x: hidden;
    border-radius: 12px;
    box-shadow: none;
    background-color: var(--neutral100);
    min-width: 1084px;
}

.tableContainer {
  width: 100%;
  min-width: 960px;
  overflow-y:initial;
  padding: 0;
  border-radius: 10px;
  box-shadow: none;
  perspective: 1px;
  &::-webkit-scrollbar-thumb {
    border: 2px solid var(--neutral100); 
    border-radius: 8px;
    background-color: var(--neutral300);
  } 

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  & > table {
    width: 100%;

    & > thead {
      height: 2rem;
      display: table-header-group;

      & > tr > th {
        padding: 0 8px 16px;
        border-bottom: 1px solid var(--skeletonLightBG);
        color: var(--neutral1000);
        font-size: 14px;
        font-weight: 600;
        cursor: default;
        @include min-width($desktopSM) {
          font-size: 14px;
        }

        & > div {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          & > span:not(:last-child) {
            margin-left: 0;
          }
        }

        & > span > svg {
          opacity: 1;
        }
      }
    }

    & > tbody {
      & > tr {
        height: 3rem;
        min-height: 50px;
        border-bottom: 1px solid var(--skeletonLightBG);

        & > td {
          font-size: 14px;
          max-width: 400px;
          min-height: 50px;
          padding: 12px 8px;
          border-bottom: 1px solid var(--skeletonLightBG);
          color: var(--neutral1000);
          overflow-wrap: break-word;
          text-overflow: ellipsis;
          word-wrap: break-word;
          @include min-width($desktopSM) {
            max-width: 420px;
          }
          @media (min-width: 775px) and (max-width: 925px) {
            max-width: 250px;
          }
        }
      }
    }
  }
}

.action__iconContainer {
  padding: 2px;
  background-color: transparent;
  &:last-child {
    margin-top: 0;
  }
  .action__icon {
    height: 10px;
    width: 10px;
  }
}

.creationDate {
  min-width: 150px;
}

.scopes {
  min-width: 200px;
   & > span {
    text-transform: capitalize;
   }
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.noBorder {
    & > td {
        border-bottom: 0 !important;
    }
}

.appIntegrationData {
    min-width: 340px;
}
