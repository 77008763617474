@import '../../../../../../styles/media-mixins';

.detailLink {
    @include flexbox();
    @include align-items(center);
    margin: auto;
    text-align: center;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }

}

.dataAgreementsTd {
    @include flexbox();
    @include align-items(center);
    gap: 10px;

    & > div {
        background-color: var(--neutral100);
        border: 1px solid var(--neutral400);
        padding: 8px;
        border-radius: 8px;

        & > div {
            @include flexbox();
            @include align-items(flex-start);
            @include justify-content(space-between);
            gap: 8px;
        }
    }

    & > .moreDataAgreements {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        background-color: var(--neutral200);
        border-radius: 8px;
        padding: 8px;
        height: 50px;
        border: none;
        cursor: auto;
    }
}
