@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    display: block;
}

.modal {
    position: absolute;
    z-index: 99999;
    top: 35px;
    right: 0px;
    box-shadow: 0px 0px 5px 0px #0000001A;
    width: 95vw;
    max-width: 480px;
    height: 95vh;
    max-height: 600px;
    border-radius: 20px;
    background: var(--neutral100);
    overflow: hidden;
    padding: 20px;
    gap: 24px;
    cursor: auto;
    @include flexbox();
    @include flex-direction(column);
    @media (min-width: 775px) {
        top: 24px;
    }

    @include min-width($tabletSM) {
       
        width: 480px;
        height: 600px;
        max-width: 80vw;
    }
}

.modal__noContent {
    @include flex(1);
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    border-radius: 8px;
    gap: 8px;
    border: 1px solid var(--neutral400);
    & > img {
        width: 60px;
        height: 60px;
    }

    & > p {
        text-align: center;
        max-width: 287px;

    }
}

.modal__header {
    @include flexbox();
    @include align-items(flex-start);
    @include flex-direction(column);
    gap: 24px;
    width: 100%;
    background-color: var(--neutral100);

    &__top {
        @include flexbox();
        @include align-items(flex-start);
        @include justify-content(space-between);
        gap: 24px;
        width: 100%;

        &__close {
            cursor: pointer;

            &:hover {
                &>svg>path {
                    stroke: var(--alert300);
                }
            }
        }
    }
}

.modal__scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    vertical-align: initial;
    padding-right: 2px;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    gap: 24px;
    @include flexbox;
    @include flex-direction(column);
}

.modal__alert {
    margin: 0;
    padding: 12px;
}

.modal__warningAlert {
    @include flex-direction(row);
    @include align-items(flex-start);
    gap: 12px;
    border: 1px solid var(--warning200);
    background: var(--warning100);

    &>div {
        margin: 0;

        &>img {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }

        &>p {
            margin-top: 0;
            font-family: var(--base);
            font-size: 14px;
            font-style: normal;
            line-height: 16px;
        }
    }
}

.modal__errorAlert {
    @include flex-direction(row);
    @include align-items(flex-start);
    gap: 12px;
    border: 1px solid var(--alert200);
    background: var(--alert100);

    &>div {
        margin: 0;

        &>img {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }

        &>p {
            margin-top: 0;
            font-family: var(--base);
            font-size: 14px;
            font-style: normal;
            line-height: 16px;
        }
    }
}


.downloadingPanel {
    margin: 0 !important;
    padding: 12px !important;
    align-items: center !important;
    gap: 12px !important;
    & > div > div > div> p {
        line-height: 16px;
    }
}

.failedPanel {
    margin: 0;
    padding: 12px;
    width: 100%;
    margin-left: 0 !important;
    align-items: center !important;
    gap: 12px;
    border: 1px solid var(--alert200);
    background: var(--alert100);
    @include flex-direction(row);

    &>div {
        margin: 0;

        &>img {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }

        &>p {
            margin-top: 0;
            font-family: var(--base);
            font-size: 14px;
            font-style: normal;
            line-height: 16px;
        }
    }
}
