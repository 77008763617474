@import '../../../../../../../styles/media-mixins';

.connections {
    @include flexbox();
    @include flex-direction(column);
    gap: 12px;
    max-height: 250px;
    overflow-y: scroll;

    &__linkedService {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        gap: 12px;
        padding: 12px;
        background-color: var(--neutral100);
        border-radius: 8px;
        border: 1px solid var(--gray300);

        & > div {
            @include flexbox();
            @include flex-direction(column);
            @include flex-wrap(wrap);
        }

        & > p {
            text-align: right;
            margin-left: auto;
        }
    }
}
