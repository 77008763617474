@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.testModal__qr {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    padding: 0 20px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 320px;
    height: 397px;
    max-height: 500px;
    max-width: 600px;

    &__close {
        z-index: 9999;
        z-index: 9999;
        position: absolute;
        right: 0;
        padding: 20px;
        cursor: pointer;

        &:hover {
            & > svg > g > path {
                stroke: red;
            }
        }
    }
}

.apiKeyPassModal__content {
    margin-top: calc(120px - 7vw);
    @include min-width($mobileXL) {
        margin-top: 0;
    }
}
