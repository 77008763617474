@import '../../../../styles/media-mixins';

.gaTextInputContainer.required {
    label {
        &::after {
            color: var(--alert300);
            content: ' *';
        }
    }
}

.gaTextInputContainer {
    position: relative;
    .topTextsContainer {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        margin-bottom: 0.5rem;
    }

    label {
        display: inline-block;
        vertical-align: baseline;
        text-align: left;
        @include flex(1 1 0);
        & > div {
            right: -15px;
            bottom: -4px;
            width: 0;
        }
    }
    .extraDescription {
        width: 100%;
        text-align: right;
        @include flex(0 0 30%);
        margin-left: 15px;
        &.noLabel {
            margin-left: 0;
            @include flex(1 1 0);
        }
    }
    .bottomTextsContainer {
        > * {
            text-align: left;
        }
    }

    input {
        letter-spacing: 0.16px;
        outline: 2px solid transparent;
        outline-offset: -2px;
        width: 100%;
        height: 2.5rem;
        padding: 0 1rem;
        border-radius: 8px;
        color: var(--neutral1000);
        background-color:var(--neutral100);
        border: 1px solid var(--neutral400);
        text-decoration: none;
        &:hover, &:active, &:focus {
            border-color: var(--primary700);
        }
        &::placeholder {
            color: var(--neutral600);
        }
    }
}

.gaTextInputContainer.readOnly, .gaTextInputContainer.disabled{
    input {
        background-color: var(--neutral300);
        border: 1px solid var(--neutral300);
        color: var(--neutral600);
        cursor: not-allowed;
        &:active,
        &:focus {
            outline: none;
    
        }
    }
}

.gaTextInputContainer.copyOption {
    input {
        padding-right: 2.5rem;
        border: 1px solid var(--neutral300);
        &:active, &:focus {
            outline: none;
        }
        &:hover{
            border: 1px solid var(--neutral300);
        }
        caret-color: transparent;
    }
}
.gaTextInputContainer.error {
    input {
        border-color: var(--alert300);
    }
}
.gaTextInputContainer.success {
    input {
        border-color: var(--success300);
    }
}

.deleteOption {
    position: absolute;
    right: 12px;
    top: 8px;
    & > svg {
        cursor: pointer;
        height: 24px;
        &:hover {
            & > path {
                stroke: var(--primary700);
            }
        }
    }
}
