@import '../../../../styles/media-mixins';

.darkButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 35px;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    letter-spacing: -0.36px;

    @include min-width($mobileSM) {
        height: 40px;
    }

    &__icon {
        display: none;

        @include min-width($mobileSM) {
            display: inline-block;
            margin-left: 10px;
        }
        @include min-width($mobileSM) {
            margin-left: 15px;
        }
    }
}

.darkButtonActive {
    border: 1px solid var(--primary700);
    background-color: var(--primary700);
    color: var(--neutral100);
    cursor: pointer;

    &:hover {
        background-color: var(--primary900);
        box-shadow: 0 5px 13px #0000002e;;
        color: white;

        & > .darkButtonIconActive {
            stroke-width: 2;

            & > path {
                stroke: var(--primary700) !important;
            }
        }
    }
}

.darkButtonInActive {
    width: fit-content;
    position: relative;
    border: 1px solid var(--neutral500);
    background-color: var(--neutral500);
    color: var(--neutral100);
    cursor: auto !important;
}

.darkButtonIconInActive {
    stroke-width: 2;

    & > path {
        stroke: var(--neutral100);
    }
}

.darkButtonIconActive {
    stroke-width: 2;
    & > path {
        stroke: var(--neutral100);
    }
}

.darkButtonHoverTooltip {
    & > div {
        text-align: center;
        & > p {
            padding: 0;
            height: auto;
        }
    }
    &.leftPosition {
        & > div > div {
            right: calc(100% + 40px) !important;
        }
    }
}

// SIZES
.large {
    padding: 12px 24px;
    height: 48px;
}

.medium {
    padding: 15px 15px;
    height: 35px;
}

.small {
    padding: 8px 16px;
    height: 32px;
}
