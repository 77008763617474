@import '../../../../../../../../../styles/media-mixins';


.credEmptyContent {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    min-height: calc(100% - 158px);
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 158px);
    min-height: 250px;
    top: 138px;
    border-radius: 8px;
    border: 1px solid var(--gray300);
    padding: 5px;

    &__content {
        @include flexbox();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        @include align-items(center);
        @include justify-content(center);
        gap: 8px;

        & > p {
            text-align: center;
        }
    }
}
