@import "../../../styles/media-mixins";

.searchBar {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 10px 0 15px;

    &__icon {
        position: absolute;
        z-index: 2;
        display: block;
        left: 12px;
    }

    &__inputContainer {
        @include flexbox();
        @include align-items(center);
        gap: 8px;
        width: 100%;
        padding: 10px 42px;
        border-radius: 8px;
        background-color: var(--neutral100);

        & > .input {  
            @include flex(1, 0, 0);
            color: var(--neutral1000);
            border: none;
            padding: 0;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
    }

    &__cleanIcon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 20px;
        padding: 1px 0 0 1px;
        right: 12px;
        border-radius: 100%;
        background-color: var(--neutral600);
        cursor: pointer;
        &:hover {
           background-color: var(--primary700);
        }
    }

    & > .withBorder {
        border: 1px solid var(--neutral400);
    }
}
