@import '../../../../../../../../../../styles/media-mixins';

.subcategoryCell {
    min-width: 184px;
    & > span {
        @include flexbox();
        @include flex-wrap(nowrap);
        @include align-items(flex-start);
        @include justify-content(flex-start);
        & > p {
            margin-right: 10px;
            width: calc(100% - 10px)
        }
    }

    .hoverTooltipTable {
        width: auto;
    }
}
