@import '../../../../styles/media-mixins';

.switchButton {
    border: 1px solid #e3e3e3;
    width: 221px;
    padding: 4px;
    border-radius: 8px;
    height: 45px;
}

.switchesContainer {
    width: 16rem;
    position: relative;
    display: flex;
padding: 0;
    position: relative;
    line-height: 35px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
}

.switchesContainer input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

.switchesContainer label {
    width: 41%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    height: 32px;
    z-index: 99;
    line-height: 35px;
    font-weight: bold;
    text-transform: capitalize;
}

.switchWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 41%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform 0.1s cubic-bezier(0.77, 0, 0.175, 1);
}

.switch {
    border-radius: 8px;
    background: var(--primary700);
    height: 32px;
}

.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: white;
    transition: opacity 0.1s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}

.labelSelectedValue {
    animation: changeToSelectColor 0.1s linear;
    color: white;
}

.labelUnselectedValue {
    animation: changeToUnselectColor 0.1s linear;
    color: var(--neutral1000);
}

.labelDisabledValue {
    cursor: auto !important;
}

/* slide the switch box from right to left */
.switchesContainer input:nth-of-type(1):checked ~ .switchWrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switchesContainer input:nth-of-type(2):checked ~ .switchWrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switchesContainer
    input:nth-of-type(1):checked
    ~ .switchWrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switchesContainer
    input:nth-of-type(2):checked
    ~ .switchWrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
}

@keyframes changeToSelectColor {
    0% {
        color: var(--neutral1000);
    }

    100% {
        color: white;
    }
}

@keyframes changeToUnselectColor {
    0% {
        color: white;
    }

    100% {
        color: var(--neutral1000);
    }
}

.disabledTooltip {
    & > div > div:nth-of-type(2) {
        left: calc(75% + 30px) !important;
        text-transform: none;
        text-align: left;
        max-width: 162px !important;
    }
}

.disbledOption {
    @include flexbox();
    @include align-items(center);
    gap: 8px;
}
