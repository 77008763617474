@import '../../../../../styles/media-mixins';

.errorPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 20px 0 10px;
    padding: 8px;
    border-radius: 10px;
    width: 100%;
    background-color: white;
    border: 2px solid #dc3d43;
    color: var(--neutral1000);
    max-width: 1180px;

    &__header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        & > img {
            width: 20px;
            margin-right: 10px;
        }
        &__title {
            color: #dc3d43;
            font-weight: bold;
        }
    }

    &__content {
        margin-left: 30px;
        & > p {
            margin-top: 10px;
        }
    }
}
