@import '../../../../styles/media-mixins';


.gaSelectCheckboxContainer.required {
    & > div > label {
        &::after {
            color: var(--alert300);
            content: ' *';
        }
    }
}


.gaSelectCheckboxContainer {
    & > .topTextsContainer {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        margin-bottom: 0.5rem;
    }


    label {
        display: inline-block;
        vertical-align: baseline;
        text-align: left;
        @include flex(1 1 0);
         & > div {
            right: -15px;
            bottom: -4px;
            width: 0;
        }
    }

    & > .extraDescription {
        width: 100%;
        text-align: right;
        @include flex(0 0 30%);
        margin-left: 15px;
        &.noLabel {
            margin-left: 0;
            @include flex(1 1 0);
        }
    }
    & > .overSelect {
        position: relative;
        border: 1px solid transparent;
        border-radius: 8px;
        border: 1px solid var(--neutral400);
        &:hover {
            border: 1px solid var(--primary700);
        }
        & > .customPlaceholder{
            position: absolute;
            left: 12px;
            top: 12px;
            color: var(--neutral600)
        }
        & > select {
            margin: 0;
            letter-spacing: 0.16px;
            height: 2.5rem;
            outline: 2px solid transparent;
            width: 100%;
            border: none;
            color: var(--neutral1000);
            background-image: none;
            padding: 0px 3rem 0px 12px;
            &:hover {
                background-color: transparent;
            }
            & > option {
                font-weight: 400 !important;
            }
        }

        & > svg {
            position: absolute;
            top: 8px;
            right: 1rem;
            pointer-events: none;
            overflow-clip-margin: content-box;
            overflow: hidden;
            path {
                stroke: var(--neutral400);
            }
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            width: 11px;
        }
        &::-webkit-scrollbar:horizontal {
            height: 20px;
        }
        &::-webkit-scrollbar-thumb {
            border: 2px solid var(--neutral100);
            /* should match background, can't be transparent */
            border-radius: 8px;
            background-color: var(--neutral300);
        }
    }
}
.checkboxes {
    display: none;
    background-color: var(--neutral100);
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    margin: 1px;
    & > .searchBarContainer {
        padding: 24px 24px 0;
        .searchBar {
            margin: 0 0 20px;
            &  > div:nth-of-type(1) {
                background-color: var(--neutral200);
            }
        }
    }

    & > .list {
        max-height: 320px;
        overflow-y: scroll;
        padding: 0 15px 24px 24px;
        &.noSearchBar {
            padding: 24px 24px 20px;
            padding-right: 15px;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            padding-top: 50px;
            width: 11px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 0;
        }

        &::-webkit-scrollbar-thumb {
            padding-top: 50px;
            border: 2px solid var(--neutral100);
            border-radius: 8px;
            background-color: var(--neutral300);
        }

        & > div {
            @include justify-content(space-between);
            padding: 12px;
            gap: 8px;
            border-radius: 8px;
            cursor: pointer;
            background-color: var(--neutral100);
            &:hover {
                background-color: var(--neutral200);
            }
            & > div {
                & > label {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 490px;
                }
            }
        }
    }
    .buttonsContainer {
        width: 100%;
        padding: 16px;
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        border-top: 1px solid var(--neutral300);
    }
}
.gaSelectCheckboxContainer.disabled,
.gaSelectCheckboxContainer.readOnly {
    & >.overSelect {
        pointer-events: none;
        background-color: var(--neutral300);
        border: 1px solid var(--neutral300);
        cursor: not-allowed;
        & > select {
            color: var(--neutral600);
        }
        & > svg {
            display: none;
        }
    }
    
}
.noneEvents {
    pointer-events: none;
}