@import '../../../../../../../styles/media-mixins';
@import "../../../../../../../styles/colors";

.bodyContainer {
    width: 100%;
    @include flexbox();
    @include flex-direction(column);
    gap: 20px;
    &__redirectionForm {
        gap: 12px;
    }
}

.linkButton {
    cursor: pointer;
    width: fit-content;
    & > span {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(nowrap);
        gap: 4px;
    }
}


.multipleUrlsForm {
    @include flexbox();
    @include flex-direction(column);
    gap: 12px;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}



.leftColumn {
    width: 100%;
    @include min-width(1200px) {
        max-width: 288px;
    }
    
    .formHeader {
        & > div {
            gap: 12px;
            @include flex-wrap(wrap);
            @include align-items(flex-start);
            @include min-width($mobileXL) {
                @include flex-wrap(nowrap);
            }
            @include min-width(775px) {
                @include flex-wrap(wrap);
            }
            @include min-width(820px) {
                @include flex-wrap(nowrap);
            }
            button {
                min-width: fit-content;
                margin: 0;
                @include min-width($mobileXL) {
                    margin: 0 0 0 auto;
                }
                @include min-width(775px) {
                    margin: 0;
                }
                @include min-width(820px) {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}

.tutorialContainer {
    width: 100%;
    border-bottom: 1px solid var(--neutral300);
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    @include align-items(center);
    .tutorialText {
        &__tooltip > div {
            & > div[aria-expanded='true'] {
                & > svg {
                    fill: var(--primary700) !important;
                }
            }
            & > div[aria-expanded='false'] {
                & > svg {
                    fill: var(--gray700) !important;
                }
            }
        }
        > p {
            color: var(--neutral800);
        }
    }
}

.companyLogoContainer {
    width: 100%;
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    @include align-items(center);
    .companyLogoText {
        &__tooltip > div {
            & > div[aria-expanded='true'] {
                & > svg {
                    fill: var(--primary700) !important;
                }
            }
            & > div[aria-expanded='false'] {
                & > svg {
                    fill: var(--gray700) !important;
                }
            }
        }
        > p {
            color: var(--neutral800);
        }
    }
}

.activationToggle {
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include align-items(flex-end);
    @include justify-content(center);
    .activationCheckbox {
        height: 0;
        width: 0;
        visibility: hidden;
    }
    .activationCheckboxLabel {
        cursor: pointer;
        text-indent: 49px;
        width: 39px;
        height: 24px;
        background: var(--neutral100);
        border: 2.25px solid var(--neutral400);
        display: block;
        border-radius: 90px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            width: 19px;
            height: 18px;
            background: var(--neutral100);
            border: 2.25px solid var(--neutral400);
            border-radius: 90px;
            transition: 0.3s;
        }
    }
}

.activationCheckbox:checked + .activationCheckboxLabel {
    background: var(--primary700);
    border-color: var(--primary700);
}

.activationCheckbox:checked + .activationCheckboxLabel:after {
    width: 19px;
    left: calc(100% - 22px);
    border-color: var(--primary700);
}

.verificationAppIntSection {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    gap: 32px;
    max-width: 1180px;
    flex-wrap: wrap;
    position: relative;
    margin: 0 auto;
    width: 100%;
    @include min-width(1200px) {
        @include flex-direction(row);
        @include justify-content(space-between);
    }
}
