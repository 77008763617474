@import '../../../styles/media-mixins';

.loadingContainer {
    width: 100%;
    height: 100%;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    position: fixed;
    z-index: 6000;
    top: 0;
    left: 0;
    background-color: rgba(169, 169, 172, 0.3);
    &.withNoOverlay {
        background-color: transparent;
    }
}

.loader {
    height: 0;
    width: 0;
    padding: 45px;
    border: 10px solid var(--primary700);
    border-right-color: var(--neutral300);
    border-radius: 50%;
    animation: rotate 1s infinite linear;
    position: absolute;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
