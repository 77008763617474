@import '../../../../../../../styles/media-mixins';

.notificationCard {
    @include flexbox();
    @include flex-direction(column);
    border-radius: 8px;
    border: 1px solid var(--neutral300);
    padding: 12px;

    &__header {
        @include flexbox();
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        gap: 8px;
        width: 100%;
        background-color: var(--neutral100);
        & > .unreadIcon {
            padding: 3px 0 0;
            @include flexbox();
        }

        & > .info {
            @include flex(1);
            @include flexbox();
            @include align-items(flex-start);
            gap: 8px;
            max-width: 100%;

            & > div > p {
                white-space: break-spaces;
            }

            .notStatusTag {
                margin-top: 8px;
                height: 32px;
                @include flexbox();
                @include align-items(center);
            }

            .getItDisabled {
                background-color: var(--neutral300) !important;
                color: var(--neutral500) !important;
            }

        }

        & > .actions {
            text-align: right;
            @include flex(1);
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(space-between);
            @include align-items(flex-end);

            & > div > div {
                cursor: pointer;
                padding-top: 0;

                & > button > svg {
                    width: 16px;
                    -ms-transform: rotate(90deg);
                        /* IE 9 */
                        -webkit-transform: rotate(90deg);
                        /* Chrome, Safari, Opera */
                        transform: rotate(90deg);
                }

                & > div:nth-of-type(1) {
                    top: auto;
                    right: 0;
                    width: auto;
                }
                & > div:nth-of-type(2) {
                    max-width: none;
                }
            }

            & > button {
                border-radius: 20px;
                padding: 8px 12px;
                height: 32px;
            }

            & > .disabled {
                background-color: var(--neutral300) !important;
                color: var(--neutral500) !important;
                border: none !important;
                &:hover {
                    box-shadow: none !important;
                }
            }
        }

    }
}
