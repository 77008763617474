@import '../../../../../../../styles/media-mixins';

.detailContent {
    @include flexbox();
    @include flex-direction(column);
    display: block;
    border-radius: 8px;
    border: 1px solid var(--gray300);
    margin: 0 12px 12px;
}

.detailContentWithoutBorder {
    border: 0px;
    margin-bottom: 0px;
}

.detailContentHidden {
    display: none;
}

.claimCredential {
    padding: 16px 12px;
}

.multiClaimElement:not(:last-child) {
    border-bottom: 1px solid var(--neutral400);
}

.photo {
    width: 100%;
    height: auto;
}

.containerStyle {
    background-color: 'transparent';
    padding: 0px;
    border-radius: 8px;
    margin-bottom: 12px;
    border: none;
}

.tableContainerStyle {
    background-color: var(--neutral100);
    padding: 4px 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    border: 1px solid var(--gray300);
}

.tableDetailTitleStyle {
    border-bottom-color: var(--gray300);
    border-bottom-width: 0;
    background-color: 'transparent';
    padding-left: 0;
    padding-right: 0;
}

.tableLastDetailStyle {
    border-bottom-color: var(--gray300);
    border-bottom-width: 0;
    background-color: var(--neutral100);
    padding-left: 0 !important;
    padding-right: 0 !important;
    cursor: default;
}

.tableDetailStyle {
    border-bottom-color: var(--gray300);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    background-color: var(--neutral100);
    padding-left: 0 !important;
    padding-right: 0 !important;
    cursor: default;
}

.singleDetailTitleStyle {
    margin-bottom: 12px;
    border-width: 0;
    border-color: var(--gray300);
    border-radius: 10px;
    background-color: 'transparent';
    padding-left: 0;
}

.singleDetailStyle {
    margin-bottom: 12px;
    border: 1px solid var(--gray300);
    border-radius: 10px;
    background-color: var(--neutral100);
    padding: 12px;
    cursor: default;
}

.noCredentialsLoaded {
    padding: 0 12px 12px;
}

.demoContent {
    cursor: auto !important;
    & > div:nth-of-type(2) {
        display: none;
    }
}
