.tenantModalBackgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.6);
    max-width: none !important;
}

.tenantModal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    padding: 25px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    width: 365px;
    height: fit-content;

    &__header {
        z-index: 9999;
        z-index: 9999;
        width: 100%;
        right: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        cursor: pointer;
        margin-bottom: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--neutral1000);

        & > p {
            font-weight: bold;
            font-size: 26px;
        }

        &__close {
            padding: 5px;
            padding-left: 10px;
            margin-left: auto;
            & > svg {
                width: 15px;
            }
            &:hover {
                & > svg > g > path {
                    stroke: red;
                }
            }
        }
    }

    & > svg {
        margin: auto;
    }

    &__active {
        font-weight: bold;
        font-size: 18px;

        & > span {
            font-weight: normal;
        }
    }

    &__button {
        margin-top: 20px;
        width: fit-content;
        margin-left: auto;
    }
}
