@import '../../../../../styles/media-mixins';

.limits {
    width: 100%;
    @include flexbox();
    @include flex(1);
}

.currentSubscriptionPanel {
    border-radius: 10px !important;
    padding: 26.25px !important;
    @include justify-content(space-between);
    .currentSubscriptionPanel__info{
        width: 100%;
        @include flexbox();
        @include flex-wrap(nowrap);
        @include align-items(center);
        @include justify-content(space-between);
        & > .limits {
            width: 100%;
            margin-top: 20px;
        }
        .description {
            color: var(--primary700);
            font-family: var(--custom);
        }
    }
}

.licenseActive {
    color: var(--neutral100) !important;
    position: relative;
    padding: 4px 8px;
    background-color:var(--success300);
    border-radius: 4px;
}

.licensePending {
    color: var(-neutral900) !important;
    position: relative;
    padding: 4px 8px;
    background-color: var(--warning300);
    border-radius: 4px;
}

.licenseCancelled {
    color: var(-neutral100) !important;
    position: relative;
    padding: 4px 8px;
    background-color: var(--alert300);
    border-radius: 4px;
}

.licenseInactive {
    color: var(-neutral900) !important;
    position: relative;
    padding: 4px 8px;
    background-color: var(--warning300);
    border-radius: 4px;
}
