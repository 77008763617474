@import '../../../../../styles/media-mixins';
@import "../../../../../styles/colors";

.bodyContainer {
    width: 100%;
    height: 100vh;
    @include flexbox();
    @include flex-direction(column);
    gap: 20px;
}

.contentCentered {
    @include justify-content(center);
}

.image {
    max-width: 100%;
    height: auto;
}
.imageContainer{
    text-align: center;
}

.sixDigitContainer {
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    gap: 12px;
}


.terms {
    @include flexbox;
    @include align-items(flex-start);
    @include flex-wrap(nowrap);
    .termsText {
        & > .checkbox__text {
            margin: 0 5px 0 0;
        }
        & > a {
            margin: 0rem 5px 0 0;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.credentialsContainer {
    @include flexbox;
    @include flex-direction(column);
    gap: 24px;
    .credRequirements {
        @include flexbox;
        @include flex-direction(column);
        gap: 12px;
    }
}
