@import '../../../../../../../../../styles/media-mixins';

.contentHeader {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
}

.goMainbutton {
    border: 1px solid var(--neutral500);
}

.levelTag {
    background-color: var(--neutral300);
    color: var(--neutral700);
    padding: 8px;
}

.blockedContent {
    @include flex-direction(row);
    @include align-items(center);
    bottom: 20px;
    position: absolute;
    margin: 0;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    background-color: var(--warning100);
    padding: 12px;
    gap: 12px;
    margin: 0;

    & > div:nth-of-type(1) > img {
        height: 24px;
        width: 22px;
        margin-right: 0;
    }

    & > div:nth-of-type(2) {
        margin: 0;
        & > p {
            margin: 0;
            font-family: var(--base);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    & > div:nth-of-type(3) {
        cursor: pointer;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }
}
