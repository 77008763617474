@import '../../../../styles/media-mixins';

.secondFactorCard {
    margin-top: 16px;
    padding: 16px;
    width: 100%;
    border: 1px solid var(--neutral400);
    border-radius: 8px;
    &__header {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        &__button {
            &__icon {
                @include flexbox;
                @include justify-content(space-between);
                gap: 12px;
                & > .edit {
                    cursor: pointer;
                    &:hover {
                        & > path {
                            fill: none;
                            stroke: var(--primary700);
                        }
                    }
                }
                & > .bin {
                    cursor: pointer;
                    &:hover {
                        & > path {
                            fill: none;
                            stroke: red;
                        }
                    }
                }
            }
        }
    }
}
