@import '../../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    & > .modal {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 95vw;
        max-width: 480px;
        height: 95vh;
        max-height: 667px;
        border-radius: 20px;
        background: white;
        transform: translate(-50%, -50%);
        overflow: hidden;
        padding: 20px;

        @include min-width($tabletSM) {
            width: 80vw;
            height: 80vh;
        }
    }

    .modal__header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-end);
        width: 100%;
        height: 32px;
        background-color: var(--neutral100);

        &__close {
            cursor: pointer;
            &:hover {
                & > svg > path {
                    stroke: var(--alert400);
                }
            }
        }
    }

    .modal__content {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        padding: 24px;
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 156px);

        @include min-width($mobileMD) {
            height: calc(100% - 76px);
        }

        & > img {
            width: 120px;
            height: auto;
            padding: 8px 0;
        }

        & > p {
            text-align: center;
        }
    }

    .modal__footer {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include justify-content(space-between);
        gap: 12px 24px;
        width: 100%;
        background-color: var(--neutral100);

        @include min-width($mobileMD) {
            @include flex-direction(row);
        }

        & > button {
            @include flex(1 0 0);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            padding: 12px 24px;
            height: 44px;
            width: 100%;

            @include min-width($mobileMD) {
                @include flex-direction(row);
                width: auto;
            }
        }

         &__lightButton {
            border: 1px solid var(--neutral500);
            &:hover {
               border: 1px solid var(--primary700);
            }
        }
    }

}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}

.tabCursorPointer {
    & > div > ul > li {
        cursor: auto !important;
    }
}
