@import "../../../../styles/media-mixins";

.searchableListHeader {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    @include min-width($mobileXL) {
        align-items: center;
        flex-direction: row;
        height: 78px;
    }

    & > .searchBar {
        @include min-width($mobileXL) {
            max-width: 300px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &__description {
        width: fit-content;
        margin-top: 20px;
        margin-bottom: 0;

        @include min-width($mobileXL) {
            margin-bottom: 20px;
        }
    }
}
