.tabCard {
    border: 1px solid #e7e7e7;
    box-shadow: none;
}

.rolesStatusPanel {
    background-color: #0000001a !important;
    & > div {
        margin-left: 0 !important;
    }
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-top: 20px;
    background-color: white;
    gap: 15px;
}