
@import "../../../../../../../styles/media-mixins";

.previewImageContainer {
    @include flexbox();
    @include align-items(center);
    @include flex(1);
    width: 100%;
    overflow-x: scroll;
    &:not(.editStyles) {
        @include min-width(468px) {
            @include justify-content(center);
        }
    }

    &.editStyles {
        @include min-width(495px) {
            @include justify-content(center);
        }
        .previewImage {
            border: 1px solid var(--neutral300);
            &__body {
                &__right {
                    border: none
                }
            }
        }
    }

    .previewImage {
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        max-width: 381px;
        min-width: 380px;
        @include min-width(1320px) {
            min-width: 0;
        }

        &__header {
            background-color: var(--neutral300);
            width: 100%;
            gap: 9px;
            padding: 8px 12px;
            @include flexbox();
            @include align-items(center);

            & > span {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                &:nth-of-type(1) {
                    background-color: #EE6A5F;
                }
                &:nth-of-type(2) {
                    background-color: #F5BD4F;
                }
                 &:nth-of-type(3) {
                    background-color: #63C655;
                }
            }
        }

        &__body {
            @include flexbox();
            @include justify-content(space-between);
            @include flex-direction(column);
            background-color: var(--neutral200);
            border-radius: 9.928px 9.928px 6.989px 6.989px;

            & > div {
                @include flexbox();
                @include flex-direction(column);
                @include justify-content(space-between);
            }

            &__left {
                padding-bottom: 3.97px;
                width: 100%;
                background-color: var(--neutral200);

                & > img {
                    width: 100%;
                }

                &__getVerified {
                    @include flexbox();
                    @include align-items(center);
                    gap: 3.92px;
                    padding: 7.942px 11.913px;
                    p {
                        font-family: "Poppins";
                        font-size: 7.842px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 10.46px;
                        & > strong {
                            font-family: "Ubuntu";
                            font-size: 8.717px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 12.204px;
                        }
                    }
                } 
            }

            &__right {
                gap: 19.86px;
                width: 100%;
                border-radius: 9.928px 9.928px 6.989px 6.989px;
                border-right: 0.874px solid var(--neutral300);
                border-bottom: 0.874px solid var(--neutral300);
                border-left: 0.874px solid var(--neutral300);
                box-shadow: 0px -4.964px 7.942px 0px rgba(47, 45, 139, 0.08);
                background-color: var(--neutral100);
                padding: 15.885px 11.913px 5.957px 11.913px;

                &__content {
                    @include flexbox();
                    @include flex-direction(column);
                    @include flex(1 0 0);
                    gap: 19.86px;

                    &__companyLogo {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        padding: 2px 4px;
                        border-radius: 2px;
                        border: 0.5px solid var(--neutral500);
                        text-align: center;
                        font-family: "Poppins";
                        font-size: 6px;
                        width: fit-content;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 7px;
                    }

                    &__companyLogoImg {
                        max-height: 18px;
                        max-width: 100%;
                        height: 18px;
                        width: fit-content;

                        & > img {
                            height: 100%;
                        }
                    }

                    &__scan {
                        & > p:nth-of-type(1) {
                            font-size: 15.887px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 19.859px;
                        }
                        & > p:nth-of-type(2) {
                            margin-top:  3.972px;
                            font-family: "Poppins";
                            font-size: 8.936px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 11.915px; 
                            color: var(--neutral700);
                            & > strong {
                                color: var(--primary700);
                            }
                        }
                    }

                    &__qr {
                        @include flexbox();
                        gap: 19.86px;
                        & > div:nth-of-type(1) {
                            @include flexbox();
                            @include align-items(center);
                            @include flex-direction(column);
                            @include justify-content(center);
                            gap: 9.929px;
                            padding: 11.92px;
                            border-radius: 5.958px;
                            height: fit-content;
                            background-color: var(--neutral100);
                            box-shadow: 0px 0px 9.929px 0px rgba(0, 0, 0, 0.10);
                            & > p {
                                width: 100%;
                                color: var(--neutral700);
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 6.951px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 7.944px;

                                & > strong {
                                    color: var(--primary700);
                                }
                            }
                            & > img {
                                max-width: 164px;
                            }
                        }
                        &__shared {
                            @include flexbox();
                            @include flex-direction(column);
                            gap: 15.88px;
                            @include flex(1 0 0);
                            height: fit-content;
                            border-radius: 5.957px;
                            max-height: 188px;
                            overflow-y: scroll;
                            padding: 11.91px;
                            background-color: var(--primary100);
                            &__header {
                                & > p:nth-of-type(1) {
                                    font-family: "Poppins";
                                    font-size: 6.95px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 7.942px;
                                    margin-bottom: 1.99px;
                                }

                                & > p:nth-of-type(2) {
                                    font-family: "Ubuntu";
                                    font-size: 9.929px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 11.915px;

                                    & > strong {
                                        color: var(--primary700);
                                    }
                                }
                            }

                            &__scope {
                                @include flexbox();
                                @include flex-direction(column);
                                gap: 7.94px;
                                &__item {
                                    @include flexbox();
                                    gap: 3.97px;

                                    & > div:nth-of-type(1) {
                                        width: 11.91px;
                                        height: 11.91px;
                                        @include flexbox();
                                        @include justify-content(flex-start);
                                        @include align-items(flex-start);
                                    }

                                    & > div:nth-of-type(2) {
                                        @include flexbox();
                                        @include flex-direction(column);

                                        & > p:nth-of-type(1) {
                                            font-family: "Poppins";
                                            font-size: 7.942px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 9.928p;
                                        }
                                        & > p:nth-of-type(2) {
                                            font-family: "Poppins";
                                            font-size: 6.95px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 7.942px;
                                        }
                                    }
                                }
                            }
                            &__policiesTerms {
                                & > p:nth-of-type(1) {
                                    font-family: "Poppins";
                                    font-size: 6.102px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 6.974px;
                                    > strong {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }

                 &__footer {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);
                    @include flex-direction(column);
                    gap: 9.93px;
                    &__firstRow {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(space-between);
                        width: 100%;
                        &__language {
                            @include flexbox();
                            @include align-items(center);
                            font-family: "Poppins";
                            font-size: 6.95px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 7.942px;
                            gap: 1.99px;
                            color: var(--neutral700);
                        }
                        &__tutorial {
                            font-family: "Poppins";
                            font-size: 6.95px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 7.942px;
                        }
                    }

                    &__terms {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        gap: 9.93px;
                        & > p {
                            font-family: "Poppins";
                            font-size: 6.95px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 7.942px;
                            text-transform: capitalize;
                        }
                    }

                    & > img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
