@import "../../../../../../../styles/media-mixins";

.secondFactorList {
    margin-left: 0;
}
.modal {
    padding: 24px;
    > div {
        padding: 0;
        min-height: auto;
    }
    > div > div > div {
        padding: 0;
    }
}