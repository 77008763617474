@import '../../../../styles/media-mixins';
$brand-success: var(--neutral1000);
$loader-size: 100px;
$check-height: calc($loader-size/2) - 8;
$check-width: calc($check-height/2);
$check-left: calc($loader-size/6) + calc($loader-size/12) - 8;
$check-thickness: 3px;
$check-color: $brand-success;

.statusPanelBackgroundLayer {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    background: rgba(0, 0, 0, 0.174);
}

.statusPanelBackgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.statusPanelContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
    height: -moz-fit-content;

    @media (min-width: 775px) {
        margin-left: 250px;
    }
}

.statusPanelModal {
    @media (min-width: 775px) {
        margin-left: 0 !important;
    }
}

.statusPanel {
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 90vw;
    height: fit-content;
    max-height: 95vh;
    padding: 20px;
    border-radius: 20px;
    background: white;
    text-align: center;

    @include min-width($mobileSM) {
        width: fit-content;
        min-width: 350px;
        max-width: 340px;
        min-height: 370px;
        max-height: 80vh;
        padding: 30px;
    }

    &__closeIcon {
        margin-bottom: 20px;
        margin-left: auto;
        cursor: pointer;

        & > svg {
            & path {
                fill: var(--neutral300);
            }
        }

        &:hover {
            & > svg {
                & path {
                    fill: var(--neutral1000);
                    stroke-width: 2px;
                }
            }
        }
    }

    & > .operationInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        min-height: 90px;
    }
}

.circleLoader {
    position: relative;
    align-self: center;
    width: $loader-size;
    height: $loader-size;
    border: 10px solid rgba(0, 0, 0, 0.2);
    border-left-color: $check-color;
    border-radius: 50%;
    vertical-align: top;
    animation: loader-spin 1.2s infinite linear;
}

.loadSuccess {
    border-color: green;
    animation: none;
    transition: border 500ms ease-out;

    -webkit-animation: none;
}

.loadFailed {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #d10c0c;
    animation: none;
    transition: border 0 ease-out;

    -webkit-animation: none;
}

.showCheckmark {
    display: block;
}

.hiddenCheckmark {
    display: none;
}

.checkmark {
    &.draw:after {
        animation-duration: 800ms;
        animation-name: checkmark;
        animation-timing-function: ease;
        transform: scaleX(-1) rotate(135deg);
    }

    &:after {
        position: absolute;
        top: $check-height;
        left: $check-left;
        width: $check-width;
        height: $check-height;
        opacity: 1;
        border-top: $check-thickness solid green;
        border-right: $check-thickness solid green;
        content: '';
        transform-origin: left top;
    }
}

.errormark {
    display: none;
    width: 40px;
    height: 40px;

    & > path {
        fill: #d10c0c;
    }
}

.animateErrorIcon {
    display: block;
    animation: animateErrorIcon 0.5s;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes checkmark {
    0% {
        width: 0;
        height: 0;
        opacity: 1;
    }

    20% {
        width: $check-width;
        height: 0;
        opacity: 1;
    }

    40% {
        width: $check-width;
        height: $check-height;
        opacity: 1;
    }

    100% {
        width: $check-width;
        height: $check-height;
        opacity: 1;
    }
}

@keyframes animateErrorIcon {
    0% {
        opacity: 0;
        transform: rotateX(100deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}
