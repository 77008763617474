@import "../../../styles/media-mixins";

.credentialCard:not(:first-child) {
    margin-top: 10px;
}

.credentialCard {
    width: 100%;
    height: auto;
    padding: 16px 12px;
    border: 1px solid var(--neutral400);
    border-radius: 8px;

    &__header {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        &__title {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 85%;

            &__icon {
                margin-right: 12px;
                width: 24px;
            }
        }

        &__button {
            height: 15px;
            padding: 0;
            border: none;
            background-color: #ffffff;
            cursor: pointer;

            @include min-width($tabletSM) {
                width: 24px;
                height: 24px;
                margin-left: 10px;
                padding: 3px;
            }

            &:hover {
                & > svg {
                    & path {
                        stroke: red;
                    }
                }
            }
        }

        &__checkbox {
            width: 15px;
            height: 15px;
            margin: 0;
            cursor: pointer;
        }
    }

    &__credentials {
        margin: 15px 0 0 10px;
        padding-left: 0;
        border-left: 1px solid var(--neutral300);
        border-left: 1px solid var(--neutral300);

        @include min-width($tabletSM) {
            margin: 15px 20px 0 30px;
        }

        & > li {
            padding: 15px 0;
            padding: 10px 15px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            &:nth-child(odd) {
                background-color: #dfd5f333;
            }
        }
    }
}

.pointer {
    cursor: pointer;

    &:hover {
        box-shadow: 0 3px 10px var(--boxShadowLigh);
    }
}
