@import '../../../../styles/media-mixins';

.screenHeader {
    width: 100%;
    &__topBar {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        &__title {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-right: 10px;

            &__text {
                font-size: 20px;
                letter-spacing: 0.3px;
                font-weight: bold;
                color: var(--neutral1000);
                line-height: 22px;
            }
        }
        & > .modal__header__primaryButton {
            margin-top: 5px;
            height: 48px;
            margin-left: auto;
        }
    }

    &__description {
        margin-top: 20px;

        @include min-width($mobileMD) {
            margin-top: 30px;
        }
    }
}
