@import "../../../../../styles/media-mixins";

.topbarStepper {
    width: 100%;
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    min-height: 156px !important;
    padding: 10px;
    border-bottom: none;
    background-color: var(--neutral100);
    z-index: 800;
    gap: 32px 10px;
    border-bottom: 1px solid var(--neutral200);
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex-wrap(wrap);

    @include min-width($mobileMD) {
        padding: 20px;
    }
    @include min-width($desktopMD) {
        min-height: 92px !important;
        display: flex;
        @include justify-content(space-between);
        @include flex-wrap(nowrap);
    }

    .logoCont {
        display: flex;
        align-items: center;
        height: 36px;

        & > img {
            height: 100%;
        }
    }

    .progressBar {
        top: -4px;
        margin: 0 auto;
        width: 100%;
        @include order(3);

        @include min-width($desktopMD) {
            @include order(2);
            max-width: 712px;
        }

        & > div > ul > li > button {
            margin: 5px auto 0;
            min-width: 102px;
            font-size: 12px;
            cursor: auto;

            @include min-width($mobileMD) {
               min-width: 151px;
            }
            @include min-width($mobileXL) {
               min-width: 204px;
            }
            @include min-width($desktopMD) {
                padding: 0 3px;
                min-width: 231px;
                max-width: 237px;
                font-size: 14px;
            }
         }
    }

    .rightSpace {
        min-width: fit-content;
        margin-left: auto;
        gap: 16px;
        @include order(2);
        @include flexbox();       
        @include justify-content(flex-end);
        @include align-items(center);

        @include min-width($desktopMD) {
            @include order(3);
            margin-left: 0;
        }

        &__close {
            cursor: pointer;
            height: 24px;
            &:hover {
                & > svg > path {
                    stroke: var(--alert300);
                }
            }
        }
    }
}

.backLink {
    margin-top: auto;
    padding: 32px 0px 12px;
    width: 100vw;
    background-color: var(--neutral200);
    z-index: 5999;

    & > span {
        @include flexbox;
        @include align-items(center);
        gap: 4px;
        width: fit-content;
        cursor: pointer;
        &:hover {
            color: var(--primary700);
            & > svg > path {
                stroke: var(--primary700);
            }
        }
    } 
}

.createAppInt {
    @include flexbox;
    @include flex-direction(row);
    @include justify-content(center);
    gap: 32px;
    max-width: 1180px;
    flex-wrap: wrap;
    position: relative;
    margin: 0 auto;
    @include min-width(1320px) {
        flex-wrap: nowrap;
        @include justify-content(space-between);
    }
}