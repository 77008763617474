@import '../../../../../../styles/media-mixins';

.credStatus {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
        margin: 0 !important;
        padding: 5px 5px;
        border: none;
        border-radius: 10px;
    }
    & > span {
        margin-left: 10px;
    }
}

.description {
    color: var(--neutral1000);
    font-size: 0.5rem;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 12px;

    overflow-wrap: break-word;
}

.noViewPermissions {
    color: #a1a1a1;
}

.copyCell {
    display: flex !important;
    & > span {
        margin-right: 5px;
    }

    &__button {
        position: relative;
        &:before {
            content: '';
            display: none;
            position: absolute;
            z-index: 9998;
            width: 0;
            height: 0;
        }

        &:after {
            content: 'Copy to Clipboard';
            display: none;
            position: absolute;
            font-weight: bold;
            z-index: 9999;
            width: 114px;
            height: 36px;

            color: #fff;
            font-size: 10px;
            line-height: 36px;
            text-align: center;

            background: rgba(0, 0, 0, 0.72);
            border-radius: 3px;
        }

        &:hover {
            &:before,
            &:after {
                right: 24px;
                top: -20px;
                display: block;
            }
        }

        &:active,
        &:focus {
            outline: none;

            &:after {
                content: 'Copied!';
            }
        }

        & > svg {
            padding: 0 5px;
            height: 15px;
            width: 25px;
            cursor: pointer;
            &:hover {
                & > path {
                    stroke: blue;
                }
            }
        }
    }
}

.credIssuedAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    & > button {
        width: 20px;
        height: 20px;
        padding-top: 5px;
        background-color: #fafafa;
    }
}

.detailLink {
    padding: 15px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.pendingAction {
    width: 127px !important;
    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &__button {
            margin-top: 0 !important;
            margin-right: 15px !important;
            position: relative;
            &:before {
                content: '';
                display: none;
                position: absolute;
                z-index: 9998;
                width: 0;
                height: 0;
            }
            &:after {
                display: none;
                position: absolute;
                z-index: 9999;

                width: 74px;
                height: 36px;
                color: #fff;
                font-size: 12px;

                line-height: 36px;
                text-align: center;

                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &:after {
                    display: block;
                }
            }

            & > div {
                width: 30px;
                height: 30px;
                padding: 8px 9px;
            }
        }
    }
}

.issue {
    &:after {
        right: 24px;
        top: -20px;
        content: 'Issue';
    }
}

.reject {
    &:after {
        top: -35px;
        content: 'Reject';
    }
}

.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 85px;

    & > button {
        margin-left: auto;
        height: 100%;
        width: 24px;
        background-color: transparent;
        &:hover {
            border: none !important;
            box-shadow: none;
            background-color: transparent;
        }
        & > img {
            height: 24px;
            width: 24px;
        }
    }

    & > .backgroundLayerActive {
        display: none;
    }
}

.action__iconContainer {
    height: 16px;
    width: 16px;
    padding: 2px;
    margin-left: 8px;
    background-color: transparent;
    &:last-child {
        margin-top: 0;
    }
    .action__icon {
        height: 10px;
        width: 10px;
    }
}

.bin {
    width: 20px;
    padding-top: 5px;
    margin-top: 5px;
    cursor: pointer;
    height: 24px;
    &:hover {
        & > path {
            fill: none;
            stroke: red;
        }
    }
}

.yellow {
    font-weight: bold;
    color: #f7ce00;
}

.green {
    font-weight: bold;
    color: green;
}

.red {
    font-weight: bold;
    color: #dd3d43;
}

.gaBlue {
    font-weight: bold;
    color: var(--neutral1000);
}
