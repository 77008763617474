@import '../../../../../../styles/media-mixins';

.success {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    height: 100%;
    width: 287px;
    margin: auto;

    & > p {
        text-align: center;
    }
}
