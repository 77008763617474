@import '../../../../../styles/media-mixins';

.successNotification {
    background-color: var(--success100);
    border: 1px solid var(--success300);
    border-radius: 8px;

    &__content {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__texts {
            .title {
                font-weight: bold;
                font-size: 1rem;
            }

            & > p {
                font-size: 14px;
                margin-right: auto;
                @media (min-width: 1230px) {
                    min-width: 606px;
                }
            }
        }
        &__close {
            display: flex;
            flex-direction: row;
            width: 20px;
            height: 20px;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            cursor: pointer;
            margin-left: 10px;
            & > .closeIcon {
                width: 40px;

                &:hover {
                    & > path {
                        fill: var(--success300);
                    }
                }
               
            }
        }

        & > .fullWidth {
            width: 100% !important;
        }
    }
}
