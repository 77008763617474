.backgroundLayerActive {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100vw;
}

.dropdownActionsContainer {
    position: relative;
    padding-top: 5px;
}

.dropdownActionsButton {
    z-index: 99999;
    overflow: hidden;
    width: 30px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;

    & > svg {
        height: 100% !important;
    }

    &:hover {
        transition: background-color 0.2s ease-in-out;

        -webkit-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;

        & > svg {
            & > path {
                fill: #ff7e79;
                stroke-width: 3px;
            }
        }
    }

    &__icon {
        transition: transform 0.3s ease-in-out;

        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        -ms-transition: -ms-transform 0.3s ease-in-out;
    }

    &__iconActive {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;

        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        -ms-transition: -ms-transform 0.3s ease-in-out;
    }
}

.menuOptionsCard {
    position: absolute;
    z-index: 9999999;
    width: 150px;
    right: 0;
    background: transparent;
    padding-bottom: 15px;

    cursor: auto;

    & > ul {
        background-color: white;
        box-shadow: 0 3px 10px var(--boxShadowLigh);
        border-radius: 10px;
        box-shadow: 0 5px 13px #0000002e;;
    }

    &__item {
        display: flex;
        background-color: white;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding: 7px 10px;
        cursor: pointer;

        &:last-child {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &:hover {
            background-color: #f1f1f1;
            transition: background-color 0.1s ease-in-out;

            -webkit-transition: background-color 0.1s ease-in-out;
            -ms-transition: background-color 0.1s ease-in-out;
        }
    }

    &__item__section {
        display: flex;
        background-color: white;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding: 7px 10px;

        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    &__icon {
        width: 20px;
        margin-right: 10px;
    }

    &__red {
        & > p {
            color: #de494f !important;
        }
    }
}

.active {
    display: block;
}

.inActive {
    display: none;
}

.alignLeft {
    width: 85px;
    margin-left: 0;
    margin-right: 50px;
}
