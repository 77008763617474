@import '../../../../styles/media-mixins';

.gaTextAreaContainer.required {
    & > .topTextsContainer > label {
        &::after {
            color: var(--neutral700);
            content: ' *';
        }
    }
}

.gaTextAreaContainer {
    position: relative;

    & > .topTextsContainer {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        margin-bottom: 0.5rem;

        & > label {
            display: inline-block;
            vertical-align: baseline;
            text-align: left;
            @include flex(1 1 0);
        }

        & > .extraDescription {
            width: 100%;
            text-align: right;
            @include flex(0 0 30%);
            margin-left: 15px;

            &.noLabel {
                margin-left: 0;
                @include flex(1 1 0);
            }
        }
    }

    & > textarea {
        background-color: var(--neutral100);
        letter-spacing: 0.16px;
        outline: 2px solid transparent;
        outline-offset: -2px;
        width: 100%;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        color: var(--neutral1000);
        background-color: transparent;
        border: 1px solid var(--neutral400);
        &:hover,
        &:active,
        &:focus {
            border-color: var(--primary700);
        }
    }
}

.gaTextAreaContainer.readOnly,
.gaTextAreaContainer.disabled { 
    & > textarea {
        cursor: not-allowed;
        pointer-events: all !important;
        outline: none !important;
        color: var(--neutral600);
        background-color: var(--neutral300);
        border: 1px solid var(--neutral300);
       
        &::placeholder {
            color: var(--neutral600);
        }
    }
}

.bottomTextsContainer {
    > * {
        text-align: left;
    }
}

.gaTextAreaContainer.error {
    textarea {
        border-color: var(--alert300);
    }
}

.gaTextAreaContainer.success {
    textarea {
        border-color: var(--success300);
    }
}
