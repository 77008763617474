@import '../../../../../styles/media-mixins';

.license {
    border: 1px solid #e3e3e3;
    width: 270px;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 20px 15px;
    position: relative;
    min-height: 550px;

    &__tag {
        display: block;
        position: absolute;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;
        
        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: var(--neutral200);

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }

    &__tag__newLicense {
        background-color: white;
    }

    @include min-width($desktopSM) {
        justify-content: flex-start;
        margin-right: 20px;
    }

    @media (min-width: 1080px) {
        width: 23%;
        min-width: 272px;
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__title {
            font-weight: bold !important;
            width: calc(100% - 70px);
        }
        &__popularTag {
            background-color: var(--secondary600);
            border-radius: 5px;
            padding: 3px 8px;
            width: fit-content;
            margin-left: auto;
            height: 25px;
            & > p {
                color: white;
                font-size: 12px;
            }
        }
    }

    &__description {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        min-height: 54px;
    }

    &__price {
        & > span {
            font-size: 40px;
            font-weight: bold;
        }
    }

    &__customPrice {
        margin-top: 21px;
        font-size: 29px;
        font-weight: bold;
        margin-bottom: 1px;
    }

    &__customPriceLarge {
        margin-bottom: 18px;
    }
    &__save {
        font-size: 12px;
    }

    &__features {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #e3e3e3;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
            min-width: 232px;
        }
        &__title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &__list {
            font-size: 13px;

            .notAvailableNow {
                color: #a1a1a1;
                & > span {
                    color: #a1a1a1;
                    margin: 3px 0;
                }
                .featureNotAvailableNow {
                    margin-left: 16px;

                    & > span {
                        color: #a1a1a1 !important;
                    }
                }
            }

            &__item {
                display: flex;
                flex-direction: row;
                // flex-wrap: wrap;
                margin-bottom: 15px;
                font-size: 13px;
                align-items: center;
                &__content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    margin-left: 9px;

                    & > span {
                        margin: 3px 0;
                    }
                    & > .featureName {
                        font-size: 13px;
                        width: fit-content;
                    }

                }

                & > .featureName {
                    font-size: 13px;
                    width: fit-content;
                }

                & > div > div > .featureName {
                    font-size: 13px;
                    width: fit-content;
                }
            }
        }
    }

    &__button {
       height: 48px;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.currentLicense {
    background-color: transparent;
    max-width: 540px;
    position: relative;
    box-shadow: none;

    @media (min-width: 1200px) {
        justify-content: flex-start;
        margin-right: 15px !important;
    }

    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: #fafafa;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }
       & > button:nth-of-type(2) {
        background-color: transparent !important;
    }
}

 .license__buttonCurrent {
        background-color: transparent !important;
    }