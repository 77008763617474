@import "../../../styles/media-mixins";

.doubleColumn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
        width: 48%;
    }

    & > div:nth-child(3) {
        margin-top: 15px;
    }

    & > div:nth-child(4) {
        margin-top: 15px;
    }
}
.base {
    overflow: hidden;
    min-height: 100vh;
    padding: 20px;
    background-color: var(--neutral200);

    .container {
        width: 100%;
        max-width: 760px;
        margin: 0 auto;
        margin-top: 6rem;
    }

    .mainContainer {
        width: 100%;
        padding: 20px;
        background-color: var(--neutral100);;
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 3rem;
    padding: 10px;

    h3 {
        margin: 20px 0;
        margin-top: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }
    .logoCont {
        height: 2rem;
    }
}

.baseContainer {
    padding: 20px;
}

.gatacaTitle {
    color: var(--ga-color-pr);
    font-family: "Orbitron";
    font-size: 2.1rem;
    font-weight: 500;
    letter-spacing: 2px;
}

.terms {
    margin-top: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    & > input {
        margin: 0 6px 0 0;
        cursor: pointer;
    }

    & > .checkbox__text {
        font-size: 0.75rem;
        letter-spacing: 0.32px;
        display: inline-block;
        margin: 0 5px 0.4rem 0;
        color: #525252;
        font-weight: 400;
        line-height: 1rem;
        vertical-align: baseline;
    }

    & > a {
        font-size: 0.75rem;
        letter-spacing: 0.32px;
        margin: 0.1rem 5px 0.4rem 0;
        text-decoration: none; 
        &:hover {
            text-decoration: underline;
        }
    }
}