@import '../../../../../styles/media-mixins';

.successEndContainer {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    gap: 32px;
    padding-bottom: 30px;
    &__messagePanel {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        @include flex-direction(row);
        max-width: 414px;
        &__icon {
            width: 50px;
            margin-right: 20px;
        }
        &__successMessage {
            width: fit-content;
            font-size: 20px;
            letter-spacing: 0.72px;
            text-align: center;
        }
    }

    &__actionPanel {
        max-width: 414px;
    }
    &__header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
    }
    &__buttons {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        width: 100%;
        @include min-width($mobileMD) {
            @include flex-direction(row);
            @include flex-wrap(wrap);
        }
        & > button {
            @include flexbox();
            @include justify-content(center);
            min-width: 130px;
            margin-top: 22px;
        }
        &__apiKey {
            margin-bottom: 10px;
            @include min-width($mobileMD) {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }
    }
}
