@import '../../../styles/fonts';

.didSelectorContainer {
    width: 300px;
    position: relative;
    
    & > svg {
        position: absolute;
        top: 4px;
        right: 0;
        pointer-events: none;
        overflow-clip-margin: content-box;
        overflow: hidden;
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 20px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid var(--neutral100); /* should match background, can't be transparent */
        border-radius: 8px;
        background-color: var(--neutral300);
    }
}


.selectCustomContainer {
    position: relative;
    display: block;
    & > input {
        display: block;
        outline: 2px solid transparent;
        outline-offset: -2px;
        padding-left: 0;
        height: 2.5rem;
        color: var(--neutral1000);
        background-color: transparent;
        border: none;
        text-decoration: none;
        width: 300px;
        cursor: default;
        &::placeholder {
            color: var(--neutral1000);
        }
    }
}

.inputSkeleton {
    border-radius: 8px;
    background-color: var(--neutral300);
    overflow: hidden;
}
