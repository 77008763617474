@import "../../../../../styles/media-mixins";

.backgroundLayer {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    @media (min-width: 775px) {
        left: 250px;
        max-width: calc(100vw - 250px);
    }
}

.darkBackgroundLayer {
    position: fixed;
    height: 100% !important;
    max-width: none !important;
    z-index: 99999;
    top: 0;
    left: 0 !important;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 0;
    max-width: 1080px;
    padding: 30px;
    border-radius: 20px;
    background: white;
    min-width: 398px;
    transform: translate(calc(44vw - 150px), -50%);
    width: 340px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 13px #0000002e;;
    @media (min-width: 775px) {
        left: 45%;
        transform: translate(-0%, -50%);
    }
    @include min-width($tabletSM) {
        width: 400px;
    }

    & > img {
        width: 120px;
        min-height: 120px;
        margin-bottom: 32px;
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
    }

    &__description {
        text-align: center;
        margin-bottom: 32px;
        font-size: 14px;
    }

    & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        & > button {
            display: flex;
            justify-content: center;
            width: calc(50% - 6px);
            cursor: pointer;
        }
    }

    &__delete {
        background-color: #DC3D43;
        border: none;
        color: white !important;
        &:hover {
            background-color: #DC3D43;
            border: none;
            color: white !important;
        }
    }

    &__close {
        z-index: 9999;
        z-index: 9999;
        position: absolute;
        right: 0;
        padding: 20px;
        cursor: pointer;

        &:hover {
            & > svg > g > path {
                stroke: red;
            }
        }
    }
}