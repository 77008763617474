@import '../../../../../styles/media-mixins';

.detailContainer {
    li {
        list-style: none;
        margin-top: 20px;
        max-height: 60px;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 11px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 20px;
        }

        &::-webkit-scrollbar-thumb {
            border: 2px solid white; /* should match background, can't be transparent */
            border-radius: 8px;
            background-color: var(--neutral300);
        }
        & > div {
            background-color: #eaeaea;
            color: var(--neutral1000);
            min-height: 50px;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            flex-wrap: wrap;

            & > button {
                position: relative;
                border: none;
                background-color: transparent;
                padding: 0;
                font-size: 24px;
                width: 30px;
                text-align: right;
                color: grey;
                cursor: pointer;

                &:disabled {
                    cursor: default;
                }

                &:hover {
                    color: var(--neutral1000);
                }

                & > p {
                    font-size: 12px;
                    position: absolute;
                    right: 25px;
                    margin-top: 8px;
                    color: grey;
                    width: 90px;
                }
                & > img {
                    margin-top: 5px;
                }
            }
            & > p {
                width: calc(100% - 30px);
            }
            & > div {
                width: 100%;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                & > span {
                    color: grey;
                }
            }
        }

        & > ul {
            margin-left: 20px;
            & > div > span {
                height: 50px;
                background-color: #eaeaea;
                margin-top: 20px;
                display: flex;
                align-items: center;
                padding: 10px 20px;
                color: grey;
            }
        }
    }

    h5 {
        padding-top: 20px;
    }
}

.active {
    border: 1px solid red;
}

.showed {
    display: none;
}

.multiCredArea {
    height: calc(100% - 30px);
    @include max-width($tabletSM) {
        height: calc(100% - 50px);
    }

    & > div {
        height: 100%;
        & > label {
            position: fixed;
            z-index: 999999;
            background-color: white;
            padding: 10px 15px 10px 0px;
            width: calc(100% - 58px);
            display: flex;
            align-items: center;
        }

        & > div:nth-child(2) {
            height: 100%;

            & > textarea {
                height: 100%;
            }
        }
    }
}

.infoPanelLabel {
    & > .link {
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }
}
.credentialSchemeLabel {
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.75rem;
    margin-top: 20px;
    &__link {
        &:hover {
            text-decoration: underline;
        }
    }
}
