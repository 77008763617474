@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.backgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.modal {
    overflow: hidden;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 460px;
    height: fit-content;
    max-height: 90%;
    padding: 35px;
    border-radius: 20px;
    background: white;

    transform: translate(-50%, -50%);

    &__header {
        &__icon {
            margin-top: 25px;
            & > img {
                margin: auto;
                display: block;
                height: 40px;
                width: 40px;
            }
        }
        &__title {
            margin-top: 25px;
            width: 100%;
            text-align: center;

            margin-bottom: 25px;
            & > h3 {
                font-size: 23px;
                color: var(--neutral1000);
                font-weight: bold;
                @include min-width($mobileXL) {
                    font-size: 28px;
                }
            }
        }
        &__close {
            margin-left: auto;
            cursor: pointer;
            width: fit-content;
            padding: 5px 0 5px
                5px;
            &:hover {
                & > svg > path {
                    fill: red;
                }
            }
        }
    }

    &__container {
        overflow: scroll;

        max-height: calc(100% - 116px);
        max-width: 100%;

        display: block;

        &__text {
            text-align: center;
        }

        &__button {
            margin: auto;
            margin-top: 25px;
            width: 100%;
            height: 36px;
            font-weight: bold;
            justify-content: center;
        }
    }
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}
