@import '../../../../styles/media-mixins';

.requestCredentials {
    @include flexbox();
    @include flex-direction(column);
    gap: 32px;
    padding-top: 10px;
    &__header {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        @include justify-content(space-between);
        gap: 12px;
    }
    & > .searchBar {
        height: auto;
        & > div {
            margin: 0;
        }
    }
    & > .credentialsContainer {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(flex-start);
        gap: 32px;
    }
}

.searchBarSkeleton {
    height: 40px;
    margin: 0;
}

.credSkeleton {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include align-items(flex-start);
    gap: 24px;
    min-width: 330px;
    padding: 12px;
    height: 174px;
    border-radius: 8px;
    margin: 0;

    @include min-width(1002px) {
        @include flex(0 0 calc((100% / 2) - (32px / 2)));
    }

    @include min-width(1364px) {
        @include flex(0 0 calc((100% / 3) - (64px / 3)));
    }

    &:before {
        top: 0;
    }
}

.paginationContainer {
    width: 100%;
}
