@import "../../../../../styles/media-mixins";

.sectionDescription {
    width: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--neutral700);

    @include min-width($mobileMD) {
        margin-bottom: 0;
    }
}

.sectionContent {
    max-width: 900px;
    margin-top: 0;

    @include min-width($mobileMD) {
        padding: 20px 0;
    }

    @media (min-width: 800px) {
        padding: 20px 20px;
    }
}

.editDid {
    @include min-width($desktopMD) {
        width: 700px;
    }

    &__circleBar {
        margin: 0 0 20px;

        @include min-width($mobileMD) {
            display: none;
        }
    }

    &__lineBar {
        display: none;

        @include min-width($mobileMD) {
            display: block;
            margin: 32px 0 32px;
        }
    }
}
