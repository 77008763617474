@import '../../../../../../styles/media-mixins';

.certificateStatus {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      margin: 0 !important;
      padding: 5px 5px;
      border: none;
      border-radius: 10px;
    }
    & > span {
      margin-left: 10px;
    }
}

.certificateAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    & > button {
      width: 20px;
      height: 20px;
      padding-top: 5px;
      background-color: #fafafa;
    }
}

.detailLink {
    padding: 15px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 85px;

    & > button {
        margin-left: auto;
        height: 100%;
        width: 24px;
        background-color: transparent;
        &:hover {
            border: none !important;
            box-shadow: none;
            background-color: transparent;
        }
        & > img {
            height: 24px;
            width: 24px;
        }
    }

    & > .backgroundLayerActive {
        display: none;
    }
}

.action__iconContainer {
  height: 16px;
  width: 16px;
  padding: 2px;
  margin-left: 8px;
  background-color: transparent;
  &:last-child {
    margin-top: 0;
  }
  .action__icon {
    height: 10px;
    width: 10px;
  }
}

.bin {
  width: 20px;
  margin-top: 8px;
  cursor: pointer;
  position: absolute;
  &:hover {
    & > path {
      fill: none;
      stroke: red;
    }
  }
}


.yellow {
    font-weight: bold;
    color: #f7ce00;
}

.green {
    font-weight: bold;
    color: green;
}

.red {
    font-weight: bold;
    color: #dd3d43;
}

.gaBlue {
    font-weight: bold;
    color: var(--neutral1000);
}
