@import "../../../../../styles/media-mixins";

.infoPanel {
    @include flexbox();
    @include align-items(center);
     @include flex-direction(row);
    gap: 12px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--info100);
    color: var(--neutral1000);
    max-width: 1180px;
    border: 1px solid var(--info200);

    & > svg {
        width: 100%;
        max-width: 24px;
        height: 24px;
    }

    & > img {
        width: 24px;
    }
}
