@import '../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    & > .modal {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 95vw;
        max-width: 480px;
        height: 95vh;
        max-height: 737px;
        border-radius: 20px;
        background: var(--neutral100);
        transform: translate(-50%, -50%);
        overflow: hidden;
        padding: 20px;
        gap: 24px;
        @include flexbox();
        @include flex-direction(column);

        @include min-width($tabletSM) {
            width: 80vw;
        }
    }

    .modal__header {
        @include flexbox();
        @include align-items(flex-start);
        @include flex-direction(column);
        gap: 24px;
        width: 100%;
        background-color: var(--neutral100);

        &__top {
            @include flexbox();
            @include align-items(flex-start);
            @include justify-content(space-between);
            gap: 24px;
            width: 100%;
            &__close {
                cursor: pointer;
                &:hover {
                    & > svg > path {
                        stroke: var(--alert300);
                    }
                }
            }
        }
    }

    .modal__scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
        vertical-align: initial;
        padding-right: 2px;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        @include flexbox;
        @include flex-direction(column);
    }

    .modal__alert {
        margin: 0;
        padding: 12px;
    } 

    .modal__warningAlert {
        @include flex-direction(row);
        @include align-items(flex-start);
        gap: 12px;
        border: 1px solid var(--warning200);
        background: var(--warning100);
        & > div {
            margin: 0;
            & > img {
                margin-right: 0;
                width: 24px;
                height: 24px;
            }
            & > p {
                margin-top: 0;
                font-family: var(--base);
                font-size: 14px;
                font-style: normal;
                line-height: 16px;
            }
        }
    }

    .modal__errorAlert {
        @include flex-direction(row);
        @include align-items(flex-start);
        gap: 12px;
        border: 1px solid var(--alert200);
        background: var(--alert100);
        & > div {
            margin: 0;
            & > img {
                margin-right: 0;
                width: 24px;
                height: 24px;
            }
            & > p {
                margin-top: 0;
                font-family: var(--base);
                font-size: 14px;
                font-style: normal;
                line-height: 16px;
            }
        }
    }

    .modal__footer {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include justify-content(space-between);
        gap: 12px 20px;
        width: 100%;
        background-color: var(--neutral100);

        @include min-width($mobileMD) {
            @include flex-direction(row);
        }

        & > button {
            @include flex(1 0 0);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            padding: 12px 24px;
            height: 44px;
            width: 100%;

            @include min-width($mobileMD) {
                @include flex-direction(row);
                width: auto;
            }
        }
    }
}

.visibleModal {
    display: block;
}
