.detailContainer {
    width: 100%;
    & > p {
        color: var(--neutral700);
        margin-bottom: 0.5rem;  
    }
}

.active {
    border: 1px solid red;
}

.showed {
    display: none;
}
