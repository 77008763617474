@import "../../../../../../styles/media-mixins";

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.209);

    & > .modal {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 343px;
        border-radius: 12px;
        background: var(--neutral100);
        transform: translate(-50%, -50%);
        overflow: hidden;

    }

    .modal__header {
        width: 100%;
        padding: 8px 16px 8px 6px;
        background-color: var(--neutral100);
        height: 56px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-end);

        &__close {
            cursor: pointer;
            height: 24px;
            &:hover {
                & > svg > path {
                    stroke: var(--alert300);
                }
            }
        }
    }

    .modal__content {
        padding: 0 16px;
         & > img {
            width: 311px;
            height: auto;
            border-radius: 12px;
         }
    }

    .modal__footer {
        margin-top: auto;
        padding: 16px;
        @include flexbox();
        @include align-items(center);
        width: 100%;
        background-color: var(--neutral100);

        & > button {
            @include flex(1 0 0);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            padding: 12px 24px;
            height: 48px;
        }
    }
}

.visibleModal {
    display: block;
}
