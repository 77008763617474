@import '../../../../../../styles/media-mixins';

.errorFilledPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 20px;
    margin-top: -25px;
    margin-bottom: 30px;
    margin-left: -30px;
    background-color: #fde5e5;
    border: 1px solid var(--alert300);
    border-radius: 8px;
    max-width: 1180px !important;

    &__header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        & > .title {
            margin-bottom: 5px;
        }
        & > img {
            width: 20px;
            margin-right: 10px;
        }

    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media (min-width: 1230px) {
            flex-direction: row;
            align-items: center;
            align-items: center;
            justify-content: space-between;
        }

        &__texts {

            &  > p {
                line-height: 1.5;
                margin-right: auto;
                @media (min-width: 1230px) {
                    min-width: 606px;
                }
            }
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            width: fit-content;
            margin-left: auto;
            & > .button {
                border: 2px solid #dc3d43;
                min-width: 140px;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                text-align: center;
                width: min-content;
                @include min-width($desktopMD) {
                    margin-top: 0;
                }
            }

            & > .button:nth-child(1) {
                background-color: #dc3d43;
                margin-left: 5px;
                color: #fde5e5;
                &:hover {
                    background-color: #fde5e5;
                    color: #dc3d43;
                }
            }

            & > .button:nth-child(2) {
                background-color: #fde5e5;
                color: #dc3d43;
                margin-left: 15px;
                &:hover {
                    color: #dc3d43 !important;
                }
            }
        }

        &__close {
            margin-left: 16px;
            cursor: pointer;
            & > svg {
                width: 16px;
                height: 16px;
            }
             &:hover {
                & > svg > path {
                    fill: red;
                }
            }
        }

        & > .fullWidth {
            width: 100% !important;
        }
    }
}
