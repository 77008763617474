@import '../../../../../../styles/media-mixins';

.skeleton {
    height: calc(500px - 48px);
    margin: 0;
    @include min-width($desktopXXL) {
        height: 495px;
    }
}
.activeUsersStatistics {
    background: var(--neutral100);
    padding: 26.25px;
    display: block;
    border-radius: 12px;
    box-shadow: 0 3px 10px var(--boxShadowLigh);
    max-width: 100%;
    @include flex(0 0 100%);
    @include min-width(1490px) {
        @include flex(0 0 calc(50% - 16px));
        max-width: calc(50% - 16px);
    }
    &__header {
        width: 100%;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        margin-bottom: 19.5px;
        gap: 20px;
        @include min-width($mobileXL) {
              @include flex-wrap(nowrap);
        }
        .datesFilter span{
            color: var(--neutral700);
        }
    }

    .filtersRightContent {
        margin-left: auto;
    }

    .chartContainer {
        display: block;
        &>div {
            display: block;
        }
        a {
            display: none;
        }
    }
}
