@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    & > .modal {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 95vw;
        max-width: 480px;
        height: 95vh;
        max-height: 667px;
        border-radius: 20px;
        background: var(--neutral100);
        transform: translate(-50%, -50%);
        overflow: hidden;
        padding: 20px;

        @include min-width($tabletSM) {
            width: 480px;
            max-width: 95vh;
            height: 737px;
            max-height: 95vh;
        }
    }

    .modal__header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
        height: 32px;
        gap: 12px;
        background-color: var(--neutral100);

        & > div:nth-of-type(1) {
            cursor: pointer;
               &:hover {
                & > svg > path {
                    stroke: var(--primary700);
                }
            }
        }
        &__close {
            margin-left: auto;
            cursor: pointer;

            & > button {
                line-height: 15px;
                border: 1px solid var(--neutral500);
                &:hover {
                    border: 1px solid var(--primary700);
                }
            }
        }
    }

    .modal__content {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 188px);

        @include min-width($mobileMD) {
            height: calc(100% - 188px);
        }

       & > div {
            gap: 20px;
            margin-top: 20px;
            @include flexbox();
            @include align-items(center);
            @include flex-wrap(wrap);
            @include flex-direction(column);
            @include flex(1);

            @include min-width($mobileXL) {
                gap: 32px;
                margin-top: 32px;
            }

            & > .image {
                width: 100%;
                @include flexbox();
                @include align-items(center);
                @include justify-content(center);
                @include flex(1);

                & > img {
                    width: 100%;
                    max-width: 218px;
                    margin: auto;
                    @include min-width($mobileXL) {
                        max-width: 318px;
                    }
                }     
            }

            & > p {
                width: 100%;
                text-align: center;
            }

            & > div > p {
                width: 100%;
                text-align: center;
            }

            & > div > .stores {
                margin-top: 20px;
                @include flexbox();
                @include justify-content(center);
                @include flex-wrap(wrap);
                gap: 16px;

                & > a {
                    height: 50px;

                    & > img {
                        height: 100%;
                    }
                }
            } 
       }
    }

    .stepper {
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        gap: 8px;
        margin: 20px 0;
        @include min-width($mobileXL) {
            margin: 32px 0;
        }
    }

    .modal__footer {
        gap: 18px;
        width: 100%;
        background-color: var(--neutral100);
    
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include justify-content(space-between);

        @include min-width($mobileMD) {
            @include flex-direction(row);
        }

        & > button {
            padding: 12px 24px;
            height: 44px;
            width: 100%;
            @include flex(1 0 0);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);

            @include min-width($mobileMD) {
                @include flex-direction(row);
                width: auto;
            }
        }
    }
}

.visibleModal {
    display: block;
}

.notShowAgain {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    gap: 8px;
    width: 100%;

    & > input {
        margin: 0;
        accent-color: var(--primary700);
        width: 24px;
        cursor: pointer;
        &:checked {
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            border: 2px solid var(--primary700);
            transition: background-color 0.3s ease-in-out;
            -webkit-transition: background-color 0.3s ease-in-out;
            -ms-transition: background-color 0.3s ease-in-out;
            background-color: var(--primary700);
            position: relative;
        
        }
        &:not(:checked) {
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            background-color: var(--neutral100);
            transition: background-color 0.3s ease-in-out;
            -webkit-transition: background-color 0.3s ease-in-out;
            -ms-transition: background-color 0.3s ease-in-out;
        }
        border-radius: 2.25px;
        &[type="checkbox"] {
            width: calc(24px - 3px);
            height: calc(24px - 3px);
            &:not(:checked) {
                border: 2px solid var(--neutral400);
                &:hover {
                    border-color: var(--primary700);
                }
            }   
        }
        &:checked {
            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                width: 6px;
                height: 1.5px;
                background-color: var(--neutral100);
                left: 3px;
                top: 10px;
                -ms-transform: rotate(45deg);
                /* IE 9 */
                -webkit-transform: rotate(45deg);
                /* Chrome, Safari, Opera */
                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 10px;
                height: 1.5px;
                background-color: var(--neutral100);
                left: 5px;
                top: 8px;
                -ms-transform: rotate(-55deg);
                /* IE 9 */
                -webkit-transform: rotate(-55deg);
                /* Chrome, Safari, Opera */
                transform: rotate(-55deg);
            }
        }
    }
    & > p {
        line-height: 10px;
    }
}
