@import '../../../../../../styles/media-mixins';

.appIntSuccess {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    gap: 32px;
    padding-bottom: 30px;
    width: 100%;
    max-width: 707px;
    margin: 0 auto;
    margin-top: 32px;

    &__messagePanel {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(row);
        @include justify-content(flex-start);
        padding: 32px;
        gap: 20px;

        &__icon {
            width: 64px;
        }
    }

    &__data {
        @include flexbox();
        @include flex-direction(column);
        gap: 24px;
        padding: 24px;

        & > div > p > strong {
            color: var(--primary700);
            font-weight: 700;
        }
        & > button {
            min-height: 44px;
            height: auto;
            min-width: 100%;
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
        }
    }
}
