@import '../../../../../../styles/media-mixins';

.closeConfirmationModal {
    & > div > div:nth-of-type(2) > div > button:nth-of-type(2) {
        background-color: var(--alert300);
        border: var(--alert300);

        &:hover {
            background-color: var(--alert400);
            border: var(--alert400);
        }
    }
}

.closeConfirmation {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    height: 100%;
    padding: 24px;
    margin: auto;

    & > p {
        text-align: center;
    }
}
