@import "../../../../../styles/media-mixins";

.searchBar {
    height: auto;
}

.searchBarSkeleton {
    height: 40px;
    margin: 32px 0;
}

.confirmationModal {
    & > div {
        @include min-width($tabletSM) {
            max-height: 496px !important;
        }
    }
}