@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 95vw;
    max-width: 640px;
    height: 95vh;
    max-height: 700px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);
    overflow: hidden;

    @include min-width($tabletSM) {
        width: 80vw;
        height: 80vh;
    }
}


.modal__header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    min-height: 64px;
    padding: 8px 4px 8px 6px;
    background-color: var(--neutral100);
    border-bottom: 1px solid var(--neutral300);

    & > p {
        margin: auto;
    }
    & > div:first-child {
        width: 90%;
    }

    &__close {
        cursor: pointer;
        margin-right: 12px;
        &:hover {
            & > svg > path {
                stroke: var(--neutral300);
            }
        }
    }
}

.modal__content {
    position: absolute;
    padding: 32px 16px 0;
    top: 65px;
    left: 0;
    right: 0;
    bottom: 80px;
    overflow-y: auto;
    width: 100%;
}

.searchBar {
    margin: 0 0 24px;

    & > div:nth-of-type(1) {
        background-color: var(--neutral200);
    }
}

.modal__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    width: 100%;
    min-height: 80px;
    padding: 16px;
    background-color: var(--neutral100);
    border-top: 1px solid var(--neutral300);

    &__primaryButton {
        padding: 12px 24px;
        height: 48px;
    }
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}
