
@import "../../../../../../../styles/media-mixins";

.previewImageContainer {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include flex(1);
    width: 100%;

    .previewImage {
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        max-width: 230px; // width + body border
        &__header {
            @include flexbox();
            @include align-items(center);
            background-color: var(--neutral300);
            width: 100%;
            gap: 9px;
            padding: 8px 12px;

            & > span {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                &:nth-of-type(1) {
                    background-color: #EE6A5F;
                }
                &:nth-of-type(2) {
                    background-color: #F5BD4F;
                }
                 &:nth-of-type(3) {
                    background-color: #63C655;
                }
            }
        }

        &__body {
            @include flexbox();
            @include justify-content(space-between);
            @include flex-direction(column);
            height: 447px;
            overflow-y: scroll;
            background-color: var(--neutral200);
            border: 14.108px solid var(--neutral300);
            border-radius: 28.216px;
            overflow-x: hidden;

            & > div {
                @include flexbox();
                @include flex-direction(column);
                @include justify-content(space-between);
            }

            &__top {
                padding-bottom: 4.708px;
                width: 100%;
                background-color: var(--neutral200);

                & > img {
                    width: 100%;
                }

                &__getVerified {
                    @include flexbox();
                    @include align-items(center);
                    gap: 5.3px;
                    padding: 7.063px 9.417px;
                    p {
                        font-family: "Poppins";
                        font-size: 10.594px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14.125px;
                        & > strong {
                            font-family: "Ubuntu";
                            font-size: 11.771px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 14.125px;
                        }
                    }
                }
            }

            &__bottom {
                gap: 4.358px;
                width: 100%;
                -webkit-box: 1;
                -moz-box: 1;
                flex: 1 0 auto;
                border-top-left-radius: 12.344px;
                border-top-right-radius: 12.344px;
                border-bottom: 0.874px solid var(--neutral300);
                box-shadow: 0px -4.964px 7.942px 0px rgba(47, 45, 139, 0.08);
                background-color: var(--neutral100);
                margin: 0;

                &__content {
                    @include flexbox();
                    @include flex-direction(column);
                    @include flex(1 0 0);
                    padding: 14.125px 9.417px 7.063px 9.417px;
                    gap: 18.834px;
            
                &__companyLogo {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(center);
                    padding: 2px 4px;
                    border-radius: 2px;
                    border: 0.5px solid var(--neutral500);
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 6px;
                    width: fit-content;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 7px;
                }

                &__companyLogoImg {
                    max-height: 18px;
                    max-width: 100%;
                    height: 18px;
                    width: fit-content;

                    & > img {
                        height: 100%;
                    }
                }

                &__scan {
                    & > p:nth-of-type(1) {
                        font-family: "Ubuntu";
                        font-size: 16.831px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 19.538px;
                    }
                    & > p:nth-of-type(2) {
                        margin-top:  4.71px;
                        font-family: "Poppins";
                        font-size: 10.592px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14.123px;
                        color: var(--neutral700);
                        & > strong {
                            color: var(--primary700);
                        }
                    }

                     & > button {
                        margin-top: 11.77px;
                        width: 100%;
                        pointer-events: none;
                        font-family: "Poppins";
                        padding: 7.061px 14.123px;
                        height: 28.246px;
                        font-size: 11.769px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14.123px;
                        @include flexbox();
                        @include justify-content(center);
                         cursor: auto;
                    }
                }

                &__qr {
                    @include flexbox();
                    @include flex-direction(column);
                    gap: 19.86px;
                   
                    &__shared {
                        @include flexbox();
                        @include flex-direction(column);
                        gap: 18.83px;
                        @include flex(1 0 0);
                        height: fit-content;
                        border-radius: 7.063px;
                        padding: 14.13px;
                        // max-height: 133px;
                        // overflow-y: scroll;
                        background-color: var(--primary100);
                        &__header {
                            & > p:nth-of-type(1) {
                                font-family: "Poppins";
                                font-size: 8.24px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 9.417px;
                                margin-bottom: 2.35px;
                            }

                            & > p:nth-of-type(2) {
                                font-family: "Ubuntu";
                                font-size: 11.771px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 14.125px;
                                & > strong {
                                    color: var(--primary700);
                                }
                            }
                        }

                          &__scope {
                                @include flexbox();
                                @include flex-direction(column);
                                gap: 9.42px;
                                &__item {
                                    @include flexbox();
                                    gap: 4.71px;

                                    & > div:nth-of-type(1) {
                                        width: 14px;
                                        height: 14px;
                                        @include flexbox();
                                        @include justify-content(flex-start);
                                        @include align-items(flex-start);
                                    }

                                    & > div:nth-of-type(2) {
                                        @include flexbox();
                                        @include flex-direction(column);

                                        & > p:nth-of-type(1) {
                                            font-family: "Poppins";
                                            font-size: 9.417px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 11.771px;
                                        }
                                        & > p:nth-of-type(2) {
                                            font-family: "Poppins";
                                            font-size: 8.24px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 9.417px
                                        }
                                    }
                                }

                            }
                            &__policiesTerms {
                                & > p:nth-of-type(1) {
                                    font-family: "Poppins";
                                    font-size: 6.95px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 7.942px;
                                    > strong {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }

                 &__footer {
                    @include flexbox();
                    @include align-items(center);
                    @include justify-content(space-between);
                    @include flex-direction(column);
                    gap: 9.93px;
                    background-color: var(--neutral100);
                    &__firstRow {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(space-between);
                        width: 100%;
                        padding: 0 9.417px;
                        &__language {
                            @include flexbox();
                            @include align-items(center);
                            font-family: "Poppins";
                            font-size: 8.24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 9.417px; 
                            gap: 1.99px;
                            color: var(--neutral700);
                        }
                        &__tutorial {
                            font-family: "Poppins";
                            font-size: 6.95px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 7.942px;
                        }
                    }
                    &__terms {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        gap: 9.93px;
                        padding: 0 9.417px;
                        text-align: center;
                        & > p {
                            font-family: "Poppins";
                            font-size: 6.95px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 7.942px;
                            text-transform: capitalize;
                        }
                    }
                    &__browser {
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        width: 100%;
                        padding: 4.12px 5.885px 2.943px 5.885px;
                        gap: 5.885px;
                        border-top: 0.294px solid var(--neutral400);

                        & > div {
                            @include flexbox();
                            @include align-items(center);
                            @include justify-content(space-between);
                            width: 100%;
                            height: 25.896px;
                            padding: 6.474px 7.063px;
                            gap: 10px;
                            border-radius: 5.885px;
                            box-shadow: 0px 0px 4.708px 0px rgba(0, 0, 0, 0.12);
                            & > p {
                                font-family: "Roboto";
                                font-size: 8.5px;
                                font-style: normal;
                                font-weight: 400;
                            }

                            & > img {
                                height: 13px;
                            }
                        }
                    }

                    & > img {
                        width: 100%;
                    }

                    & > img:nth-of-type(1) {
                        padding: 3.531px 9.42px 2.943px 9.42px;
                    }
                }
            }
        }
    }
}
