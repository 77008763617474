@import '../../../../styles/media-mixins';

:root {
    --tooltip-margin: 30px;
    --tooltip-arrow-size: 8px;
}
.gaTooltip__container {
    display: inline-block;
    position: relative;
    .iconTextContainer {
        @include flexbox;
        @include align-items(center);
        gap: 4px;
        .iconTooltipContainer {
            position: relative;
            max-height: 16px;
        }
    }
    .textTooltipContainer {
        position: relative;
    }
    .gaTooltip__info {
        position: absolute;
        background-color: var(--neutral1000);
        padding: 8px;
        border-radius: 4px;
        left: 50%;
        z-index: 9999;
        transform: translateX(-50%);
        word-wrap: break-word;
        max-width: 180px;
        width: max-content;
        &::before {
            content: '';
            left: 50%;
            width: 0;
            height: 0;
            position: absolute;
            border: solid transparent;
            border-width: var(--tooltip-arrow-size);
            pointer-events: none;
            margin-left: calc(var(--tooltip-arrow-size) * -1);

        }
    }
}
.gaTooltip__info {
    &.noArrow {
        &::before {
            content: none;
        }
    }
}
.gaTooltip__info {
    &.positionTop {
        // We can have a max width as the rest because we 
        // don't know the height and it's difficult to position it 
        // without a fixed height
        word-wrap: normal;
        max-width: max-content;
        width: auto;
        white-space: nowrap;
        
        top:  -40px;
        &::before {
            top: 100%;
            border-top-color: var(--neutral1000);

        }
        &.directionRight {
            transform: translateX(-80%);
            &::before {
                right: 30px;
                left: auto;
            }
        }

        &.directionLeft {
            transform: translateX(-15%);
            &::before {
                left: 30px;
            }
        }
    }
}
.gaTooltip__info {
    &.positionRight {
        left: 30px;
        top: 50%;
        transform: translateX(0) translateY(-50%);
        &::before {
            left: calc(var(--tooltip-arrow-size) * -1);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-right-color: var(--neutral1000);
        }
    }
}

.gaTooltip__info {
    &.positionBottom {
        bottom: auto;
        margin-top: 10px;
        &::before {
            bottom: 100%;
            border-bottom-color: var(--neutral1000);
        }
        &.directionRight {
            transform: translateX(-80%);
            &::before {
                right: 30px;
                left: auto;
            }
        }

        &.directionLeft {
            transform: translateX(-15%);
            &::before {
                left: 30px;
            }
        }
    }
}

.gaTooltip__info {
    &.positionLeft {
        left: auto;
        right: 30px;
        top: 50%;
        transform: translateX(0) translateY(-50%);
        &::before {
            left: auto;
            right: calc(var(--tooltip-arrow-size) * -2);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            border-left-color: var(--neutral1000);
        }
    }
}

.textTooltipContainer {
    .gaTooltip__info {
        &.positionLeft {
            right: calc(85% + var(--tooltip-margin));
        }
    }
}
.textTooltipContainer {
    .gaTooltip__info {
        &.positionRight {
            left: calc(95% + var(--tooltip-margin));
        }
    }
}

.removeScrollBar {
    overflow-y: unset !important;
}

.upgradeIcon {
   margin-top: 2px;
   transform: rotate(135deg);
}
