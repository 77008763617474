@import '../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.testModal__qr {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    padding: 0 20px;
    border-radius: 20px;
    background: transparent;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 302px;
    height: fit-content;
    max-height: 500px;
    max-width: 600px;

    &__close {
        z-index: 9999;
        z-index: 9999;
        position: absolute;
        right: 0;
        top: 24px;
        padding: 0 20px;
        cursor: pointer;

        &:hover {
            & > svg > path {
                stroke: red;
            }
        }
    }
}

.testModalStepInfo {
    margin-top: 5px;
    font-size: 14px;
    color: var(--neutral1000);
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 18px;
    overflow-wrap: break-word;
}

.testModalSanboxQR {
    width: fit-content;
    margin: auto;
    margin: 15px auto 20px;
}

.showSandBoxQRButton {
    height: 30px;
    margin-top: 15px;
    font-weight: bold;
}
.testModalSanboxSkeleton {
    width: 250px !important;
    height: 250px !important;
    &::before {
        height: 250px !important;
    }
}

.errorFilledPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px;
    margin-bottom: 30px;
    background-color: #FFDEDF;
    color: var(--neutral1000);
    border: 1px solid #EE888C;
    border-radius: 12px;

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        & > img {
            width: 20px;
            margin-right: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        width: 100%;
        @media (min-width: 1230px) {
            flex-direction: row;
            align-items: center;
            align-items: center;
            justify-content: space-between;
        }

        & > img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }
        &__texts {
            .title {
                color: #1E1E20;
                font-weight: bold;
                margin-bottom: 5px;
            }

            & > p {
                line-height: 1.5;
                color: #1E1E20;
                margin-right: auto;

                & > a {
                    color: #DC3D43;
                }
            }
        }
    }
}