@import '../../../../../../styles/media-mixins';

.changeCredModal {
    height: 95vh !important;
    max-height: 737px !important;

    & > div:nth-of-type(2) {
        padding: 20px 16px;
    }
    & > div:nth-of-type(3) {
        padding: 16px;
    }
}

.selectableCredList {
    @include flexbox();
    @include flex-direction(column);
    gap: 20px;
}

.credContainer {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    gap: 12px;
}

.credDataContainer {
    @include flexbox();
    @include flex-direction(column);
    gap: 2px;

    & > .value > .photoContainer {
        @include flexbox();
        @include align-items(center);
        gap: 8px;

        & > .imagePreview {
            width: 32px;
            height: auto;
            border-radius: 6.4px;
        }

        & > p {
            cursor: pointer
        }
    }
}
