@import '../../../../styles/media-mixins';

.topbar {
    width: 100%;
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    height: 50px;
    padding: 10px;
    border-bottom: none;
    background-color: var(--neutral100);
    z-index: 800;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);

    @media (min-width: 775px) {
        height: 76px !important;
        display: flex;
        padding: 20px;
        @include justify-content(space-between);
    }

    .logoCont {
        display: flex;
        align-items: center;
        height: 36px;

        & > img {
            height: 100%;
        }
    }

    .supportButton {
        display: flex;
        align-items: center;
        height: 30px;
        margin: 0 40px;
        padding: 7px 14px;
        font-size: 0.8rem;
        letter-spacing: 0.32px;
        line-height: 16px;

        &:hover {
            font-weight: bold;
        }
    }

    .user {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        margin-right: 12px;
        color: var(--neutral1000);

        &__info {
            & > p {
                font-size: 0.8rem;
                text-align: right;

                &:nth-child(2) {
                    opacity: 0.6;
                    font-size: 0.7rem;
                }
            }
        }

        &__image {
            width: 35px;
            height: 35px;
            margin-top: 2px;
            border-radius: 100%;
            background-color: #ffffff;
        }
    }

    .helpImage {
        width: 40px;
        height: 40px;
    }

    .rightSpace {
        min-width: fit-content;
        margin-left: auto;
        position: absolute;
        right: 10px;

        @media (min-width: 775px) {
            position: relative;
            right: auto;
            @include flexbox();       
            @include justify-content(flex-end);
            @include align-items(center);
            gap: 16px;
        }

        &__support {
            display: none;
          
            @media (min-width: 775px) {
                margin-right: 10px;
                display: block;
            }
        }

        & > .user__image {
            display: none;
            
            @media (min-width: 775px) {
                display: block;
            }
        }

        &>button {
            display: none;

            @media (min-width: 775px) {
                display: block;
            }
        }

        .rightMenuIcon {
            width: 15px;
            margin-top: 10px;
        }

        & > .notifications {
            cursor: pointer;
            position: relative;
        }
    }
}

.panelContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: content-box;
    padding: 75px 20px 20px 20px;
    background-color: var(--neutral200);
    padding-bottom: 98px;
    display: flex;
    flex-direction: column;

    @include min-width($mobileMD) {
        padding: 75px 30px 30px;
        padding-bottom: 98px;
    }
    @media (min-width: 775px) {
        display: flex;
        flex-direction: column;
        width: calc(100vw - 310px);
        margin-top: 20px;
        margin-left: 250px;
        padding-top: 75px;
    }

    & > div {
        width: 100%;
        max-width: 1180px;
        height: fit-content;

        & > section {
            margin-bottom: 20px;
        }
    }

    & > .extraPanel {
        border-radius: 8px;
        margin: 10px 0 32px !important;

        & > .errorPanel {

        }
    }
}

.panelContainerWithoutMargin {
    margin-left: 0;
    width: calc(100vw - 64px);
    padding: 168px 32px 30px;
    @include min-width(775px) {
        padding: 150px 32px 30px;
    }
    @include min-width($desktopMD) {
        padding: 75px 32px 30px;
    }
    & > div {
        margin: 0 auto;
        max-width: none;
    }
}

.topLink {
    margin: 20px 20px 0;
    margin-top: auto;
    padding: 20px 20px 5px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--neutral200);
    z-index: 5999;

    & > span {
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}