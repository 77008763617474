@import '../../../../styles/media-mixins';

.licenses {
    @include flexbox();
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    margin-top: 35px;
    gap: 20px;
}

.currentSubscriptionPanel {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 24px;
    border-radius: 10px !important;
    justify-content: space-between;
    & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        & > p:nth-of-type(1) {
            margin-right: auto;
            font-weight: bold;
            text-transform: capitalize;
        }
        & > p:nth-of-type(2) {
            margin-left: auto;
            font-size: 14px;
            color: var(--neutral1000);
        }
    }

    &__limits {
        width: 100%;
        margin-top: 19.25px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        gap: 19.25px;
        @include min-width(600px) {
            @include flex-wrap(nowrap);
        }
        @include min-width(775px) {
            @include flex-wrap(wrap);
        }
        @include min-width(880px) {
            @include flex-wrap(nowrap);
        }
    }
}
.warningPanelContainer {
    width: 100%;

    & > div > div:nth-child(2) {
        & > p {
            line-height: 16.5px !important;
        }
    }
}
.currentLicense {
    background-color: transparent;

    position: relative;

    &__tag {
        display: block;
        position: absolute;
        z-index: 9999;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;

        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: #fafafa;

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.availablePlantsHeader {
    margin-top: 32px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.license {
    width: 270px;
    margin-top: 20px;
    margin-right: 5px;
    padding: 20px 15px;
    border: 1px solid #e3e3e3;
    box-shadow: none;

    @include min-width($desktopSM) {
        justify-content: flex-start;
        margin-right: 20px;
    }

    @media (min-width: 1080px) {
        width: 23%;
        min-width: 262px;
    }
    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__title {
            font-weight: bold !important;
        }
        &__popularTag {
            background-color: var(--neutral1000);
            border-radius: 5px;
            padding: 3px 8px;
            & > p {
                color: white;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }

    &__description {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
    }

    &__price {
        & > span {
            font-size: 40px;
            font-weight: bold;
        }
    }

    &__features {
        margin-top: 10px;
        padding-top: 15px;
        margin-bottom: 10px;
        border-top: 1px solid #e3e3e3;
        width: 100%;
        &__title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &__list {
            font-size: 13px;

            &__item {
                margin-bottom: 15px;
                font-size: 13px;
                & > span {
                    font-weight: bold;
                    font-size: 13px;
                }
                & > div > div > .featureQuantity {
                    font-weight: bold;
                    font-size: 13px;
                }
                & > div > div > .featureName {
                    color: var(--neutral1000);
                    font-size: 13px;
                }
            }
        }
    }
    &__button {
        font-weight: bold;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.licenseActive {
    font-weight: bold;
    color: #3d9a50 !important;
    position: relative;
    padding-left: 15px;
    &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #3d9a50;
        border-radius: 10px;
        left: 0;
        top: 5px;
    }
}

.licenseCancelled {
    font-weight: bold;
    color: #dc3d43 !important;
    position: relative;
    padding-left: 15px;
    &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #dc3d43;
        border-radius: 10px;
        left: 0;
        top: 5px;
    }
}

.licenseInactive {
    font-weight: bold;
    color: #f7ce00 !important;
    position: relative;
    padding-left: 15px;
    &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #f7ce00;
        border-radius: 10px;
        left: 0;
        top: 5px;
    }
}

.licenseSkeleton {
    min-height: 590px;
}

.onboardingSwicthButton {
    margin: 0;
    width: 280px !important;
    height: 58px;

    @include min-width($mobileXL) {
        width: 341px !important;
    }

    & > div {
        width: 280px !important;
        height: 48px;

        @include min-width($mobileXL) {
            width: 341px !important;
        }

        & > input:nth-of-type(2):checked ~ div {
            width: 180px !important;
            transform: translateX(90px) !important;

            @include min-width($mobileXL) {
                width: 212px !important;
                transform: translateX(117px) !important;
            }
        }

        & > input:checked ~ div {
            padding: 0 !important;
        }

        & > label {
            font-size: 16px;
            font-weight: 500;
            height: 48px;
            line-height: 48px;
            font-size: 14px !important;

            @include min-width($mobileXL) {
                font-size: 16px !important;
            }
        }

        & > label:nth-child(3) {
            width: 90px !important;

            @include min-width($mobileXL) {
                width: 117px !important;
            }
        }

        & > label:nth-child(4) {
            width: 180px !important;

            @include min-width($mobileXL) {
                width: 212px !important;
            }
        }

        & > div {
            width: 90px !important;
            height: 48px;

            @include min-width($mobileXL) {
                width: 117px !important;
            }

            & > div {
                height: 48px;
            }
        }
    }
}
