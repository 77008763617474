@import '../../../../../../../styles/media-mixins';

.noDataAvailable__container {
    height: calc(100% - 61.75px);
    min-height: 406px;
    border: 1px solid var(--gray300);
    border-radius: 8px;
    padding: 20px;
    div {
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        svg {
            path {
                stroke: var(--neutral700);
            }
        }
        p {
            color: var(--neutral700);
        }
    }
}