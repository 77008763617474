// Text Colors 
.neutral1000 {
    color: var(--neutral1000)
}
.neutral800 {
    color: var(--neutral800)
}
.neutral700 {
    color: var(--neutral700)
}
.neutral600 {
    color: var(--neutral600)
}
.neutral500 {
    color: var(--neutral500)
}

.neutral100 {
    color: var(--neutral100)
}

.primary700 {
    color: var(--primary700)
}

.alert300 {
    color: var(--alert300)
}
.success300 {
    color: var(--success300)
}


// BG Colors
.primary1000_BG {
    background-color: var(--primary1000);
}

.primary700_BG {
    background-color: var(--primary700);
}

.neutral600_BG {
    background-color: var(--neutral600);
}
