@import '../../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

    & > .modal {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        width: 95vw;
        max-width: 480px;
        height: auto;
        border-radius: 20px;
        background: white;
        transform: translate(-50%, -50%);
        overflow: hidden;
        padding: 20px;
        gap: 24px;
        @include flexbox();
        @include flex-direction(column);

        @include min-width($tabletSM) {
            width: 80vw;
            height: auto;
        }
    }

    .modal__header {
        @include flexbox();
        @include align-items(flex-start);
        @include flex-direction(column);
        gap: 24px;
        width: 100%;
        background-color: var(--neutral100);

        &__top {
            @include flexbox();
            @include align-items(flex-start);
            @include justify-content(space-between);
            gap: 24px;
            width: 100%;
            &__close {
                cursor: pointer;
                &:hover {
                    & > svg > path {
                        stroke: var(--alert300);
                    }
                }
            }

            & > div > p > strong {
                color: var(--primary700);
            }
        }
    }


    .modal__content {
        width: 100%;
        @include flexbox();
        @include flex-direction(column);
        gap: 12px;

    }

    .modal__footer {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include justify-content(space-between);
        gap: 12px 24px;
        width: 100%;
        background-color: var(--neutral100);

        @include min-width($mobileMD) {
            @include flex-direction(row);
        }

        & > button {
            @include flex(1 0 0);
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            padding: 12px 24px;
            height: 44px;
            width: 100%;

            @include min-width($mobileMD) {
                @include flex-direction(row);
                width: auto;
            }
        }
    }

}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}

.tabCursorPointer {
    & > div > ul > li {
        cursor: auto !important;
    }
}
