@import '../../../../../../../../styles/media-mixins';
@import "../../../../../../../../styles/colors";

.closeConfirmationModal {
    & > div {
        max-height: 496px !important;
        max-width: 480px !important;
        & > div:nth-child(1) {
            padding: 20px 20px 0;
            min-height: 44px;
        }
        & > div:nth-child(2) {
            border-bottom: 0;
            overflow-y: auto;
            padding: 0 20px 20px;
            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }
        
            &::-webkit-scrollbar:vertical {
                width: 11px;

            }
        
            &::-webkit-scrollbar:horizontal {
                height: 20px;
            }
        
            &::-webkit-scrollbar-thumb {
                border: 2px solid var(--neutral100);
                /* should match background, can't be transparent */
                border-radius: 8px;
                background-color: var(--neutral300);
            }
            & > div {
                @include flex(0 0 100%);
            }
        }
        & > div:nth-of-type(3) {
            @include flex-direction(column);
            @include flex-wrap(wrap);
            padding: 0 20px 20px;
            @include min-width($mobileMD) {
                @include flex-direction(row);
                @include flex-wrap(nowrap);
            }
            
            & > button {
                width: 100%;
                @include flexbox();
                @include justify-content(center);
                gap: 24px;
                &:nth-of-type(1) {
                    border-color: var(--neutral500);
                    padding: 12px 24px;
                    @include justify-content(center);
                }
                &:nth-of-type(2) {
                    background-color: var(--alert300);
                    border: var(--alert300);
                    &:hover {
                        background-color: var(--alert400);
                        border: var(--alert400);
                    }
                }
            }
        }
    }

}

.closeConfirmation {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    height: 100%;
    padding: 24px;
    margin: auto;

    & > p {
        text-align: center;
    }
}
