@import '../../../../../styles/media-mixins';

.warningPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 0;
    padding: 16px;
    border-radius: 10px;
    width: 100%;
    background-color: white;
    border: 2px solid #f7dd5c;
    color: var(--neutral1000);
    max-width: 1180px;

    &__header {
        display: flex;
        flex-direction: row;
        & > img {
            margin-right: 10px;
        }
    }
    &__content {
        margin-left: 30px;
        & > p {
            margin-top: 10px;
        }
    }

    & > .closeIcon {
        margin-left: auto;
    }
}
