@import '../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.backgroundLayer.transparent {
    background: rgba(0, 0, 0, 0);
}

.modalContainer {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100vw;
    width: calc(100% - 40px);
    height: 100vh;
    margin: 0 auto;
    @media (min-width: 775px) {
        margin-left: 280px;
        width: calc(100vw - 310px);
        max-width: 1180px;
    }
}

.modal {
    position: absolute;
    z-index: 10;
    top: 50%;
    width: 95vw;
    max-width: 100%;
    height: fit-content;
    padding: 20px;
    border-radius: 10px;
    background: white;
    transform: translate(0, -50%);

    @include min-width($tabletSM) {
        width: 600px;
        height: fit-content;
        max-width: 100%;
    }
}

.modal__footer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
    background-color: white;
    gap: 15px;
}

.modal__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    background-color: white;

    & > div:first-child {
        width: 90%;
        & > h3 {
            font-weight: bold;
        }
        & > p {
            font-size: 14px;
        }
    }

    &__close {
        cursor: pointer;
        &:hover {
            & > svg > path {
                stroke: red !important;
            }
        }
    }
}

.modal__content {
    margin-top: 20px;
    height: fit-content;
    overflow-y: auto;
    width: 100%;
    max-height: 500px;
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}
