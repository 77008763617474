@import '../../../../../../styles/media-mixins';

.credStatus {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
        margin: 0 !important;
        padding: 4px 8px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        font-family: var(--base);
        font-weight: 500;
        line-height: 16px;
    }
}

.credIssuedAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & > button {
        background-color: #fafafa;
    }
}

.detailLink {
    padding: 15px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.pendingAction {
    width: 127px !important;
    padding-top: 10px !important;

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &__button {
            margin-top: 0 !important;
            margin-right: 15px !important;
            position: relative;

            &:before {
                content: '';
                display: none;
                position: absolute;
                z-index: 9998;
                width: 0;
                height: 0;
            }

            &:after {
                display: none;
                position: absolute;
                z-index: 9999;

                width: 74px;
                height: 36px;
                color: #fff;
                font-size: 12px;

                line-height: 36px;
                text-align: center;

                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &:after {
                    display: block;
                }
            }

            & > div {
                width: 30px;
                height: 30px;
                padding: 8px 9px;
            }
        }
    }
}

.issue {
    &:after {
        right: 24px;
        top: -20px;
        content: 'Issue';
    }
}

.reject {
    &:after {
        top: -35px;
        content: 'Reject';
    }
}

.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 85px;

    & > button {
        margin-left: auto;
        height: 100%;
        width: 24px;
        background-color: transparent;

        &:hover {
            border: none !important;
            box-shadow: none;
            background-color: transparent;
        }

        & > img {
            height: 24px;
            width: 24px;
        }
    }

    & > .backgroundLayerActive {
        display: none;
    }
}

.action__iconContainer {
    height: 24px;
    width: 24px;
    padding: 2px;
    margin-left: 8px;
    background-color: transparent;

    &:last-child {
        margin-top: 0;
    }

    .action__icon {
        height: 24px;
        width: 24px;
    }
}

.bin {
    width: 20px;
    padding-top: 5px;
    margin-top: 5px;
    cursor: pointer;
    height: 24px;

    &:hover {
        & > path {
            fill: none;
            stroke: red;
        }
    }
}

.yellow {
    font-weight: bold;
    color: #f7ce00;
}

.green {
    font-weight: bold;
    color: green;
}

.red {
    font-weight: bold;
    color: #dd3d43;
}

.gaBlue {
    font-weight: bold;
    color: var(--neutral1000);
}
