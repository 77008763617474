@import "../../../../styles/media-mixins";

.scrollableList {
    overflow-y: scroll;
    width: calc(95vw - 42px );
    padding: 0 5px 0 8px;

    @include min-width($tabletSM) {
        width: 100%;
    }
}

.scrollableList::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollableList::-webkit-scrollbar:vertical {
    width: 11px;
}

.scrollableList::-webkit-scrollbar:horizontal {
    height: 20px;
}

.scrollableList::-webkit-scrollbar-thumb {
    border: 2px solid white; /* should match background, can't be transparent */
    border-radius: 8px;
    background-color: var(--neutral300);
}

.shortList {
    height: calc(95vh - 288px);

    @include min-width($tabletSM) {
        height: calc(80vh - 288px);
    }
}

.longList {
    height: calc(95vh - 194px);

    @include min-width($tabletSM) {
        height: calc(80vh - 215px);
    }
}
