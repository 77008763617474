@import '../../../../../styles/media-mixins';

.optionsCustomContainer {
    @include flexbox();
    @include flex-direction(column);
    gap: 4px;
    background-color: var(--neutral100);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    top: 52px;
    z-index: 9;
    width: 100%;
    max-height: 360px;
    overflow: scroll;
    padding: 4px 0px;
    border-radius: 8px;
    overflow: hidden;

    & > .option {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        padding: 12px;
        gap: 12px;
        &:hover {
            background-color: var(--neutral200);
        }
        
    }

    & > .showMoreOption {
        @include justify-content(flex-start);
        gap: 4px;
        color: var(--primary700);
        cursor: pointer;
    }

    & > .separator {
        margin: 0;
        background-color: var(--neutral300);
        height: 1px;
    }
    & > .addAccountOption {
        color: var(--neutral1000);
        cursor: pointer;
    }
}
