@import '../../../../../styles/media-mixins';

.addAccountModal > div {
   max-width: none;
  & > div {
      & > div:nth-child(2) {
         min-height: 200px;
         margin-top: 24px;
      }

      & > div:nth-child(3) {
         margin-top: 24px;
         gap: 20px;
         & > button {
            @include flex(1, 0, 0);
            @include flexbox();
            @include justify-content(center);
            padding: 12px 24px;
            height: 48px;
         }
      }
   }
}

.content {
   @include flexbox();
   @include flex-direction(column);
   gap: 24px;
}

.methodInput {
   & > div:nth-of-type(2) > div {
      position: relative;
      width: calc(100% - 4px);
      top: 1px;
      margin: 0 auto;
   }
}

.warningAlert {
   @include flex-direction(row);
   @include align-items(center);
   background-color: var(--warning100);
   padding: 12px;
   gap: 12px;
   margin: 0;
   & > div:nth-of-type(1) > img {
      height: 24px;
      width: 22px;
      margin-right: 0;
   }

   & > div:nth-of-type(2) {
      margin: 0;
      & > p {
         margin: 0;
         font-family: var(--base);
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 16px;
      }
   }
}
