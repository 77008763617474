@import '../../../../styles/media-mixins';

.gaSelectContainer.required {
    label {
        &::after {
            color: var(--alert300);
            content: ' *';
        }
    }
}

.gaSelectContainer {
    position: relative;
    .topTextsContainer {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        margin-bottom: 0.5rem;
    }
    label {
        display: inline-block;
        vertical-align: baseline;
        text-align: left;
        @include flex(1 1 0);
         & > div {
            right: -15px;
            bottom: -4px;
            width: 0;
        }
    }
    .extraDescription {
        width: 100%;
        text-align: right;
        @include flex(0 0 30%);
        margin-left: 15px;
        &.noLabel {
            margin-left: 0;
            @include flex(1 1 0);
        }
    }
    .bottomTextsContainer {
        > * {
            text-align: left;
        }
    }
    select {
        margin-top: 0;
        margin-right: 0;
        letter-spacing: 0.16px;
        outline: 2px solid transparent;
        outline-offset: -2px;
        width: 100%;
        height: 2.5rem;
        padding: 0 2.5rem 0 1rem;
        border: none;
        border-radius: 8px;
        background-color: transparent;
        border: 1px solid var(--neutral400);
        color: var(--neutral1000);
        text-decoration: none;
        background-image: none;
        @include min-width($tabletSM) {
            display: none;
        }
        &:hover {
            background-color: transparent;
        }
    }
    & > svg {
        position: absolute;
        top: 32px;
        right: 1rem;
        pointer-events: none;
        overflow-clip-margin: content-box;
        overflow: hidden;

        path {
            stroke: var(--neutral400);
        }
    }
    & > select {
        margin-right: 0 !important;
    }
    
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 20px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid var(--neutral100); /* should match background, can't be transparent */
        border-radius: 8px;
        background-color: var(--neutral300);
    }
}

/* Show custom Dropdown Desktop */

.selectCustomContainer {
    display: none;
    @include min-width($tabletSM) {
        position: relative;
        display: block;
    }
    & > input {
        display: none;
        @include min-width($tabletSM) {
            display: block;
            letter-spacing: 0.16px;
            outline: 2px solid transparent;
            outline-offset: -2px;
            width: 100%;
            height: 2.5rem;
            padding: 0 3rem 0 1rem;
            border-radius: 8px;
            color: var(--neutral1000);
            background-color: var(--neutral100);
            border: 1px solid var(--neutral400);
            text-decoration: none;
            cursor: default;
            &:hover,
            &:active,
            &:focus {
                border-color: var(--primary700);
            }
            &::placeholder {
                color: var(--neutral600);
            }
        }
    }
    & > .optionsCustomContainer {
        @include min-width($tabletSM) {   
            display: block;
            background-color: var(--neutral100);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
            position: absolute;
            top: 40px;
            z-index: 9;
            width: 100%;
            max-height: 7.4rem;
            overflow: scroll;
            padding: 8px;
            border-radius: 6px;
            & > .menuItem {
                padding: 16px 12px;
                &:hover {
                    background-color: var(--neutral200);
                    border-radius: 8px;
                }
                & > .menuItem__option {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    line-height: normal;
                }
            }
        }
    }
}

.gaSelectContainer.readOnly {
    & > select:disabled {
        color: var(--neutral600) !important;
        opacity: 1;
        background-color: var(--neutral300) !important;
        border: 1px solid var(--neutral300) !important;
        outline: none !important;
    }
    & > div > input:disabled {
        color: var(--neutral600) !important;
        opacity: 1;
        background-color: var(--neutral300) !important;
        border: 1px solid var(--neutral300) !important;
        outline: none !important;
    }
    svg {
        display: none;
    }
}