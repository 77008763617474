@import '../../../../../styles/media-mixins';

.tableTab__list {
    width: 100vw;
    & > ul {
        width: 100vw;
        & > div {
            width: 900px;
            display: flex;
            justify-content: flex-end;
            border-bottom: 1px solid #e0e0e0;
        }
    }
}
.table__header {
    outline: 2px solid transparent;
    height: 40.2px;
    display: none;
    @include min-width($mobileXL) {
        display: block;
    }
    &__option {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.75rem 1rem 0.5rem;

        &__icon {
            width: 18px;
            margin-right: 5px;
        }
    }
}

.infoReachedLimit {
    display: block;
    position: absolute;
    top: -20px;
    left: 10rem;
    display: block !important;
    z-index: 9999;
    background-color: var(--neutral1000);
    padding: 10px;
    color: white;
    border-radius: 4px;
    line-height: 18px;
    max-width: 200px;
}

.infoReachedLimitHidden {
    display: none;
}

.tabIssuedContent, .tabPendingContent {
    position: relative;
    margin-top: 14px;
}
