@import "../../../../../styles/media-mixins";

.backgroundLayer {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 150px;
    cursor: auto;
    width: 100vw;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    @include min-width($tabletSM) {
        left: 0;
    }
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    max-width: 1080px;
    padding: 20px 30px;
    border-radius: 20px;
    background: white;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 350px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 13px #0000002e;;

    @include min-width($tabletSM) {
        width: 350px;
        height: 250px;
    }

    & > img {
        width: 30px;
        margin-bottom: 15px;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin-bottom: 10px;
        padding: 0 20px;
    }

    &__description {
        text-align: center;
        margin-bottom: 20px;
        padding: 0 20px;
        font-size: 14px;
    }

    & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & > button {
            display: flex;
            justify-content: center;
            cursor: pointer;
            font-weight: bold;
            width: 120px;
        }
    }

    &__delete {
        background-color: #DC3D43;
        border: none;
        color: white;
        &:hover {
            background-color: #DC3D43;
            border: none;
            color: white;
        }
    }

    &__close {
        z-index: 9999;
        z-index: 9999;
        position: absolute;
        right: 0;
        padding: 20px;
        cursor: pointer;

        &:hover {
            & > svg > g > path {
                stroke: red;
            }
        }
    }
}