@import "../../../../../../styles/media-mixins";
@import '../../../../../../styles/colors';

tr:nth-last-child(-n + 3) > td > div > div > div:nth-last-child(1) {
    &::after {
        margin-top: -60px;
    }
}

tr:nth-last-child(-n + 2) > td > div > div > div:last-child > ul {
    margin-top: -120px;
    margin-left: 73px;
}

tr:nth-last-child(-n + 4) > td > div > div > div:last-child > ul {
    margin-top: -120px;
    margin-right: 28px;
}

.selectTag {
    position: relative;
    display: flex;
    align-items: center;
    & > div {
        position: relative;
        width: 181px;
        width: 32px;
        top: 0;
        top: 0;
    }

    & > .typeTag {
        width: fit-content !important;
        z-index: 99999;
        margin-right: 10px;

        & > div {
            &:after {
                content: 'Remove';
                display: none;
                position: absolute;
                z-index: 9999;
                width: 60px;
                height: 36px;
                color: #fff;
                font-size: 10px;
                line-height: 36px;
                text-align: center;
                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &:after {
                    display: block;
                }
            }
            & > img {
                cursor: pointer;
            }
        }
    }
}

.alias {
  position: relative;
  min-width: 226px;
    &__content {
        width: calc(100% - 70px);
        min-width: 150px;
        &__input {
            &:hover {
                background-color: #92929345;
            }
        }
        &__buttons {
            position: absolute;
            display: flex;
            width: -moz-fit-content;
            width: fit-content;
            right: 0;
            top: 6px;
        
            & > div {
                margin-left: 5px;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 5px 13px #0000002e;;
                }
            }
        }
        
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
}

.noBorder {
    border-bottom: none !important;
}


