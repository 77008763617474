@import "../../../../styles/media-mixins";

.lightButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.36px;
    gap: 8px;

    @include min-width($mobileSM) {
        height: 40px;
    }

    &__icon {
        display: none;

        @include min-width($mobileSM) {
            display: inline-block;
        }
    }
}

.lightButtonActive {
    border: 1px solid var(--primary700);
    background-color: var(--neutral100);
    color: var(--neutral1000);
    cursor: pointer;
    font-weight: normal;

    &:hover {
        color: var(--primary700);
    }
    &.noBorder {
        border: 1px solid transparent;
        padding: 0;
    }
}

.lightButtonInActive {
    border: 1px solid var(--neutral500);
    background-color: var(--neutral300);
    color: var(--neutral500);
    font-weight: bold;
    &.noBorder {
        border: 1px solid transparent;
        padding: 0;
    }
}

.lightButtonIconInActive {
    stroke-width: 2;

    & > path {
        stroke: var(--neutral100);
    }
}

.lightButtonIconActive {
    & > path {
        stroke: var(--neutral1000);
    }
}

// SIZES
.large {
    padding: 12px 24px;
    height: 48px;
}

.medium {
    padding: 15px 15px;
    height: 35px;
}

.small {
    padding: 8px 16px;
    height: 32px;
}
