@import '../../../../styles/media-mixins';

.logoContainer {
    display: flex;
    align-items: center;
    height: 2rem;

    img {
        height: 100%;
    }
}

.panelContainer {
    display: flex;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: #f2f2f2;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (min-width: 700px) {
        height: calc(100% - 152px);
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
    }

    & > .panelContainerChildren {
        display: flex;
        align-content: flex-start;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-x: hidden;
        align-items: center;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 11px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border: 2px solid white;
            /* should match background, can't be transparent */
            border-radius: 8px;
            background-color: var(--neutral300);
        }

        @include min-width($mobileMD) {
            align-items: center;
        }
    }
}

.topbar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px;
    background: rgb(255, 255, 255);

    @media (min-width: 775px) {
        padding: 20px 30px 20px;
    }

    @media (min-width: 1000px) {
        background: -moz-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
    }
}

.footer {
    position: relative;
    z-index: 999999;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;
    background: rgb(255, 255, 255);

    @media (min-width: 775px) {
        padding: 0px 30px;
    }

    @media (min-width: 1000px) {
        background: -moz-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        background: -webkit-linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 52%,
            var(--neutral200) 50%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid white;
        /* should match background, can't be transparent */
        border-radius: 8px;
        background-color: var(--neutral300);
    }

    @include min-width($tabletSM) {
        flex-direction: row;
        height: 80px;
        border-top: none;
        flex-wrap: wrap;
    }

    ul {
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 0;

        @include min-width($tabletSM) {
            flex-direction: row;
            justify-content: flex-start;
        }

        & > a {
            width: fit-content;
            padding: 5px;
            text-decoration: none;

            @include min-width($tabletSM) {
                margin-right: 10px;
            }

            &:not(.hover) > li {
                text-shadow: none;
                letter-spacing: 0.1rem;
                transition: text-shadow 0.3s;
            }

            &:hover > li {
                text-decoration: underline;
            }
        }

        & > li {
            padding-right: 10px;
            color: var(--neutral1000);
            font-size: 13px;
            letter-spacing: 0.12px;
            white-space: nowrap;

            @include min-width($tabletSM) {
                padding-right: 30px;
                font-size: 14px;
                letter-spacing: 0.16px;
            }
        }
    }

    & > a {
        width: 100%;
        padding: 5px;
        color: var(--neutral1000);
        white-space: nowrap;
        text-align: center;

        @include min-width($mobileMD) {
            display: block;
            width: fit-content;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.spaceLinks {
    color: #fafafa !important;

    &:hover {
        text-decoration: underline;
    }

    @include min-width($tabletSM) {
        margin-right: 37.7px !important;
        text-align: center;
        width: 20px !important;
        cursor: auto;
    }
}

.version {
    margin-top: 20px;

    @include min-width($tabletSM) {
        margin-top: 0px;
        margin-left: auto;
    }
}

.columnDisplay {
    flex-direction: column !important;
    margin-top: 30px;

    & > div:first-child {
        width: fit-content;

        @include min-width($tabletSM) {
            width: 550px;
        }
    }
}

.extraPanel {
    position: absolute;
    top: 80px;
    right: 20px;
    height: fit-content !important;
}