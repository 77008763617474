@import '../../../../../styles/media-mixins';

.organizationModal {
    max-width: none !important;
    & > div > div {
        padding: 32px;
        padding-right: 17px;
        width: 568px;
        max-height: 90vh;
        & > div:nth-child(2) {
            height: 800px;
            max-height: 50vh;
             
        }
    }
}

.terms {
    margin-top: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    & > input {
        margin: 0 6px 0 0;
        cursor: pointer;
    }

    & > .checkbox__text {
        font-size: 0.75rem;
        letter-spacing: 0.32px;
        display: inline-block;
        margin: 0 5px 0rem 0;
        color: #525252;
        font-weight: 400;
        line-height: 1rem;
        vertical-align: baseline;
    }

    & > a {
        font-size: 0.75rem;
        letter-spacing: 0.32px;
        margin: 0rem 5px 0 0;
        text-decoration: none; 
        &:hover {
            text-decoration: underline;
        }
    }
}

.doubleColumn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
        width: 48%;
    }

    & > div:nth-child(3) {
        margin-top: 15px;
    }

    & > div:nth-child(4) {
        margin-top: 15px;
    }
}

.organizationModal__content {
    padding-right: 15px;
    // margin-top: calc(120px - 7vw);
    @include min-width($mobileXL) {
        margin-top: 0;
    }

    & > .buttonsContainer {
display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    margin-top: 32px;
    background-color: white;
    gap: 15px;
    }
}
