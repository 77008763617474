@import '../../../../../../../styles/media-mixins';
@import "../../../../../../../styles/colors";

.bodyContainer {
    width: 100%;
    @include flexbox();
    @include flex-direction(column);
    gap: 20px;
    &__redirectionForm {
        gap: 12px;
    }
}

.thirdStepImage {
    img {
        max-width: 229px !important;
    }
}
.linkButton {
    cursor: pointer;
    width: fit-content;
    & > span {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(nowrap);
        gap: 4px;
    }
}