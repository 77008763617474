@import '../../../../../styles/media-mixins';

.noCredentialsPanel {
    @include flexbox();
    @include flex(1, 0, 0);
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 265px); // 100vh - section paddings and margins, selector height and spaces
    padding: 32px;
    border-radius: 8px;
    border: 1px dashed var(--neutral500);

    &__content {
        @include flexbox();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        gap: 20px;
        max-width: 400px;

        & > img {
            margin: 13px auto;
            width: 120px;
            height: auto;
        }

        &__body {
            @include flexbox();
            @include flex-direction(column);
            gap: 8px;

            & > p {
                text-align: center;
            }
        }

        &__footer {
            @include flexbox();
            @include flex-wrap(wrap);
            gap: 20px;
            margin-top: 12px;
            position: relative;

            & > div {
                @include flex(1, 0, 0);
                min-width: 163px;
                position: relative;
                cursor: pointer;
                &:hover {
                    & > .lightButton {
                        color: var(--primary700);
                    }
                }
                & > button {
                    @include flexbox();
                    @include justify-content(center);
                    width: 100%;
                }

                & > .lightButton {
                    background-color: transparent;
                }

                & > input[type='file'] {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    font-size: 0;
                    width: 100%;
                    height: 100%;
                    filter: alpha(opacity=0); /* IE 7 */
                    cursor: pointer;
                    &::-webkit-file-upload-button {
                        cursor:pointer;
                    }
                }
            }
        }
    }
}