@import '../../../../styles/media-mixins';

.progressIndicator {
  width: 100%;
  &__items {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    gap: 12px;
    width: 100%;
  }
}

.option {
    @include flex(1, 0, 0);
    border-radius: 4px;
    height: 4px;
    background-color: var(--neutral300);
}

.optionActiveOrCompleted {
    background-color: var(--primary700);
}
