@import "../../../../styles/media-mixins";
$skeleton-01: #e5e5e5;
$skeleton-02: #c6c6c6d1;

.cardSkeleton {
    display: block;
    overflow: hidden;
    height: 90px;
    margin: 10px 0 15px;
    border-radius: 10px;
    background: #e0e0e0;

    @include min-width($mobileXL) {
        display: none !important;
    }
}

.tableSkeleton {
    display: none;

    @include min-width($mobileXL) {
        position: relative;
        display: table-row;
        display: flex;
        overflow: hidden;
        align-items: center;
        flex-direction: row;
        margin: 10px 0 15px;
        border-radius: 10px;
        box-shadow: 0 3px 10px var(--boxShadowLigh);
    }

    thead {
        background-color: #e0e0e0;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.16px;
        line-height: 1.29;
    }

    table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }

    tbody {
        width: 100%;
        background-color: #f4f4f4;
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.16px;
        line-height: 1.29;
    }

    tr {
        width: 100%;
        height: 3rem;
        border: none;
    }

    th {
        padding-right: 1rem;
        padding-left: 1rem;
        padding-left: 1rem;
        background-color: #e0e0e0;
        color: #161616;
        vertical-align: middle;
    }

    td {
        height: 47px;
        padding: 13px 20px;
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #e0e0e0;
        background: #f4f4f4;
        color: #525252;
    }

    td:not(:first-child) {
        border-left: 1px solid #e0e0e0;
    }

    span {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 20px;
        padding: 0;
        border: none;
        border-radius: 10px;
        background: #e5e5e5;
        box-shadow: none;

        pointer-events: none;
    }

    // span::before {
    // TODO: Add order icon
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     background: #c6c6c6;
    //     content: "";
    //     animation: 2000ms ease-in-out skeleton infinite;

    //     will-change: transform-origin, transform, opacity;
    // }
}

@keyframes skeleton {
    0% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }

    20% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }

    28% {
        transform: scaleX(1);
        transform-origin: right;
    }

    51% {
        transform: scaleX(0);
        transform-origin: right;
    }

    58% {
        transform: scaleX(0);
        transform-origin: right;
    }

    82% {
        transform: scaleX(1);
        transform-origin: right;
    }

    83% {
        transform: scaleX(1);
        transform-origin: left;
    }

    96% {
        transform: scaleX(0);
        transform-origin: left;
    }

    100% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }
}

@keyframes skeleton-animation {
    0% {
        right: 100%;
    }

    50% {
        right: -128px;
    }

    100% {
        right: 100%;
    }
}

.skeleton {
    position: relative;
    display: grid;
    overflow: hidden;
    width: 100%;
    height: 78px;

    grid-gap: 8px;
    grid-template-columns: 128px 200px;
}

.skeleton:before {
    position: absolute;
    display: block;
    width: 128px;
    height: 78px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
    content: "";
    animation: skeleton-animation 2s infinite;
}
