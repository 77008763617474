@import '../../styles/media-mixins';

.homeBlock {
    position: relative;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    gap: 28px;
    min-height: 105px;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--neutral100);
    box-shadow: 0 3px 10px var(--boxShadowLigh);
    cursor: pointer;
      &:hover {
        box-shadow: 0 5px 13px #0000002e;
    }

    &__icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        padding: 8px;
        background: rgba(255, 255, 255, 0.50);
        @include flexbox();
    }

    &__iconDisabled {
        background: transparent;
        padding: 0;
    }

    &__info {
        @include flex(1)
    }
    &__textTooltipContainer {
       position: relative;
 
        & > .gaTooltip__info {
            position: absolute;
            min-width: 151px;
            background-color: var(--neutral1000);
            padding: 8px;
            border-radius: 4px;
            z-index: 9999;
            transform: translateX(10px);
            word-wrap: break-word;
            max-width: 100px;
            width: max-content;
            &::before {
                content: '';
                left: 4px;
                top: 14px;
                width: 0;
                height: 0;
                position: absolute;
                border: solid var(--neutral1000);
                border-width: 5px;
                pointer-events: none;
                margin-left: calc(8px * -1);
                transform: rotate(45deg);
            }
        }
    }
}

.upgradeIcon {
    transform: rotate(135deg);
}
