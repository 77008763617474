@import '../../../../styles/media-mixins';

.circlePercentageBar {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    @include flex(0 0 calc(50% - 19.25px));
    padding: 15.4px 8px;
    border-radius: 12px;
    border: 1px solid var(--gray300);
    margin: 0;
    @include min-width(600px) {
        @include flex(0 0 calc(25% - 19.25px));
    }
    @include min-width(775px) {
        @include flex(0 0 calc(50% - 19.25px));
    }
    @include min-width(880px) {
        @include flex(0 0 calc(25% - 19.25px));
    }

    & > .circleContainer {
        position: relative;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);

        & > .quantity {
            text-align: center;
            position: absolute;
            top: 18.6px;
            width: 100%;
        }

        & > .centeredQuantity {
            top: 24.6px;
            width: 100%;
        }

        & > .total {
            position: absolute;
            top: 43.6px;
            text-align: center;
            width: 100%;
            color: var(--neutral700);
        }
    }
   
    &__labels {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        padding-top: 10px;
        text-align: left;

        @include min-width($mobileSM) {
            width: calc(100% - 130px);
            padding-top: 0;
        }
        @include min-width($mobileSM) {
            margin-left: 30px;
            letter-spacing: 1.2px;
        }

        &__title {
            text-align: center;
            color: var(--neutral1000);
            margin-top: 11.55px;
        }
    }
}

.responsiveText {
    min-height: 32px;
    @include min-width(458px){
        min-height: 0;
    }
    @include min-width(600px){
        min-height: 32px;
    }
    @include min-width($tabletMD){
        min-height: 0;
    }
    @include min-width(880px){
        min-height: 32px;
    }
    @include min-width(1096px){
        min-height: 0;
    }
    @include min-width(1490px){
        min-height: 32px;
    }
    @include min-width(1914px){
        min-height: 0;
    }
}
