@import '../../../styles/media-mixins';

.numberStepper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;
    border-radius: 5px;
    font-family: arial;
    text-align: left;
    counter-reset: flag;
    width: 100%;
    @media (min-width: 438px) {
        flex-direction: row;
        width: fit-content;
    }

    .step {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 15px;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        &:hover {
            .step__label {
                text-decoration: underline;
            }
        }

        @media (min-width: 438px) {
            margin-top: 0;
        }

        &:not(:first-child) {
            margin-left: 0;

            @media (min-width: 438px) {
                margin-left: 20px;
            }
        }
        &__order {
            background-color: var(--neutral500);
            height: 26px;
            width: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            margin-right: 10px;
            & > p {
                color: white;
                font-weight: bold;
            }
        }

        &__label {
            color: var(--neutral500);
            font-size: 14px;
font-family: var(--base);
line-height: 16px;

        }
    }

    .inactiveStep {
        cursor: auto !important;
        &:hover {
            .step__label {
                text-decoration: none !important;
            }
        }
    }
    
    .activeStep {
        cursor: auto;
        &:hover {
            .step__label {
                text-decoration: none;
            }
        }
        .step__order {
            background-color: var(--primary700);
            & > p {
                color: white;
            }
        }

        .step__label {
            color: var(--primary700);
            font-size: 14px;
            font-family: var(--base);
            font-weight: 700;
            line-height: 16px;
        }
    }
}
