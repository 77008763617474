@import '../../../../styles/media-mixins';

.connections {
    @include flexbox();
    @include flex-direction(column);
    gap: 32px;
    padding-top: 10px;
     &__header {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        @include justify-content(space-between);
        gap: 12px;
    }

    & > .searchBar {
        height: auto;
        & > div {
            margin: 0;
        }
    }
}

.searchBarSkeleton {
    height: 40px;
    margin: 0;
}
