@import "../../../styles/media-mixins";

.formSection {
    &__content {
        margin-top: 32px;

        &__subDescription, &__subText {
            margin-bottom: 20px;
        }
    }
}
