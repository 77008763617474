@import '../../../../../styles/media-mixins';

.table {
    width: 100%;
    overflow-x: scroll;
    overflow-y: initial;
    padding: 5px 5px 10px;
    border-radius: 10px;
    box-shadow: none;
    perspective: 1px;
    &::-webkit-scrollbar-thumb {
        border: 2px solid white; /* should match background, can't be transparent */
        border-radius: 8px;
        background-color: var(--neutral300);
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    @include min-width($mobileXL) {
        padding: 0;
    }

    & > table {
        width: 100%;

        & > thead {
            height: 2rem;

            & > tr {
         
                & > th {
                padding: 10px;
                color: var(--neutral1000);
                cursor: default;
                font-size: 14px;
                font-weight: 600;

                @include min-width($desktopSM) {
                    font-size: 14px;
                }

                & > div {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    & > img {
                        height: 100%;
                    }
                    & > span {
                        margin-left: 5px;
                    }
                }

                & > span > svg {
                    opacity: 1;
                }
            }
        }
        }

        & > tbody {
            & > tr {
                height: 3rem;
                min-height: 50px;

                & > td {
                    min-width: 25px;
                    font-size: 14px;
                    max-width: 400px;
                    min-height: 50px;
                    padding: 10px;
                    border-bottom: 1px solid #e0e0e0;
                         border-top: 1px solid #e0e0e0;
                    color: var(--neutral1000);

                    @include min-width($desktopSM) {
                        max-width: 420px;
                    }
                    overflow-wrap: break-word;
                    text-overflow: ellipsis;
                    word-wrap: break-word;

                    @media (min-width: 775px) and (max-width: 925px) {
                        max-width: 250px;
                    }

                    & > .modal__content > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        & > .modalAction {
                            width: 15px;
                            height: 15px;
                            padding: 2px;
                            border-radius: 10px;
                            margin-left: 5px;
                            &:hover {
                                padding: 1px;
                                border: 1px solid var(--neutral1000);
                            }
                            &__icon {
                                width: 10px;
                            }
                        }
                    }

                    & > .options {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        width: 85px;

                        & > button {
                            margin-left: auto;
                            height: 100%;
                            width: 24px;
                            background-color: transparent;
                            &:hover {
                                border: none !important;
                                box-shadow: none;
                                background-color: transparent;
                            }
                            & > img {
                                height: 24px;
                                width: 24px;
                            }
                        }

                        & > .backgroundLayerActive {
                            display: none;
                        }
                    }

                    & > .status__content {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        & > div {
                            margin: 0 !important;
                            padding: 5px 5px;
                            border: none;
                            border-radius: 10px;
                        }
                        & > span {
                            margin-left: 10px;
                        }
                    }
                }
            }

            & > .table__mobileDisplay {
                height: auto;
            }
        }

        tr:nth-last-child(-n + 3) > td > div > div:nth-last-child(2) {
            margin-top: -120px;
            margin-right: 73px;
        }

        tr:nth-last-child(-n + 4) > td > div > div:nth-last-child(2) {
            margin-top: -120px;
            margin-right: 73px;
        }
    }

    &__mobileDisplay:not(:last-child) {
        margin-bottom: 10px;
    }
}

.disabledPagination {
    pointer-events: none;
    opacity: 0.2;
    & > div {
        cursor: not-allowed !important;
    }
}

.yellow {
    font-weight: bold;
    color: #f7ce00;
}

.green {
    font-weight: bold;
    color: green;
}

.red {
    font-weight: bold;
    color: #dd3d43;
}

.gaBlue {
    font-weight: bold;
    color: var(--neutral1000);
}
