@import "../../../../../styles/media-mixins";

.scopeList {
    @include flexbox();
    @include flex-direction(column);
    gap: 20px;
    width: 100%;
    min-height: calc(100% - 29px);
    margin-top: 12px;
}

.previewModalResponsive {
    & > div {
        margin-left: auto !important;
        margin: 0 auto;
        & > div {
            padding: 24px;
            gap: 32px;
            max-height: calc(100vh - 40px);
        
            @include flexbox();
            @include flex-direction(column);

            @include min-width($tabletSM) {
                max-width: 600px !important;
                width: 600px !important;
            }

            & > div:nth-child(2) {
                margin-top: 0;
                max-height: 100%;
                    overflow-y: scroll;
            }
        }
    }
}

.previewVerificationModalResponsive {
    & > div > div {
         @include min-width($tabletSM) {
            max-width: 960px !important;
            width: 960px !important;
        }
    }
}

.verificationPreviewContainer {
    position: relative;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-start);

    @include min-width($mobileXL) {
        @include flex(1, 0, 0);
        min-width: 461px;
        max-width: 685px;
        width: 50%;
    }

    .verificationPreview {
        width: 100%;
        @include min-width-height(1440px, 640px)  {
            position: fixed;
            width: auto;
            width: inherit;
            max-width: inherit;
        }
    
        & > div {
            box-shadow: none;
            gap: 24px;
            height: calc(100vh - 264px);
            padding: 0;
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);

            @include min-width(1320px) {
                height: calc(100vh - calc(97px + 13px + 32px + 68px)); //PanelScafoldingPrivate padding top and marginTop, section marginTop & marginBottom;
                padding: 24px;
                min-height: 842px;
            }
        
            @include min-width-height(1440px, 640px) {
                min-height: auto;
            }
        }
        &__header {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            gap: 24px;
            & > div > div  {
                &:nth-child(3) {
                    margin-top: 0;
                    & > div {
                        margin-top: 0;
                    }
                }
            }
        }
        &__body {
            @include flexbox();
            @include flex-direction(column);
            @include flex(1 0 auto);
            @include min-width(1320px) {
            }
            & > p:nth-of-type(1) {
                width: 100%;
                text-align: left;
            }
        }
        &__footer {
            width: 100%;
            > button {
                width: 100%;
                padding: 24px 12px;
                height: 48px;
                @include flexbox();
                @include flex-direction(row);
                @include justify-content(center);
            }

        }
    }
}

.scrollableArea {
    overflow-y: auto;
    height: 100%;
    vertical-align: initial;
    padding-right: 2px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    @include flexbox;
    @include flex-direction(column);

    @include min-width(1320px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.previewResponsive {
    background-color: var(--neutral200);
    border-radius: 12px;
    padding: 4px;
    margin-left: auto;
    width: fit-content;
    @include flexbox();
    @include align-items(center);

    & > div {
        padding: 12px;
        border-radius: 8px;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        cursor: pointer;
    }

    & > .active {
        background-color: var(--neutral100);
    }
}

.previewTutorial {
    padding: 12px;
    background-color: var(--neutral200);
    border-radius: 8px;
    gap: 24px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    @include min-width($mobileXL) {
        @include flex-wrap(nowrap);
    }

    & > button {
        min-width: fit-content;
        background-color: var(--neutral500);
        border: 0px;
        margin-left: auto;
        &:hover {
            background-color: var(--neutral700);
            border: 0px;
        }
    }
}

.appIntPreviewModal {
    & > div > div {
        height: calc(100vh - 210px) !important;
    }
}