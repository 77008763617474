@import '../../../../../../../../styles/media-mixins';

.periodToggle {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding-top: 20px;
    & > span {
        margin-bottom: 2px;
    }
}

.periodCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.periodCheckboxLabel {
    cursor: pointer;
    text-indent: 49px;
    width: 44px;
    height: 22px;
    background: var(--neutral100);
    border: 2px solid var(--primary700);
    display: block;
    border-radius: 90px;
    position: relative;
    margin-right: 10px;
}

.periodCheckboxLabel:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 18px;
    height: 18px;
    background: var(--neutral100);
    border: 2px solid var(--primary700);
    border-radius: 90px;
    transition: 0.3s;
}

.periodCheckbox:checked + .periodCheckboxLabel {
    background: var(--primary700);
}

.periodCheckbox:checked + .periodCheckboxLabel:after {
    width: 18px;
    left: calc(100% - 18px);
}

.periodCheckboxLabel:active:after {
    width: 22px;
}

.tableContainer {
    width: 100%;
    display: none;
    max-height: calc(100% - 116px);
    max-width: 100%;
    width: 100%;
    @include min-width(1313px) {
        display: table;
    }
}

.licensesTable {
    overflow-x: visible !important;
    border-collapse: separate !important;
    height: 100%;
    margin: 60px auto 0;
    max-width: 1260px;
}

.table__header__container {
    overflow-x: visible;
    position: sticky;
    top: 0;
    z-index: 10;
    &::before {
        content: '';
        width: 100%;
        position: absolute;
        height: 152px;
        left: 0px;
        top: -1px;
        box-shadow: 0px 2px 0px 0 var(--neutral300);
        z-index: -1;
        background-color: var(--neutral100);
    }
}

.table__header {
    margin-left: auto;
    & > th {
        max-height: 143px;
    }
    &__cell {
        height: 143px;
        padding: 15px !important;
        text-align: center;
        line-height: 16px;
        width: 175px;
        border: 2px solid var(--neutral300);
        border-right: 2px solid var(--neutral300);

        &__content {
            @include inline-flex();
            @include flex-direction(column);
            width: 100%;
            vertical-align: top;
            text-align: left;

            .license__header {
                width: 100%;
                @include flexbox();
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                width: 190px;
                align-items: center;
                & > .licenseName {
                    width: 100%;
                    width: calc(100% - 78px);
                    color: var(--neutral1000);
                }
                &__popularTag {
                    background-color: var(--secondary600);
                    border-radius: 5px;
                    padding: 3px 8px;
                    width: fit-content;
                    margin-left: auto;
                    height: 18px;
                    & > p {
                        color: var(--neutral100);
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
            }

            & > div {
                width: 100%;
                & > .licensePrice {
                    margin: 34px 0 0;
                    font-weight: 400;
                    line-height: 18px;
                    font-size: 16px;
                    width: 100%;
                    color: var(--neutral700);
                    & > span {
                        font-size: 25px;
                        font-weight: bold;
                        color: var(--neutral1000);
                    }
                }

                & > .licensePriceLarge {
                    margin: 34px 0 0;
                    color: var(--neutral1000);
                }

                & > .licenseCustomPrice {
                    margin: 34px 0 0;
                    line-height: 16px;
                    margin-bottom: 0;
                    color: var(--neutral1000);
                }

                & > .licenseSave {
                    font-size: 12px;
                    margin-bottom: 15px;
                    height: 14px;
                }

                & > .licenseAdapted {
                    font-size: 12px;
                    margin-bottom: 20px;
                }
                & > button {
                    margin-top: auto;
                    width: 100%;
                    text-align: center;
                    @include flexbox();
                    @include justify-content(center);
                    cursor: pointer;
                }
            }

            & > div:nth-child(2) {
                height: 90px;
                @include flexbox();
                @include flex-direction(column);
                @include justify-content(space-between);
            }
        }
    }
    &:first-child {
        & > .table__header__cell:nth-child(2) {
            -moz-border-top-left-radius: 4px;
            -webkit-border-top-left-radius: 4px;
            border-top-left-radius: 4px;
            width: 50%;
        }
        & > .table__header__cell:last-child {
            -moz-border-top-right-radius: 12px;
            -webkit-border-top-right-radius: 12px;
            border-top-right-radius: 12px;
            width: 50%;
        }
        & > .table__header__cell:nth-child(4) {
            & > .table__header__cell__content {
                & > div:nth-child(2) {
                    height: 90px;
                    & > .licensePrice {
                        margin: 34px 0 0;
                        font-weight: 400;
                        line-height: 18px;
                        font-size: 16px;
                        width: 100%;

                        & > span {
                            font-size: 25px;
                            font-weight: bold;
                        }
                    }
                    & > .licensePriceLarge {
                        margin: 34px 0 0;
                    }
                }
            }
        }
    }

    & > th {
        border-bottom: none !important;
    }
}

.table__body {
    border-top-left-radius: 12px;
    overflow: hidden;
    margin-top: -5px;
    & > tr:nth-child(19) {
        & > td:nth-child(1) {
            border-bottom-left-radius: 12px;
        }
    }
    &__row:nth-child(1) {
        height: 60px;
        width: 30px;
    }

    &__row:nth-child(1) {
        & > td:nth-child(1) {
            height: 36px;
        }
    }
    &__row {
        background-color: var(--neutral100);
        & > td {
            vertical-align: middle;
            border: 1px solid var(--neutral300);
            padding: 12px;
            line-height: 16px;
            & > span,
            p {
                color: var(--neutral1000);
            }
            & > span > p {
                width: auto !important;
            }
        }
        .table__body__row__cell__icon {
            width: 16px;
            height: 16px;
        }
        &:last-child {
            &:last-child {
                & > td {
                    &:last-child {
                        -moz-border-bottom-right-radius: 12px;
                        -webkit-border-bottom-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                }
            }
        }
    }
}

.license__button {
    font-size: 15px;
    padding: 14px 6.5px;
}

.paragraphCell {
    text-transform: capitalize;
}
