@import '../../../../styles/media-mixins';

.formLightPanel {
    margin-top: 32px;
    padding: 32px;
    & > div {
        width: 100%;
    }
}

