@import '../../../../../../../../../styles/media-mixins';

.itemIconContainer {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap-reverse);
    background-color: var(--neutral100);
    padding: 12px;
    color: var(--neutral1000);
    cursor: pointer;
}

.itemIconHalf {
    @include flexbox();
    @include flex-direction(column);
    max-width: 90%;
}

.mainText {
    font-family: var(--base);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    color: var(--neutral1000);
}

.defaultCursor {
    cursor: default !important;
}