@import '../../../../../../styles/media-mixins';

.infoPanelWithAction {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px 0 10px;
    padding: 12px;
    border-radius: 8px;
    background-color: var(--info100);
    border: 2px solid var(--info200);
    color: var(--neutral1000);

    &__texts {
        & > p:nth-of-type(1) {
            width: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            color: var(--neutral1000);
            font-weight: bold;
            margin-left: 0 !important;
            & > svg {
                margin-right: 10px;
                width: 20px;
                & > path {
                    stroke: rgba(0, 129, 241, 1);
                }
            }

            & > span {
                width: calc(100% - 32px);
            }
        }

        & > p {
            margin-left: 30px;
            margin-bottom: 0;
        }

        & > p:nth-of-type(3) {
            margin-top: 10px;
        }
    }

    & > button {
        margin-top: 10px;
        margin-left: 30px;
        height: 32px;
        background-color: rgba(0, 129, 241, 1);
        margin-right: auto;
        color: white;

        &:hover {
            border: 1px solid rgba(0, 129, 241, 1);
        }
    }
}
