@import '../../../../../../../styles/media-mixins';

.appIntegrationURLS {
    @include flexbox();
    @include align-items(center);
    gap: 4px;

    & > span {
        max-width: calc(250.5px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;    
        min-width: 0;
    }

    & > div {
        background-color: var(--neutral100);
        border: 1px solid var(--neutral400);
        padding: 2px 4px;
        border-radius: 10px;

        & > div {
            @include flexbox();
            @include align-items(flex-start);
            @include justify-content(space-between);
            gap: 8px;
        }
    }

    & > .moreUrls {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        background-color: var(--neutral200);
        padding: 2px 4px;
        border-radius: 10px;
        text-align: center;
        border: none;
        min-width: 27px;
        font-family: "Work Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: auto;
    }
}
