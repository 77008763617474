@import '../../../../../styles/media-mixins';

.datesFilter {
    display: block;
    min-width: 180px;

    select {
        line-height: 16px !important;
        margin-top: 0 !important;
        letter-spacing: 0 !important;
        padding: 8px 8px 8px 12px !important;
        height: 40px !important;
    }
}

.datesFilterContainer {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    border: 1px solid var(--neutral400);
    min-width: 180px;
    padding: 8px 12px;
    border-radius: 8px;
    height: 34px;
    cursor: pointer;
}