@import "../../../../../../../../styles/media-mixins";

.storesPanel {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    border-radius: 5.23px;
    padding: 8.72px;
    gap: 8.72px;
    background-color: var(--neutral200);

    @include min-width($tabletSM) {
        @include flex-wrap(nowrap);
    }

    &>img {
        width: 21.01px;
        height: auto
    }

    &__texts {
        @include flexbox();
        @include flex(1);
        @include flex-direction(column);
        gap: 1.74px;
        min-width: 100px;

        &>p:nth-of-type(1) {
            font-family: "Ubuntu";
            font-size: 8.72px;
            font-weight: 700;
            line-height: 10.46px;
        }

        &>p:nth-of-type(2) {
            font-family: "Poppins";
            font-size: 6.1px;
            font-weight: 400;
            line-height: 6.97px;
        }
    }

    &__links {
        @include flexbox();
        @include align-items(center);
        gap: 6.97px;

        &>img {
            &:nth-of-type(1) {
                width: 73.66px;
                height: 21.79px;
            }

            &:nth-of-type(2) {
                width: 65.38px;
                height: 21.79px;
            }
        }
    }
}


.mobileStoresPanel {
    @include justify-content(flex-start);
    @include flex-wrap(wrap);
    gap: 11.77px;
    border-radius: 7.06px;
    padding: 11.77px;

    &>img {
            width: 28.59px;
    }
    

    & > .storesPanel__texts {
        gap: 2.35px;

        &>p:nth-of-type(1) {
                font-family: "Ubuntu";
                font-size: 11.77px;
                font-weight: 700;
                line-height: 14.13px;
            }
        
            &>p:nth-of-type(2) {
                font-family: "Poppins";
                font-size: 8.24px;
                font-weight: 400;
                line-height: 9.42px;
            }
    }


    & > .storesPanel__links {
        @include flex-wrap(wrap);

        & > img {
            max-width: none;

            &:nth-of-type(1) {
                width: 99.47px;
                height: 29.43px;
            }

            &:nth-of-type(2) {
                width: 88.28px;
                height: 29.43px;
            }
        }
    }
}