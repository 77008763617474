@import "../../../../styles/media-mixins";

.credentialPreviewContainer {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-start);
    @include align-items(center);
    @include min-width($mobileXL) {
        min-width: 461px;
    }
    .credentialPreview {
        width: 100%;
        @include min-width-height(1440px, 640px)  {
            position: fixed;
            width: auto;
        }
        @include min-width(1320px) {
            max-width: 461px;
        }
        & > div {
            box-shadow: none;
            gap: 32px;
            height: calc(100vh - 264px);
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(space-between);
            padding: 0;
            @include min-width(1320px) {
                height: calc(100vh - calc(75px + 20px + 13px)); //PanelScafoldingPrivate padding top and marginTop, section marginTop;
                padding: 24px;
                min-height: 842px;
            }
            @include min-width(1440px) {
                height: calc(100vh - calc(75px + 20px + 13px + 32px)); //PanelScafoldingPrivate padding top and marginTop, section marginTop & marginBottom;
                margin-bottom: 32px;
            }
            @include min-width-height(1440px, 640px) {
                min-height: auto;
            }
        }
        &__header {
            & > div > div  {
                &:nth-child(3) {
                    margin-top: 0;
                    & > div {
                        margin-top: 0;
                    }
                }
            }
        }
        &__body {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            @include flex(1 0 auto);
            @include min-width(1320px) {
                @include align-items(center);
            }
            & > p:nth-of-type(1) {
                width: 100%;
                text-align: left;
            }
        }
        &__infoAlert {
            position: fixed;
            bottom: 24px;
            left: 24px;
            width: calc(100% - 48px);
            margin: 0;
            @include min-width(1320px) {
                position: unset;
                bottom: 0;
                left: 0;
                width: 100%;
                margin: 10px 0;
            }
        }
        &__footer {
            width: 100%;
            > button {
                width: 100%;
                @include flexbox();
                @include flex-direction(row);
                @include justify-content(center);
                padding: 24px 12px;
                height: 48px;
            }

        }
    }
}

.scrollableArea {
    overflow-y: auto;
    height: 100%;
    vertical-align: initial;
    padding-right: 2px;
    width: 100%;
    @include flexbox;
    @include flex-direction(column);
    padding-top: 15px;
    padding-bottom: 15px;
    @include min-width(1320px) {
        padding-top: 0;
        padding-bottom: 0;
    }
    &.withInfoAlert {
        height: calc(100% - 102px); // Info alert height + 32px gap
        @include min-width($mobileXL) {
            height: calc(100% - 78px);
        }
        @include min-width(1320px) {
            height: 100%;
        }
    }
}

.scrollableArea::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollableArea::-webkit-scrollbar:vertical {
    width: 11px;
}

.scrollableArea::-webkit-scrollbar:horizontal {
    height: 20px;
}

.scrollableArea::-webkit-scrollbar-thumb {
    border: 2px solid var(--neutral100);
    /* should match background, can't be transparent */
    border-radius: 8px;
    background-color: var(--neutral300);
}

.credConstraints {
    @include flexbox();
    @include flex-direction(column);
    gap: 20px;
    width: 100%;
    min-height: 100%;
}

.requirementsPreview {
    min-height: 100%;
    width: 100%;
    & > .credRequirements  {
        @include flexbox();
        @include flex-direction(column);
        gap: 20px;
    }
}

.noReqOrOptCredentials {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    padding: 24px;
    gap: 24px;
    border-radius: 12px;
    border: 1px dashed var(--neutral400);
    & > img {
        max-width: 80px;
    }
}

.credDetail {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 100%;
    max-width: 260px;
    margin: 3px auto;
    cursor: default;
    @include min-width($mobileMD) {
        max-width: 343px;
    }

    & > div:nth-of-type(1) {
        cursor: default;
    }

    & > .credCard {
        min-width: 0 !important;
    } 

    & > .content {
        & > .header {
            @include flexbox();
            @include align-items(center);
            @include justify-content(space-between);
            gap: 12px;
            padding: 12px;
            border-top: 1px solid var(--gray300);
            border-radius: 0px 0px 8px 8px;
            cursor: pointer;
        }

        & > div:nth-of-type(2) > div:not(:last-child) {
            border-bottom: 1px solid var(--neutral300);
        }
    }
}

.credentialsSkeleton {
    min-height: 100%;
}
.previewModalResponsive {
    & > div > div {
        padding: 24px;
        @include flexbox();
        @include flex-direction(column);
        gap: 32px;
        height: calc(100vh - 100px);
        @include min-width($tabletSM) {
            max-width: 608px;
            width: 600px;
        }
        & > div:nth-child(2) {
            margin-top: 0;
            max-height: 100%;
            overflow-y: hidden;
        }
        
    }
}
