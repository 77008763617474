@import "../../styles/media-mixins";

.paginator {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    gap: 20px;
}

.pagination__container {
    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);
    gap: 8px;
    margin-left: auto;
    list-style-type: none;

    .pagination__item {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        background-color: transparent;
        min-width: 30px;
        text-align: center;
        box-sizing: border-box;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: var(--neutral700);
        padding: 4px 12px;
        border: 1px solid transparent;

        &:hover {
            color: var(--primary1000);
            cursor: pointer;
        }

        &.selected {
            color: var(--primary700);
            font-weight: 700;
            border-radius: 4px;
            border: 1px solid var(--primary700);
        }

        &.disabled {
            pointer-events: none;
            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }

         &.dots {
            font-size: 20px;
            padding: 4px 10px;
            &:hover {
                background-color: transparent;
                color: var(--neutral1000);
            }
         }
    }

    .arrow {
        min-width: 0;
        padding: 0;
    }
}
