@import '../../../../styles/media-mixins';

.formLightPanel {
    margin-top: 20px;
    padding: 30px;

    .form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: flex-end;
        cursor: pointer;

        @include min-width($desktopSM) {
            flex-direction: row;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
        }

        &__textInputs {
            width: 100%;
            @include min-width($desktopSM) {
                // width: calc(100% - 250px);
                min-width: 200px;
            }
        }

        &__imageInput {
            margin: 0 auto;
            margin-bottom: 20px;
            border-radius: 100%;
            position: relative;
            width: 185px;
            height: 185px;
            background-color: #dadbec;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                box-shadow: 0 3px 10px var(--boxShadowLigh);
            }

            @include min-width($desktopSM) {
                margin-bottom: none;
                margin-right: 0;
            }

            & > img {
                border-radius: 100%;
                width: 100%;
                height: 100%;
            }

            & > input {
                display: none;
                border-radius: 15px;
                border: 1px #000 solid;
            }

            &__empty {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100px;
                text-align: center;

                & > img {
                    margin-bottom: 10px;
                }
            }
        }

        &__imageInputFilled {
            &::after {
                content: 'Edit';
                display: none;
                position: absolute;
                font-weight: bold;
                z-index: 9999;
                width: 54px;
                height: 36px;
                top: 0;
                right: 0;
                color: #fff;
                font-size: 10px;
                line-height: 36px;
                text-align: center;
                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &::after {
                    display: block;
                }
            }
        }
    }
}
