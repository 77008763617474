@import "../../../../../../../styles/media-mixins";

.periodToggle {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    padding-bottom: 20px;
    width: 100%;
    & > span {
        color: var(--neutral1000);
        margin-bottom: 2px;
    }
}

.periodCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.periodCheckboxLabel {
    cursor: pointer;
    text-indent: 49px;
    width: 44px;
    height: 22px;
    background: white;
    border: 2px solid var(--primary700);
    display: block;
    border-radius: 90px;
    position: relative;
    margin-right: 10px;
}

.periodCheckboxLabel:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid var(--primary700);
    border-radius: 90px;
    transition: 0.3s;
}

.periodCheckbox:checked + .periodCheckboxLabel {
    background: var(--primary700);
}

.periodCheckbox:checked + .periodCheckboxLabel:after {
    width: 18px;
    left: calc(100% - 18px);
}

.periodCheckboxLabel:active:after {
    width: 22px;
}

.tableContainer {
  display: block;
  @include min-width(1313px) {
    display: none;
  }

  & > .header__container {
    & > div:nth-child(2) {
      & > p {
        margin-bottom: 24px;
        color: var(--neutral1000);
      }
    }
  }
}

.card {
  margin-top: 32px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--neutral300);
  overflow: hidden;
  background-color: var(--neutral100);
  color: var(--neutral1000);

  & > .card__header {
    padding: 12px 20px;
    border-bottom: 1px solid var(--neutral300);
  }

  & > .card__row {
    @include flexbox();
    color: var(--neutral1000);
    
    & > div:nth-child(1) {
      padding: 12px;
      border-right: 1px solid var(--neutral300);
      min-width: 140px;
      width: 140px;
      color: var(--neutral1000);
      @include min-width($mobileXL) {
        width: 178px;
      }

      & > div {
        @include flexbox();
        @include flex-wrap(nowrap);
        @include align-items(space-between);
        @include justify-content(space-between);
        & > p {
          margin-right: 10px;
          color: var(--neutral700);
        }
      }
    }

    & > div:nth-child(2) {
      padding: 12px;
    }
  }

  & > .card__row:not(:last-child) {
    border-bottom: 1px solid var(--neutral300);
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.header__container {
  margin-top: 40px;
  @include flexbox();
  @include justify-content(space-between);
  & > div:nth-child(1) {
    width: 100%;
    @include flexbox();
    @include flex-direction(column);
  }
}
.license__button {
  margin-top: 24px;
  width: 100%;
}

.popularTag {
  background-color: var(--secondary600);
  border-radius: 5px;
  padding: 3px 8px;
  width: fit-content;
  margin-left: auto;
  height: 18px;
  & > p {
    color: var(--neutral100);
    font-weight: 400;
    font-size: 12px;
  }
}
.selector {
  width: 100%;
  padding: 8px 12px;
  height: 40px;
  border: 1px solid var(--neutral400);
  border-radius: 8px;
  background-color: var(--neutral100);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

