@import '../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 95vw;
    max-width: 480px;
    height: 95vh;
    max-height: 667px;
    border-radius: 20px;
    background: var(--neutral100);
    transform: translate(-50%, -50%);
    overflow: hidden;
    @include flexbox();
    @include flex-direction(column);

    @include min-width($tabletSM) {
        width: 80vw;
        height: 80vh;
    }
}

.modal__header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    min-height: 64px;
    padding: 8px 4px 8px 6px;
    background-color: var(--neutral100);
    border-bottom: 1px solid var(--neutral300);

    & > p {
        margin: auto;
    }
    & > div:nth-child(2) {
        width: 90%;
    }

     &__close {
        cursor: pointer;
        &:hover {
            & > svg > path {
                stroke: var(--primary700);
            }
        }
    }

    &__delete {
        height: 24px;
        min-width: 32px;
        &:hover {
                    
            & > svg {
                cursor: pointer;
                & > path {
                
                stroke: var(--alert400);
            }
            }
        }
    }
}

.modal__content {
    @include flexbox();
    @include flex-direction(column);
    flex: 1;
    gap: 32px;
    padding: 24px 20px 0px;
    overflow-y: auto;
    width: 100%;
}

.modal__footer {
    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);
    @include flex-direction(column);
    @include justify-content(space-between);
    gap: 12px 20px;
    padding: 20px;
    width: 100%;
    border-top: 1px solid var(--neutral300);
    
    background-color: var(--neutral100);

    @include min-width($mobileMD) {
        @include flex-direction(row);
    }

    & > button {
        @include flex(1 0 0);
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        padding: 12px 24px;
        height: 44px;
        width: 100%;

        @include min-width($mobileMD) {
            @include flex-direction(row);
            width: auto;
        }
    }
}

.visibleModal {
    display: block;
}

.hiddenModal {
    display: none;
}

.tabCursorPointer {
    & > div > ul > li {
        cursor: auto !important;
    }
}
