@import '../../../../../styles/media-mixins';

.license {
    border: 1px solid #e3e3e3;
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 20px 15px;
    position: relative;

    &__tag {
        display: block;
        position: absolute;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;
        top: -12px;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: auto;
        left: 75px;
        background-color: var(--neutral200);

        & > p {
            font-size: 12px;
            font-weight: bold;
        }
    }

    &__tag__newLicense {
        background-color: white;
    }

    @include min-width($desktopSM) {
        justify-content: flex-start;
        margin-right: 20px;
    }

    @media (min-width: 1080px) {
        width: 23%;
        min-width: 272px;
    }

    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &__title {
            font-weight: bold !important;
            width: calc(100% - 70px);
        }
        &__popularTag {
            background-color: var(--secondary600);
            border-radius: 5px;
            padding: 3px 8px;
            width: fit-content;
            margin-left: auto;
            height: 25px;
            & > p {
                color: white;
                font-size: 12px;
            }
        }
    }

    &__description {
        margin-top: 5px;
        margin-bottom: 15px;
        font-size: 14px;
        min-height: 54px;
    }

    &__price {
        margin-top: auto;
        padding-top: 10px;
        & > span {
            font-size: 40px;
            font-weight: bold;
        }
    }

    &__customPrice {
        font-size: 29px;
        font-weight: bold;
        margin-bottom: 1px;
    }

    &__customPriceLarge {
        margin-bottom: 18px;
    }
    &__save {
        font-size: 12px;
    }

    &__features {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #e3e3e3;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
            min-width: 232px;
        }
        &__title {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &__list {
            font-size: 13px;

            &__item {
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                align-items: center;
                &__content {
                    @include flexbox();
                    @include flex-direction(row);
                    @include flex-wrap(wrap);
                    @include align-items(center);
                    gap: 8px;
                    margin-left: 9px;

                    & > .featureName {
                        width: fit-content;
                    }
                }

                & > .featureName {
                    width: fit-content;
                }

                & > div > div > .featureName {
                    width: fit-content;
                }
            }
        }
    }

    &__button {
        height: 48px;
        margin-top: auto;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
