@import '../../../../../../../styles/media-mixins';

.info {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid var(--neutral400);
    border-bottom: 0;
    overflow: hidden;
    overflow-y: scroll;

    &__detail {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-wrap(wrap);
        gap: 12px;
        padding: 16px 12px;
        border-left: 1px solid var(--neutral400);
        border-right: 1px solid var(--neutral400);
        &:not(:last-child) {
            border-bottom: 1px solid var(--neutral300);
        }
        &:last-child{
            border-bottom: 1px solid var(--neutral400);
        }
        &:last-child{
            border-bottom: 1px solid var(--neutral400);
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        & > p:last-child {
            text-align: left;
            margin-left: auto;
        }

       .issuerStatus {
            @include flexbox();
            @include align-items(center);
            @include justify-content(flex-end);
            gap: 4px;

            & > div > div > div > div  {
                transform: translateX(-90%) !important;
                &::before {
                    right: 10px !important;
                }
            }
        }

        .status {
          & > div > div > div > div > div {
             transform: translateX(-90%) !important;
                &::before {
                    right: 10px !important;
                }
            }
        }
    }
}
