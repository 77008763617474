@import "../../../../styles/media-mixins";

.copyButton {
    position: absolute;
    right: 12px;
    top: 32px;
    .copyButton__button {
        &:before {
            content: '';
            display: none;
            position: absolute;
            z-index: 9998;
            right: 0;
            width: 0;
            height: 0;
        }
        &:after {
            content: 'Copy to Clipboard';
            display: none;
            position: absolute;
            font-weight: bold;
            z-index: 9999;
            right: 0;
            top: -35px;
            width: 114px;
            height: 30px;
            color: var(--neutral100);
            font-size: 10px;
            line-height: 30px;
            text-align: center;
            background: rgba(0, 0, 0, 0.72);
            border-radius: 3px;
        }

        &:hover {
            &:before,
            &:after {
                display: block;
            }
        }
        &:active,
        &:focus {
            outline: none;
            &:after {
                content: 'Copied!';
            }
        }

        & > svg {
            cursor: pointer;
            height: 24px;
            &:hover {
                & > path {
                    stroke: var(--primary700);
                }
            }
        }
    }
}