@import "../../../../styles/media-mixins";

.linkButtonIcon {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(row);
    gap: 4px;
    border: none;
    background-color: transparent;
    padding: 0;
    &:hover {
        opacity: 0.8;
    }
    span {
        &:hover {
            opacity: 0.8;
        }
    }
}





