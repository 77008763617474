@import '../../../../../../styles/media-mixins';

.backgroundLayer {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    cursor: auto;

    @media (min-width: 775px) {
        left: 250px;
        max-width: calc(100vw - 250px);
    }
}

.modal {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 0;
    max-width: 1080px;
    padding: 20px 30px;
    border-radius: 20px;
    background: white;
    transform: translate(calc(44vw - 150px), -50%);
    // height: 300px;
    width: 350px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 13px #0000002e;;

    @media (min-width: 775px) {
        padding: 30px;
        left: 27%;
        transform: translate(-0%, -50%);
    }

    @include min-width($tabletSM) {
        width: 568px;
    }

    & > img {
        width: 120px;
        margin-bottom: 15px;
    }

    &__title {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        padding: 0 20px;
        color: #1e1e20;
        font-size: 24px;
    }

    &__description {
        text-align: center;
        padding: 0 20px;
        font-size: 16px;
        color: #707074;
    }

    & > div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > button {
            display: flex;
            justify-content: center;
            cursor: pointer;
            width: calc(50% - 6px);
        }
    }

    &__delete {
        background-color: #dc3d43;
        border: none;
        color: white !important;

        &:hover {
            background-color: #dc3d43;
            border: none;
            color: white;
        }
    }

    &__close {
        z-index: 9999;
        z-index: 9999;
        position: absolute;
        right: 0;
        padding: 20px;
        cursor: pointer;

        &:hover {
            & > svg > g > path {
                stroke: red;
            }
        }
    }
}

.errorFilledPanel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px;
    margin-top: 40px;
    background-color: #ffdedf;
    color: var(--neutral1000);
    border: 1px solid #ee888c;
    border-radius: 12px;

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        & > img {
            width: 20px;
            margin-right: 10px;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        width: 100%;

        & > img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }

        &__texts {
            .title {
                color: #1e1e20;
                font-weight: bold;
                margin-bottom: 5px;
            }

            & > p {
                line-height: 1.5;
                color: #1e1e20;
                margin-right: auto;

                & > a {
                    color: #dc3d43;
                }
            }
        }
    }

    .issuancesLink {
        cursor: pointer;
        color: #dc3d43;
        width: fit-content;

        &:hover {
            text-decoration: underline;
        }
    }
}

.templateList {
    width: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    max-height: 192px;
    overflow-y: scroll;

    & > div {
        width: 100%;
        padding: 16px 12px;
        min-height: 48px;
    }

    & > div:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
    }
}
