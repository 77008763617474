@import "../../../../../../styles/media-mixins";

.panelContainer {
  background-color: transparent;
  box-shadow: none;
  padding: 0;

  @include min-width($mobileXL) {
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.table {
  overflow-x: scroll;
  overflow-y:initial;
  padding: 5px 5px 10px;
  border-radius: 10px;
  box-shadow: none;
  &::-webkit-scrollbar-thumb {
    border: 2px solid white; /* should match background, can't be transparent */
    border-radius: 8px;
    background-color: var(--neutral300);
  } 

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  @include min-width($mobileXL) {
    padding: 0;
  }
  perspective: 1px;

  & > table {
    width: 100%;

    & > thead {
      display: none;
      height: 2rem;

      @include min-width($mobileXL) {
        display: table-header-group;
      }

      & > tr > th {
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;
        color: var(--neutral1000);
        cursor: default;
        font-size: 14px;
        font-weight: 600;

        @include min-width($desktopSM) {
          font-size: 14px;
        }

        & > div {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          & > img {
            height: 100%;
          }
          & > span:not(:last-child) {
            margin-left: 5px;
          }
        }

        & > span > svg {
          opacity: 1;
        }
      }
    }

    & > tbody {
      & > tr {
        height: 3rem;
        min-height: 50px;
        border-bottom: 1px solid #e0e0e0;

        & > td {
          font-size: 14px;
          max-width: 400px;
          min-height: 50px;
          padding: 10px;
          border-bottom: 1px solid #e0e0e0;
          color: var(--neutral1000);

          @include min-width($desktopSM) {
            max-width: 420px;
          }
          overflow-wrap: break-word;
          text-overflow: ellipsis;
          word-wrap: break-word;

          @media (min-width: 775px) and (max-width: 925px) {
            max-width: 250px;
          }

          & > .checkbox {
            justify-content: start;
            padding: 0;
            margin: 0;
            border-bottom: none !important;
            cursor: pointer;
            & > input {
              margin: 0;
            }
          }

          & > .detailLink {
            padding: 15px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }

          & > .modal__content > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            & > .modalAction {
              width: 15px;
              height: 15px;
              padding: 2px;
              border-radius: 10px;
              margin-left: 5px;
              &:hover {
                padding: 1px;
                border: 1px solid var(--neutral1000);
              }
              &__icon {
                width: 10px;
              }
            }
          }
          & > .action__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
          }

          & > .status__content {
            display: flex;
            flex-direction: row;
            align-items: center;

            & > div {
              margin: 0 !important;
              padding: 5px 5px;
              border: none;
              border-radius: 10px;
            }
            & > span {
              margin-left: 10px;
            }
          }
        }
      }

      & > .table__mobileDisplay {
        height: auto;
      }
    }
  }

  &__mobileDisplay {
    display: block;

    @include min-width($mobileXL) {
      display: none;
    }
  }

  &__mobileDisplay:not(:last-child) {
    margin-bottom: 10px;
  }

  &__longDisplay {
    display: none;

    @include min-width($mobileXL) {
      display: table-row;
    }
  }
}
.actions {
  & > div:nth-of-type(1) {
    max-width: 150px;
  }
}

.disabledPagination {
  pointer-events: none;
  opacity: 0.2;
  & > div {
    cursor: not-allowed !important;
  }
}

.bin {
  width: 20px;
  padding-top: 5px;
  cursor: pointer;
  height: 24px;
  &:hover {
    & > path {
      fill: none;
      stroke: red;
    }
  }
}