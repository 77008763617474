@import "../../../../styles/media-mixins";
$skeleton-01: #e5e5e5;
$skeleton-02: #c6c6c6d1;

.cardListSkeleton {
    overflow: hidden;
    height: 90px;
    margin: 10px 0 15px;
    border-radius: 10px;
    background: var(--skeletonLightBG);
}

@keyframes skeleton {
    0% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }

    20% {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
    }

    28% {
        transform: scaleX(1);
        transform-origin: right;
    }

    51% {
        transform: scaleX(0);
        transform-origin: right;
    }

    58% {
        transform: scaleX(0);
        transform-origin: right;
    }

    82% {
        transform: scaleX(1);
        transform-origin: right;
    }

    83% {
        transform: scaleX(1);
        transform-origin: left;
    }

    96% {
        transform: scaleX(0);
        transform-origin: left;
    }

    100% {
        opacity: 0.3;
        transform: scaleX(0);
        transform-origin: left;
    }
}

@keyframes skeleton-animation {
    0% {
        right: 100%;
    }

    50% {
        right: -128px;
    }

    100% {
        right: 100%;
    }
}

.skeleton {
    position: relative;
    display: grid;
    overflow: hidden;
    width: 100%;
    height: 78px;
    grid-gap: 8px;
    grid-template-columns: 128px 200px;
}

.skeleton:before {
    position: absolute;
        display: block;
        width: 128px;
        height: 100%;
        content: "";
        animation: skeleton-animation 2s infinite;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
        background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
        background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
}
