@import "./_reset";
@import "./_media-mixins";
@import "./_colors";
@import "./_fonts";
@import "./_spacing";


:global {
    // Grid
    .rowLayout {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        @include min-width($tabletSM) {
            flex-direction: row;
        }
    }

    .widthFitContent {
        width: fit-content;
    }

    .width100 {
        width: 100%;
    }

    .containerMaxWidth {
        max-width: 1184px;
        margin-right: auto;
        margin-left: auto;
    }

    // border
    .borderBottom1000 {
        border-bottom: 1px solid var(--neutral1000);
    }

    .borderBottom300 {
        border-bottom: 1px solid var(--neutral300);
    }
}