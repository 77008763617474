@import '../../../../../styles/media-mixins';

.formLightPanel {
    margin-top: 32px;
    padding: 32px;

    .form {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;

        @include min-width($desktopSM) {
            flex-wrap: wrap-reverse;
            justify-content: space-between;
        }
        .headerContainer {
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            @include flex-wrap(nowrap);
            width: 100%;
            .closeFormIcon {
                cursor: pointer;
                &:hover {
                    color: var(--alert300);
                }
            }
        }

        &__textInputs {
            width: 100%;
            @include min-width($desktopSM) {
                // width: calc(100% - 250px);
                min-width: 200px;
            }
            &__doubleColumn {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 20px;
                & > div {
                    width: 48%;
                }

                & > div:nth-child(3) {
                    margin-top: 15px;
                }

                & > div:nth-child(4) {
                    margin-top: 15px;
                }
            }
        }

        &__imageInput {
            margin-right: auto;
            margin-bottom: 20px;
            border-radius: 10px;
            position: relative;
            width: 185px;
            height: 185px;
            background-color: #dadbec;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                box-shadow: 0 3px 10px var(--boxShadowLigh);
            }

            @include min-width($desktopSM) {
                margin-bottom: none;
                margin-right: 0;
            }

            & > img {
                border-radius: 10px;
                width: 100%;
                height: 100%;
            }

            & > input {
                display: none;
                border-radius: 15px;
                border: 1px #000 solid;
            }

            &__empty {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100px;
                text-align: center;

                & > img {
                    margin-bottom: 10px;
                }
            }
        }

        &__imageInputFilled {
            &::after {
                content: 'Edit';
                display: none;
                position: absolute;
                font-weight: bold;
                z-index: 9999;
                width: 54px;
                height: 36px;
                top: 0;
                right: 0;
                color: #fff;
                font-size: 10px;
                line-height: 36px;
                text-align: center;
                background: rgba(0, 0, 0, 0.72);
                border-radius: 3px;
            }

            &:hover {
                &::after {
                    display: block;
                }
            }
        }
    }
    .formButtons {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(flex-end);
        @include align-items(center);
        width: 100%;
        gap: 12px;
        margin-top: 20px;
        button:nth-child(1) {
            border-color: var(--neutral500);
        }
    }
}

.warningPanelContainer {
    margin-left: 0px !important; 
    & > div > div > button {
        width: 190px !important;
    }
}