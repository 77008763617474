@import '../../../../../../../../../styles/media-mixins';

.card__row {
    @include flexbox();
    & > div:nth-child(1) {
        width: 140px;
        @include min-width($mobileXL) {
            width: 178px;
        }

        & > div {
            @include flexbox();
            @include flex-wrap(nowrap);
            @include align-items(space-between);
            @include justify-content(space-between);
            & > p {
                margin-right: 10px;
                width: calc(100% - 10px);
                color: var(--neutral1000);
                word-break: break-word;
            }

            & > div {
                & > p {
                    color: var(--neutral1000);
                    word-break: break-word;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                }

                & > div > div {
                    max-width: 162px;
                    font-size: 10px !important;
                }
            }
        }
    }
}
