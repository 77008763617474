@import '../../../../styles/media-mixins';

.requestSubscription {
    max-width: none !important;
    & > div > div {
        padding: 32px;
        padding-right: 17px;
        width: 568px;
        max-height: 90vh;
        & > div:nth-child(2) {
            min-height: 430px;
            max-height: 50vh;
        }
    }
}

.terms {
    margin-top: 12px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    & > input {
        margin: 0 6px 0 0;
        cursor: pointer;
    }

    & > .checkbox__text {
        display: inline-block;
        margin: 0 5px 0 0;
        vertical-align: baseline;
    }

    & > a {
        margin: 0rem 5px 0 0;
        text-decoration: none; 
        &:hover {
            text-decoration: underline;
        }
    }

    .required {
         &::after {
            color: red;
            content: ' *';
        }
    }
}

.doubleColumn {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    & > div {
        flex: 1;
        min-width: 212px;
    }

    & > div:nth-child(3) {
        margin-top: 15px;
    }

    & > div:nth-child(4) {
        margin-top: 15px;
    }
}

.requestSubscription__content {
    padding-right: 15px;
    // min-height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @include min-width($mobileXL) {
        margin-top: 0;
    }

    & > .buttonsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-top: 32px;
        background-color: white;
        gap: 15px;
    }
}

 .success {
    & > div > div {
        padding: 32px;
        padding-right: 17px;
        width: 568px;
        max-height: 90vh;
        & > div:nth-child(2) {
            margin-top: 0;
            height: 498px;
            max-height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            gap: 40px;
             
        }
        & > div:nth-child(3) {
            justify-content: center;

            & > button {
                width: 100%;
                display: block;
                text-align: center;
                padding-bottom: 15px;
                padding-top: 10px;
            }
        }
    }
}
