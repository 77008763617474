@import '../../../../styles/media-mixins';

.overSelectTag {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 26px;
  display: flex;
  justify-content: center;

  & > div {
    cursor: pointer;
    & >  div {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    }
  }
  & > svg {
    position: absolute;
    top: 6px;
    right: 0rem;
    overflow-clip-margin: content-box;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;

    path {
      stroke: var(--neutral1000);
    }
  }

  &:hover {
    svg {
      border: 1px solid var(--neutral1000);
    }
  }


  & > select {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
    background-image: none;
    outline: none;
  }
}

.checkboxes {
  background-color: var(--neutral100);
  border-radius: 5px;

  & > ul {
    padding: 5px;
    margin: 0;
    background-color: var(--neutral100);
    box-shadow: 0 5px 13px var(--boxShadowLigh);
    border-radius: 5px;
    & > input {
      border-right: none;
    }
    & > p {
      font-size: 14px;
      font-family: var(--base);
    }

    & > div {
      cursor: pointer;
    }
  }

  & > .searchBar > input {
    width: 100%;
    border: none;
    margin-left: 15px;
  }

  & > .list {
    max-height: 150px;
    overflow-y: scroll;
    padding: 0 10px 10px 10px;
    width: 152px;
    width: 152px;
    right: 14px;
    position: absolute;
    top: 31px;
    z-index: 999999;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      padding-top: 50px;
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      padding-top: 50px;
      border: 2px solid white;
      border-radius: 8px;
      background-color: var(--neutral300);
    }
  }
}

.backgroundLayerActive {
  position: fixed;
  z-index: 888888;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.skeleton {
  position: relative;
  display: grid;
  overflow: hidden;
  background: var(--skeletonLightBG);
  width: 128px;
  height: 20px;
  padding: 0;
  border: none;
  border-radius: 10px;
}

.skeleton:before {
  position: absolute;
  display: block;
  width: 128px;
  height: 30px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
  content: "";
  animation: skeleton-animation 2s infinite;
}

@keyframes skeleton-animation {
  0% {
    right: 100%;
  }

  50% {
    right: -128px;
  }

  100% {
    right: 100%;
  }
}
